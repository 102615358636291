import React from 'react';

import * as Styled from './LoginModal.style';

import { Button, Link } from '../Shared';
import { updateResetPassword } from '../../api';
import { useNavigate, useSearchParams } from 'react-router-dom';

import GoBack from '../../assets/images/goBack.svg';

export const ResetModal = ({ showModal, closeModal }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formErrors, setFormErrors] = React.useState([]);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: '',
  });

  const handleValueChange = (event, key) => {
    const { value } = event.target;

    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateResetPassword({ code: searchParams.get('reset-code'), ...formData })
      .then((res) => {
        setFormErrors([]);
        setFormSuccess(true);
      })
      .catch((err) => setFormErrors(err.errors));
  };

  const getErrors = (field) =>
    formErrors.find((error) => error.field === field)?.messages || null;

  return (
    <Styled.LoginModal className={`${showModal ? 'show' : ''} fit`}>
      <div className='header'>
        <div className='goBack' onClick={() => navigate('/')}>
          <img src={GoBack} alt='Go Back' />
          Go Back To Login
        </div>
      </div>
      <div
        className='wrapper'
        style={{ maxWidth: '75%', margin: '0 auto', display: 'block' }}
      >
        {!formSuccess ? (
          <>
            <h1>
              Reset <br />
              <em>Password</em>
            </h1>

            <form className='form_wrapper reset_form'>
              <div className='input_group'>
                <div className='label_group'>
                  <label htmlFor='password'>Password</label>
                  <Link variant='link-primary' to='?forget-password=true'>
                    Forgot your password?
                  </Link>
                </div>
                <input
                  id='password'
                  type='password'
                  value={formData.password}
                  onChange={(e) => handleValueChange(e, 'password')}
                />

                {getErrors('password') && (
                  <p className='error'>
                    <small>{getErrors('password')}</small>
                  </p>
                )}
              </div>

              <Button
                variant='primary'
                onClick={handleSubmit}
                styles={{ width: '100%' }}
              >
                Reset Password
              </Button>
            </form>
          </>
        ) : (
          <div className='success'>
            <h1>Success</h1>
            <p>You passsword is suscessfully reseted.</p>

            <Link
              to='/?login=true'
              variant='primary'
              styles={{ width: '100%' }}
              onClick={closeModal}
            >
              LOG IN
            </Link>
          </div>
        )}
      </div>
    </Styled.LoginModal>
  );
};
