import React from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import {
  ExternalLink,
  Footer,
  ForgetModal,
  LoginModal,
  Navbar,
  ResetModal,
} from '../components';
import { useAuth } from '../hooks';

import 'aos/dist/aos.css';
AOS.init({ once: true });

export const LandingPage = () => {
  const { t } = useTranslation();
  const { isLoggedIn, user } = useAuth();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [showLoginModal, setShowLoginModal] = React.useState(
    searchParams.get('login') || false
  );

  if (!['/privacy-policy', '/terms-and-conditions'].includes(pathname)) {
    if (isLoggedIn && user?.reviewed && user.verified && user.emailVerified) {
      return <Navigate to='/account' />;
    } else if (
      user &&
      (!user?.reviewed || !user.verified || !user.emailVerified)
    ) {
      return <Navigate to='/under/review' />;
    }
  }

  return (
    <div className='layout landing'>
      <Navbar
        navLinks={
          <>
            {!user && (
              <ExternalLink
                variant='secondary'
                onClick={() => setShowLoginModal(true)}
              >
                {t('lr_button')}
              </ExternalLink>
            )}
          </>
        }
        hideAccount
      />

      <LoginModal
        closeModal={() => setShowLoginModal(false)}
        showModal={
          showLoginModal &&
          !searchParams.get('reset-code') &&
          !searchParams.get('forget-password')
        }
      />

      <ForgetModal
        closeModal={() => searchParams.set('forget-password', '')}
        showModal={searchParams.get('forget-password')}
      />

      <ResetModal
        closeModal={() => searchParams.set('reset-code', '')}
        showModal={searchParams.get('reset-code')}
      />

      <Outlet />
      <Footer />
    </div>
  );
};
