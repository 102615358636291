import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './PhiSteps.style';
import { Modal } from '../../Modal/Modal';

export const PhiSteps = ({ items }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(null);
  const [showModal, setShowModal] = React.useState(null);

  return (
    <>
      <Styled.PhiSteps>
        <div className='container withPadding'>
          <h2 className='title'>{t('home.phiawards_steps.title')}</h2>
        </div>

        <div className='container'>
          <div className='divider'></div>
        </div>

        <div className='container withPadding icons_group'>
          {items.map((item, i) => (
            <div
              className='icon_group'
              key={i}
              data-aos='zoom-in-up'
              data-aos-delay={i * 100}
            >
              <div className='icon'>
                <item.icon />
              </div>
              <p className='title'>
                {t(`home.phiawards_steps.steps.${item.key}`)}
              </p>
              <a
                href
                onClick={(e) => {
                  e.preventDefault();

                  setActiveStep(item.key);
                  setShowModal(true);
                }}
              >
                {t('home.phiawards_steps.see_more')}
              </a>
            </div>
          ))}
        </div>
      </Styled.PhiSteps>

      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        hideBrand
        className={'rounded'}
        style={{ maxWidth: '70%', minHeight: '25vh' }}
      >
        <div
          className='wrapper'
          style={{ paddingTop: '60px' }}
        >
          {activeStep !== null && (
            <>
              <p
                className='desc'
                dangerouslySetInnerHTML={{
                  __html: t(`home.phiawards_steps.steps_more.${activeStep}`),
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
