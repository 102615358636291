import styled from 'styled-components';
import ProfileScoreBG from '../../../assets/images/ProfileScore.png';

export const ProfileScore = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 172px;
  min-height: 169px;
  padding: 24px;

  background-image: url(${ProfileScoreBG});
  background-repeat: no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;

  margin-top: -86px;
  margin-bottom: 16px;

  position: relative;

  h4 {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #fafafa;
    margin: 0 0 4px;
  }
  p {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 20px;
    color: #fafafa;
    margin: 0 0 16px;
  }
  .points {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .last_refresh {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #fafafa;
      opacity: 0.5;
      margin: 0;
    }
    p.date {
      opacity: 1;
      text-align: right;
    }
  }

  .buy-a-review {
    position: absolute;
    top: 54px;
    right: 24px;

    align-items: end;

    text-align: center;
    font-family: 'Arimo';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    text-transform: uppercase;
    padding: 3px 18px 6px 17px;
  }
`;
