import styled from 'styled-components';

import { ReactComponent as PhiLogo } from '../../assets/images/PhiLogo.svg';

const PhiLineWrapper = styled.div`
  position: relative;

  & svg {
    width: 48px;
    height: 48px;

    background-color: #111111;
    padding: 12px;
    border-radius: 100%;

    margin: 0 auto;
    display: block;

    z-index: 9;
    position: relative;
  }

  &::after {
    content: '';
    width: ${({ width }) => width || '100'};
    height: 1px;
    background-color: #dfdfdf;

    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export const PhiLine = ({ width }) => (
  <PhiLineWrapper width={width}>
    <PhiLogo />
  </PhiLineWrapper>
);
