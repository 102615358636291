import { useEffect } from 'react';
import { BottomStickyBar } from '../components';

export const PrivacyPolicy = () => {
  document.body.style.backgroundColor = '#000';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='policy-page container'>
        <p style={{ textAlign: 'center' }}>
          <strong>PRIVACY POLICY PhiAwards</strong>
        </p>
        <p style={{ textAlign: 'center' }}>
          <strong>
            Article 13 and 14 General Data Protection Regulation (GDPR)
          </strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          With this information we inform you (“Data Subject”) about the
          processing of your personal data (“Data”) as well as your data
          protection rights connected to the use of our service “PhiAwards” and
          the visit of our Web platform (https://
          <em>phiawards.phi-academy.com</em>).
        </p>
        <p style={{ textAlign: 'justify' }}>CONTENT OF THIS PRIVACY POLICY:</p>
        <ul>
          <li>
            Which Data are processed and from which sources do they come from?
          </li>
          <li>
            Who is responsible for the processing of your Data and how can you
            get in touch with the controller?
          </li>
          <li>
            What are the purposes, the duration, and the legal basis of the
            processing of your Data?
          </li>
          <li>Cookies, pixels and analytics tools</li>
          <li>
            <strong>Are you obliged to provide Data?</strong>
          </li>
          <li>Automated decision making</li>
          <li>
            <strong>
              Your rights in the context of the processing of your data
            </strong>
          </li>
        </ul>
        <p>
          Which Data are processed and from which sources do they come from?
        </p>
        <p style={{ textAlign: 'justify' }}>
          We process the data we receive from you when you visit our Web
          platform PhiAwards https://phiawards.phi-academy.com and the data, we
          receive from you as part of your use of our Web platform PhiAwards
          (hereinafter “Web Platform”, “PhiAwards” or “our Services”).
        </p>
        <p style={{ textAlign: 'justify' }}>
          We receive your data from the following sources:
        </p>
        <ul>
          <li>
            From you: When you visit the Web Platform, when you register a
            PhiAwards Profile, when you subscribe to PhiAwards contest and when
            you use our Services, we receive the data directly from you.
          </li>
          <li>
            From Social Media (publicly accessible sources): If you disclose
            your social media profile(s), we receive your data from the
            respective social media platform (e.g. Instagram, Facebook).
          </li>
          <li>
            From your use of our service “PhiCert”: If you have a PhiCert
            Profile your data (e.g. name, city and country of residence, e-mail
            address, telephone number, brand/course/level certified in,
            graduation date, company details) will be automatically transmitted
            to our Service PhiAwards.
          </li>
        </ul>
        <p>
          Who is responsible for the processing of your Data and how can you get
          in touch with the controller?
        </p>
        <p>
          PhiAcademy GmbH, Gartengasse 8/8, 1050 Vienna, is responsible for the
          data processing activities.
        </p>
        <p>
          What are the purposes, the duration, and the legal basis of the
          processing of your Data?
        </p>
        <p>
          We process your Data in the following described manner. If we process
          your Data for any other purpose, we will inform you separately before
          we start processing your Data. If you are obliged to provide the Data
          for the respective purpose, it will be made visible with (*).
        </p>
        <p>
          Data processing when visiting the Web Platform
          (https://phiawards.phi-academy.com)
        </p>
        <p>
          <strong>Data: </strong>Browser type*, operating system*, country*,
          date, time and duration of access, IP address*, pages accessed (for
          information on how we use cookies and other tools see section 4).
        </p>
        <p>
          <strong>Purpose:</strong> This data processing is necessary to provide
          you with the best possible user experience on our Web Platform and to
          guarantee the stability of the Web Platform. In particular, we use
          this data for the purpose of providing the following services:
          Infrastructure and platform services, computing capacity, storage
          space and database services, security services and technical
          maintenance services that we use for the purpose of operating the
          website.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage.
        </p>
        <p>
          <strong>Legal Basis:</strong> We process the mentioned Data based on
          our legitimate interests or based on the legitimate interests of third
          parties (Art. 6 para. 1 lit f GDPR), namely to enable the operation of
          the Website.
        </p>
        <p style={{ textAlign: 'justify' }}>
          To achieve the intended purposes, it may sometimes be necessary to
          disclose your Data to the following recipients:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>PhiAcademy d.o.o</td>
              <td>Managing the contractual relationship</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>HALO Creative Agency doo Beograd</td>
              <td>Development &amp; maintenance of the Platform</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
          </tbody>
        </table>
        <p>Registration of Profile and Use of our Services</p>
        <p>
          <strong>Data: </strong>Name, e-mail address*, location (city,
          country)*, language setting*, timezone*, certificate id*, brand /
          course / level, graduation date, title (within the PhiAcademy
          community, e.g. “Artist”, “Royal Artist”)*, social media link (e.g.
          Instagram*, Facebook, Youtube), website link, brand of products that
          is used for work, profile photo.
        </p>
        <p>
          <strong>Purpose:</strong> We process your Data to verify if you meet
          the requirements for the use of our Services and for the purpose of
          managing our contractual relationship and to provide our Services to
          you. Furthermore, we may process data for monitoring and to solve
          technical errors or issues.
        </p>
        <p>
          <strong>Legal Basis:</strong> We process the mentioned Data based on
          our legitimate interests or based on the legitimate interests of third
          parties (Art. 6 para. 1 lit f GDPR), namely to provide our Services to
          you and to ensure the stability and security of our Services. If you
          upload a profile photo we process this Data based on your consent
          (Art. 6 para 1 lit a GDPR). Depending on the photo this might be
          sensitive Data (Art 9 para 1 GDPR). You have the right to withdraw
          your consent at any time. The lawfulness of the processing until the
          withdrawal is not affected by this.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage.
        </p>
        <p style={{ textAlign: 'justify' }}>
          To achieve the intended purposes, it may sometimes be necessary to
          disclose your Data to the following recipients:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>PhiAcademy d.o.o</td>
              <td>Managing the contractual relationship</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>HALO Creative Agency doo Beograd</td>
              <td>Development &amp; maintenance of the Platform</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>Amazon Web Services EMEA SARL</td>
              <td>Maintenance and hosting of the Platform</td>
              <td>Luxembourg</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Public bodies and authorities</td>
              <td>Legal obligation</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>Payed Subscription</p>
        <p>(If you subscribe for our Service “PhiAwards Contest”)</p>
        <p>
          <strong>Data: </strong>Your profile information* (e.g. name,
          e-mail-address, address; see point 3.2 above); payment data*.
        </p>
        <p>
          <strong>Purpose:</strong> We process your Data for the purpose of
          managing our contractual relationship and to provide our services to
          you. In particular we process your data in order to rate you within
          the scope of the PhiAwards Contest. Furthermore, we may process data
          for monitoring and to solve technical errors or issues.
        </p>
        <p>
          <strong>Legal Basis:</strong> We process the mentioned Data based on
          our legitimate interests or based on the legitimate interests of third
          parties (Art. 6 para. 1 lit f GDPR), namely to provide our Services to
          you and to ensure the stability and security of our Services.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage.
        </p>
        <p style={{ textAlign: 'justify' }}>
          To achieve the intended purposes, it may sometimes be necessary to
          disclose your Data to the following recipients:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>PhiAcademy d.o.o</td>
              <td>Managing the contractual relationship</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>Stripe Payments Europe Limited</td>
              <td>Handling of payments</td>
              <td>Ireland</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>HALO Creative Agency doo Beograd</td>
              <td>Development &amp; maintenance of the Platform</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>Public bodies and authorities</td>
              <td>Legal obligation</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>User support</p>
        <p>(if you contact us via e-mail)</p>
        <p>
          <strong>Data: </strong>Name*, e-mail-address*, address* (street, city,
          postal code, county, country), issue or request*.
        </p>
        <p>
          <strong>Purpose:</strong> We will process Data you provide when you
          contact us to respond to your questions or to fulfill your issues.
        </p>
        <p>
          <strong>Legal Basis:</strong> We process the mentioned Data based on
          our legitimate interests or based on the legitimate interests of third
          parties (Art. 6 para. 1 lit f GDPR), namely to process your inquiries
          and requests in order to be able to provide our Services even better.
        </p>
        <p>
          <strong>Duration:</strong> We process the Data you provide only for
          the duration of the response or fulfillment of your requests and
          issues. Beyond that, we will process the Data only as long as there is
          a legal obligation to do so or we need Data for the exercise or
          defense of legal claims.
        </p>
        <p style={{ textAlign: 'justify' }}>
          To achieve the intended purposes, it may sometimes be necessary to
          disclose your Data to the following recipients:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>PhiAcademy d.o.o</td>
              <td>Customer Service</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>HALO Creative Agency doo Beograd</td>
              <td>Development &amp; maintenance of the Platform</td>
              <td>Serbia</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
            <tr>
              <td>Amazon Web Services EMEA SARL</td>
              <td>Maintenance and hosting of the website</td>
              <td>Luxenbourg</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Freshworks Inc.</td>
              <td>Client Service and Client communication</td>
              <td>USA</td>
              <td>Standard contractual clauses pursuant to Art 46 GDPR</td>
            </tr>
          </tbody>
        </table>
        <p>Cookies, pixels and analytics tools</p>
        <p>
          The Web Platform uses cookies, which are small files stored on your
          device (web browser).
        </p>
        <p>
          We use the Data collected through these cookies to improve our
          Services and to make them user-friendly, for example to evaluate the
          use of our Services. Some cookies remain stored on your device until
          you delete them, other cookies are only stored for a certain duration.
        </p>
        <p>
          For the collection of these Data we use the following technologies:
        </p>
        <p>
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org.
        </p>
        <p>
          “Log files” track actions occurring on the Web Platform, and collect
          data including your IP address, browser type, referring/exit pages,
          and date/time stamps.
        </p>
        <p>
          “Web beacons”, “tags”, and “pixels” are electronic files used to
          record information about how you browse the Web Platform.
        </p>
        <p>Cookies necessary for the functioning of the Web Platform:</p>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>Function/purpose</td>
              <td>Storage Period</td>
            </tr>
            <tr>
              <td>PHPSESSID</td>
              <td>Your session ID on the server</td>
              <td>1 hour</td>
            </tr>
          </tbody>
        </table>
        <p>
          All these cookies are technically necessary for the presentation of
          the Web Platform. You can manually delete any cookie. Please note that
          a general deactivation/deletion of cookies may possibly lead to
          functional limitations of the Web Platform.
        </p>
        <p>
          Cookies not technically necessary for the functioning of the Web
          Platform:
        </p>
        <p>
          Additionally, we use pixels and tags from the following third parties
          (which may in turn place cookies). These cookies are not technically
          necessary for the presentation of the Web Platform and only activated
          with your given consent:
        </p>
        <p>Cookies for marketing and advertising purposes</p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Description/purpose</strong>
              </td>
              <td>
                <strong>Privacy Policy</strong>
              </td>
              <td>
                <strong>Third Party</strong>
              </td>
              <td>
                <strong>Storage period</strong>
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>
                We use Google Analytics to help measure how users interact with
                our Website.
              </td>
              <td>https://policies.google.com/privacy</td>
              <td>Google Analytics</td>
              <td>7 days</td>
            </tr>
            <tr>
              <td>Google Ads</td>
              <td>
                We use Google Ads to deliver targeted advertisements to
                individuals who visit our Website.
              </td>
              <td>https://policies.google.com/privacy</td>
              <td>Google Ads</td>
              <td>7 days</td>
            </tr>
            <tr>
              <td>Facebook Ads</td>
              <td>
                We use Facebook Custom Audiences to deliver targeted
                advertisements to individuals who visit our Websites
              </td>
              <td>https://www.facebook.com/policy.php</td>
              <td>Facebook</td>
              <td>7 days</td>
            </tr>
          </tbody>
        </table>
        <p>Analytical and behavorial cookies</p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Description/purpose</strong>
              </td>
              <td>
                <strong>Privacy Policy</strong>
              </td>
              <td>
                <strong>Third Party</strong>
              </td>
              <td>
                <strong>Storage period</strong>
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>
                Determined by Google Analytics to identify unique sessions
              </td>
              <td>https://policies.google.com/privacy</td>
              <td>Google LLC (Google Analytics Cookie)</td>
              <td>1 minute</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>
                Determined by Google Analytics to identify unique sessions
              </td>
              <td>https://policies.google.com/privacy</td>
              <td>Google LLC (Google Analytics Cookie)</td>
              <td>1 day</td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>
                Determined by Google Analytics to identify unique sessions
              </td>
              <td>https://policies.google.com/privacy</td>
              <td>Google LLC (Google Analytics Cookie)</td>
              <td>2 years</td>
            </tr>
          </tbody>
        </table>
        <p>Other cookies</p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Description/purpose</strong>
              </td>
              <td>
                <strong>Privacy Policy</strong>
              </td>
              <td>
                <strong>Third Party</strong>
              </td>
              <td>
                <strong>Storage period</strong>
              </td>
            </tr>
            <tr>
              <td>Stripe</td>
              <td>
                We use Stripe as one of our payment providers to process your
                orders and capture payments
              </td>
              <td>https://stripe.com/privacy-center/legal</td>
              <td>Stripe</td>
              <td>7 days</td>
            </tr>
          </tbody>
        </table>
        <p>Other Analytics and Marketing tools</p>
        <p>Google Analytics</p>
        <p>
          We use the Cookies of Google Analytics, a web analysis service
          provided by Google LLC (hereinafter “Google”). These cookies transmit
          data about your usage of the Website/Platform to a Google server in
          the USA. However, your IP address will be shortened prior to
          transmission and the transmitted data can no longer be associated with
          your person.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Data about your usage of the Website/Platform
          (visits, sessions, frequency, time active, events &amp; user actions).
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> Google will use the collected information to
          evaluate general usage data of our Website/Platform and to compile
          reports on Website/Platform activities. For information about how
          Google and its affiliates use data, please visit Google's Privacy
          Policy, currently available at:&nbsp;www.google.com/privacy.html.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal. If you want to
          prevent the use of Google Analytics cookies in general, you can either
          do this through your browser settings or you can install the browser
          plug-in available under the following link:
          http://tools.google.com/dlpage/gaoptout.
        </p>
        <p>
          <strong>Duration:</strong> Your Data will be processed until you
          withdraw your consent. Beyond that, we will process the Data only as
          long as there is a legal obligation to do so or we need the Data for
          the exercise or defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Google LLC</td>
              <td>
                Evaluation of general usage of our Website/Platform, compilation
                of reports on Website/Platform activities
              </td>
              <td>USA</td>
              <td>SCCs</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://policies.google.com/privacy.
        </p>
        <p>Google Customer Match</p>
        <p>
          Google Customer Match feature enables us to create an audience using
          data such as email addresses and phone numbers. Google does not
          receive actual email addresses. Google’s system transforms the contact
          information we have into hashed codes using the secure hashing
          algorithm SHA256, a one-way hashing mechanism that is not unencrypted
          by Google.
        </p>
        <p>
          <strong>Data: </strong>Name*,<strong> </strong>e-mail-address*,
          address (street, city, postal code, county, country), chosen license,
          metadata (date of registration, date of subscription, clicks, etc.)
        </p>
        <p>
          <strong>Purpose:</strong> We process your Data for the purpose of
          remarketing, direct marketing and social media marketing in the Google
          Customer Match tool. We may combine Data we or our providers have
          collected with Data collected by our affiliates to provide a more
          detailed picture of your needs and interests.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal.
        </p>
        <p>
          <strong>Duration:</strong> Your Data will be processed until you
          withdraw your consent. Beyond that, we will process the Data only as
          long as there is a legal obligation to do so or we need the Data for
          the exercise or defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Google Ireland ltd</td>
              <td>remarketing, direct marketing and social media marketing</td>
              <td>Ireland</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here:
          https://support.google.com/google-ads/answer/6334160?hl=en
        </p>
        <p>https://policies.google.com/technologies/partner-sites?hl=en</p>
        <p>https://policies.google.com/privacy</p>
        <p>Facebook</p>
        <p>
          We also use the Facebook Audience Pixel analysis tool from Facebook
          Ireland Limited. All data collected by this pixel is encrypted by
          Facebook using "hashes".
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Data about your usage of the Website/Platform
          (visits, sessions, frequency, time active, events &amp; user actions).
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> Measure the effectiveness of our
          advertising.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal. The comparison
          of the data with the data stored by us is based on our legitimate
          interest in marketing and customer loyalty.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage. Beyond that data processed on your consent
          will no longer be stored if you withdraw your consent unless there is
          a legal obligation to do so or we need the data for the exercise or
          defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Meta Platforms Ireland Limited</td>
              <td>
                Analysis of data to measure the effectiveness of our advertising
              </td>
              <td>Ireland</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://www.facebook.com/policy.php.
        </p>
        <p>Facebook Custom Audiences</p>
        <p>
          Facebook’s customer list custom audiences feature enables us to create
          an audience using data such as email addresses and phone numbers
          ("Audience"). When using this feature, your data is locally hashed on
          our system before we upload and pass it to Facebook ("Hashed Data") to
          be used to create an Audience.
        </p>
        <p>
          <strong>Data: </strong>Name*,<strong> </strong>e-mail-address*,
          address (street, city, postal code, county, country), chosen license,
          metadata (date of registration, date of subscription, clicks, etc.).
        </p>
        <p>
          <strong>Purpose:</strong> We process your Data for the purpose of
          remarketing, direct marketing and social media marketing in the
          Facebook Customer List Audience tool. We may combine Data we or our
          providers have collected with Data collected by our affiliates to
          provide a more detailed picture of the needs and interests of our
          Clients and Customers.
        </p>
        <p>
          <strong>Legal Basis:</strong> We process this data on the basis of our
          legitimate interest
          (Article&nbsp;6&nbsp;para&nbsp;1&nbsp;lit&nbsp;f&nbsp;GDPR) in direct
          advertising and marketing and to measure the effectiveness of our
          advertising.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Meta Platforms Ireland Limited</td>
              <td>
                direct advertising and marketing and to measure the
                effectiveness of our advertising
              </td>
              <td>Ireland</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here:
          https://www.facebook.com/legal/terms/customaudience
        </p>
        <p>TikTok</p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Data about your usage of the Website/Platform
          (visits, sessions, frequency, time active, events &amp; user actions).
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> Measure the effectiveness of our
          advertising.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal. The comparison
          of the data with the data stored by us is based on our legitimate
          interest in marketing and customer loyalty.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage. Beyond that data processed on your consent
          will no longer be stored if you withdraw your consent unless there is
          a legal obligation to do so or we need the data for the exercise or
          defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>TikTok Information Technologies UK Limited</td>
              <td>Advertising and Direct Marketing</td>
              <td>UK</td>
              <td>Adequacy decision pursuant to Art 45 GDPR</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://www.tiktok.com/legal/privacy-policy?lang=en
        </p>
        <p>Pinterest</p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Data about your usage of the Website/Platform
          (visits, sessions, frequency, time active, events &amp; user actions).
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> Measure the effectiveness of our
          advertising.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal. The comparison
          of the data with the data stored by us is based on our legitimate
          interest in marketing and customer loyalty.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage. Beyond that data processed on your consent
          will no longer be stored if you withdraw your consent unless there is
          a legal obligation to do so or we need the data for the exercise or
          defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Pinterest Europe Ltd.</td>
              <td>Advertising and Direct Marketing</td>
              <td>Irland</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://policy.pinterest.com/en-gb/privacy-policy#section-residents-of-the-eea
        </p>
        <p>Snapchat</p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Data about your usage of the Website/Platform
          (visits, sessions, frequency, time active, events &amp; user actions).
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> Measure the effectiveness of our
          advertising.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal. The comparison
          of the data with the data stored by us is based on our legitimate
          interest in marketing and customer loyalty.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage. Beyond that data processed on your consent
          will no longer be stored if you withdraw your consent unless there is
          a legal obligation to do so or we need the data for the exercise or
          defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Snap Inc.</td>
              <td>Advertising and Direct Marketing</td>
              <td>USA</td>
              <td>SCCs</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://www.snap.com/en-GB/privacy/privacy-policy
        </p>
        <p>LinkedIn</p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Data about your usage of the Website/Platform
          (visits, sessions, frequency, time active, events &amp; user actions).
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> Measure the effectiveness of our
          advertising.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal. The comparison
          of the data with the data stored by us is based on our legitimate
          interest in marketing and customer loyalty.
        </p>
        <p>
          <strong>Duration:</strong> We store your data only as long as it is
          necessary for the purposes for which we have collected your data, we
          are legally obliged to do so or we have a corresponding overriding
          interest in the storage. Beyond that data processed on your consent
          will no longer be stored if you withdraw your consent unless there is
          a legal obligation to do so or we need the data for the exercise or
          defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>LinkedIn Ireland Unlimited Company</td>
              <td>Advertising and Direct Marketing</td>
              <td>Ireland</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://www.linkedin.com/legal/privacy-policy?
        </p>
        <p>Hotjar</p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Data: </strong>Device’s IP address (stored in a de-identified
          format); device screen resolution; device type (unique device
          identifiers), operating system, and browser type; geographic location
          (country only); preferred language used to display the Hotjar enabled
          site; mouse events (movements, location and clicks); keypresses;
          referring URL and domain; pages visited; and. Date and time when
          website pages were accessed
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Purpose:</strong> We use Hotjar in order to better understand
          our users’ needs and to optimize this service and experience. Hotjar
          is a technology service that helps us better understand our users’
          experience (e.g. how much time they spend on which pages, which links
          they choose to click, what users do and don’t like, etc.) and this
          enables us to build and maintain our service with user feedback.
        </p>
        <p>
          <strong>Legal Basis:</strong> The collection of data only takes place
          with your consent. This consent can be withdrawn by you at any time.
          The withdrawal of this consent does not affect the lawfulness of
          processing based on the consent before its withdrawal.
        </p>
        <p>
          <strong>Duration:</strong> Your Data will be processed until you
          withdraw your consent. Beyond that, we will process the Data only as
          long as there is a legal obligation to do so or we need the Data for
          the exercise or defense of legal claims.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Recipient</strong>
              </td>
              <td>
                <strong>Purpose</strong>
              </td>
              <td>
                <strong>Located in</strong>
              </td>
              <td>
                <strong>
                  Appropriate safeguards to third countries transfer
                </strong>
              </td>
            </tr>
            <tr>
              <td>Hotjar Ltd.</td>
              <td>
                Measures how costumers/users interact with the Website/Platform
                and to receive feedback
              </td>
              <td>Malta</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          Additional information about the tool can be found here
          https://www.hotjar.com/legal/policies/privacy/.
        </p>
        <p>Are you obliged to provide Data?</p>
        <p>
          If you visit the Website and/or interact with the Web Platform, you
          are obliged to provide the data marked with (*). Unless you provide
          those mandatory Data, we will generally not be able to provide our
          services. You are not obliged to provide any other data.
        </p>
        <p>Automated decision-making</p>
        <p style={{ textAlign: 'justify' }}>
          We do not use automated decision-making or profiling according to
          Article 22 GDPR.
        </p>
        <p>Your rights in the context of the processing of your data</p>
        <p>
          You have the right to (i) access as to whether and what personal data
          we process and receive copies of your data, (ii) request rectification
          or amendment of inaccurate or not lawfully processed data and request
          erasure of your personal data, (iii) request restriction of data
          processing activities in certain circumstances, (iv) object to data
          processing activities in certain circumstances or withdraw consent
          previously given for the processing, (v) request that we provide you
          your data in a transferable format, and (vi) lodge a complaint with
          the Austrian data protection authority (www.dsb.gv.at ) or to any
          other data protection supervisory authority in the EU, in particular
          at your place of residence or place of work.
        </p>
        <p>
          To exercise any of the above-mentioned rights, you can contact us
          directly by e-mail or by post at the following address:
        </p>
        <p>
          <strong>PhiAcademy GmbH</strong>
        </p>
        <p>Gartengasse 8/8, 1050 Vienna</p>
        <p>E-Mail: phiawards@phiacademy.com</p>
      </div>

      <BottomStickyBar />
    </>
  );
};
