import React from 'react';

import * as Styled from './ProgressRange.style';

export const ProgressRange = ({
  title,
  range = [0, 1],
  value,
  hideRange = false,
  steps = null,
  activeStep = null,
}) => {
  let lineValue = ((value * 1) / range[1]) * 100;
  if (steps) {
    if (activeStep === steps?.length - 1) {
      lineValue = 100;
    } else {
      lineValue = ((activeStep + 1.6) / (steps?.length + 1)) * 100;
    }
  }

  return (
    <Styled.ProgressRange
      value={lineValue}
      className='progress-wrapper'
    >
      {!hideRange && (
        <div className='range'>
          <span>{range[0]}</span>
          <span>{range[1]}</span>
        </div>
      )}

      <div className='progress'>
        <div className='line'></div>
        {title && (
          <div className='tooltip'>
            {value}
            {title}
          </div>
        )}
      </div>
    </Styled.ProgressRange>
  );
};
