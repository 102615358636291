import styled from 'styled-components';

export { AccountHeaderElements } from '../../../components';

export const UserPageWrapper = styled.div`
  .section-title {
    color: #fafafa;

    &::after {
      background-color: #2f2f2f;
    }
  }

  .user_account .body .container {
    position: relative;

    .goBack {
      position: absolute;
      top: 0;
      left: 0;

      svg,
      path {
        stroke: #000;
      }
    }
  }

  .card-dark {
    background-color: transparent;
    .header {
      background-color: transparent;
      border: 1px solid #2f2f2f;
      color: #fafafa;
    }

    .body,
    .footer {
      background-color: #2f2f2f;

      * {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .desktop {
      display: none;
    }
  }
`;
