import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as UserVerified } from '../../assets/images/UserVerified.svg';
import * as UserGroup from '../../assets/images/UserGroup';

const StyledAccountInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .user_photo {
    width: fit-content;
    height: fit-content;
    position: relative;

    img {
      width: 36.91px;
      height: 36.82px;
      object-fit: cover;
      border-radius: 100%;
    }

    .user_group {
      width: 15px;
      height: 15px;

      background-color: #1a1919;
      padding: 3px;
      border-radius: 100%;

      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .account_info {
    .user_name {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #fafafa;
      margin: 0 0 4px;
    }
    .user_points {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #fafafa;
      margin: 0;
    }
  }
`;

export const AccountInfo = ({ name, title, verified, avatar, group, rank }) => {
  const { t } = useTranslation();

  const UserGroupIcon = group && UserGroup[group.replace(' ', '')];

  return (
    <StyledAccountInfo className='account'>
      <div className='user_photo'>
        <img
          src={avatar}
          alt={name}
        />
        {UserGroupIcon && <UserGroupIcon className='user_group' />}
      </div>

      <div className='account_info'>
        <p className='user_name'>
          {name} {verified && <UserVerified />}
        </p>
        <p className='user_points'>
          {title} {rank > 0 && `| ${t('user_profile.ranked')}: #${rank}`}
        </p>
      </div>
    </StyledAccountInfo>
  );
};
