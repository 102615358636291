import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, loginUser } from '../api';
import { GlobalContext } from './globalContext';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { setState: setGlobalState } = useContext(GlobalContext);

  const [user, setUser] = useState(null);
  const [fetching, setFetching] = useState(true);

  const fetchUser = useCallback(() => {
    setGlobalState({ loading: true });

    getCurrentUser()
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          setFetching(false);

          setTimeout(() => setGlobalState({ loading: false }), 1000);
        } else {
          setUser(null);
          setFetching(false);

          setGlobalState({ loading: false });
        }
      })
      .catch((error) => {
        setUser(null);
        setFetching(false);
        localStorage.removeItem('token');

        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setFetching(false);
      return;
    }

    fetchUser();
  }, [fetchUser]);

  const isLoggedIn = Boolean(user);

  const login = (formData) => {
    setGlobalState({ loading: true });

    return loginUser(formData)
      .then((res) => {
        if (res.data.user) {
          setUser(res.data.user);
          navigate('/account');
        } else {
          setUser(null);
        }

        setTimeout(() => setGlobalState({ loading: false }), 1000);

        return res;
      })
      .catch((error) => {
        setUser(null);
        setGlobalState({ loading: false });
        console.error(error);

        throw error;
      });
  };

  const logout = () => {
    setGlobalState({ loading: false });
    setUser(null);
    localStorage.removeItem('token');
    setTimeout(() => setGlobalState({ loading: false }), 1000);

    navigate('/', { replace: true });
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user, setUser, login, logout, fetchUser }}
    >
      {!fetching && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
