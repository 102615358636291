import React from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import { AuthProvider, GlobalProvider } from './hooks';
import { Loader } from './components';

import './i18n/i18n';
import './assets/scss/App.scss';

const App = () => {
  const AppRoutes = () => useRoutes(routes);

  return (
    <div className='App'>
      <BrowserRouter>
        <GlobalProvider>
          <AuthProvider>
            <Loader />

            <AppRoutes />
          </AuthProvider>
        </GlobalProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
