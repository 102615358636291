import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { cancelUserSubscriptions, getUserSubscriptions } from '../../../../api';
import { Button } from '../../../../components/Shared';
import { Table } from '../../../../components';
import { GlobalContext } from '../../../../hooks';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`;

const TableStyled = styled(Table)`
  width: 100%;

  th,
  td {
    white-space: nowrap;
  }

  th {
    color: #2f2f2f !important;
  }
  td {
    color: #8f8f8f !important;
  }
`;

export const Subscription = () => {
  const { t } = useTranslation();
  const { setState: setGlobalState } = React.useContext(GlobalContext);
  const [subscription, setSubscription] = React.useState(null);

  React.useEffect(() => {
    if (!subscription) {
      setGlobalState({ loading: true });

      getUserSubscriptions()
        .then((res) => {
          setSubscription(res.data);

          setTimeout(() => setGlobalState({ loading: false }), 1000);
        })
        .catch((e) => {
          setGlobalState({ loading: false });

          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  if (
    !subscription ||
    subscription?.subscriptions?.length <= 0 ||
    !subscription?.product
  ) {
    return;
  }

  const handleCancelSubscription = () => {
    cancelUserSubscriptions()
      .then(() => {
        getUserSubscriptions()
          .then((res) => {
            setSubscription(res.data);
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  return (
    <>
      <Grid>
        <div className='group'>
          <h3>{t('profile.settings.subscriptions.title')}</h3>
          <p>{subscription?.product?.name}</p>
        </div>

        <div className='group'>
          <h3>{t('profile.settings.subscriptions.charge')}</h3>
          <p>{subscription?.subscriptions[0]?.plan?.amount / 100}€</p>
        </div>

        <div className='group'>
          <h3>{t('profile.settings.subscriptions.status')}</h3>
          <p className='status'>
            {subscription?.subscriptions[0]?.status}

            {/* {!subscription?.subscriptions[0]?.canceled_at && ( */}
            <Button
              variant='primary'
              onClick={handleCancelSubscription}
              styles={{
                width: 'max-content',
                padding: '5px',
                borderRadius: '4px',
              }}
            >
              {t('profile.settings.subscriptions.cancel_renew')}
            </Button>
            {/* )} */}
          </p>
        </div>

        <div className='group'>
          <h3>{t('profile.settings.subscriptions.next_payment')}</h3>
          <p>
            {moment
              .unix(subscription?.subscriptions[0]?.current_period_end)
              .format('MMMM D, YYYY')}
          </p>
        </div>

        {subscription?.subscriptions[0]?.canceled_at && (
          <div className='group'>
            <h3>{t('profile.settings.subscriptions.canceled')}</h3>
            <p>
              {moment
                .unix(subscription?.subscriptions[0]?.canceled_at)
                .format('MMMM D, YYYY')}
            </p>
          </div>
        )}

        {subscription?.subscriptions[0]?.cancel_at && (
          <div className='group'>
            <h3>{t('profile.settings.subscriptions.active_until')}</h3>
            <p>
              {moment
                .unix(subscription?.subscriptions[0]?.cancel_at)
                .format('MMMM D, YYYY')}
            </p>
          </div>
        )}
      </Grid>

      <div className='group'>
        <h3>{t('profile.settings.subscriptions.table.title')}</h3>

        <div style={{ overflowX: 'auto' }}>
          <TableStyled>
            <thead>
              <tr>
                <th>#</th>
                <th>{t('profile.settings.subscriptions.table.id')}</th>
                <th>{t('profile.settings.subscriptions.table.status')}</th>
                <th>{t('profile.settings.subscriptions.table.started')}</th>
                <th>{t('profile.settings.subscriptions.table.canceled')}</th>
                <th>{t('profile.settings.subscriptions.table.ends')}</th>
              </tr>
            </thead>

            <tbody>
              {subscription?.subscriptions.length > 0 ? (
                subscription?.subscriptions?.map(
                  (subscription, iSubscription) => (
                    <tr key={iSubscription}>
                      <td>{iSubscription + 1}</td>
                      <td>{subscription.id}</td>
                      <td>{subscription.status.toUpperCase()}</td>
                      <td>
                        {moment
                          .unix(subscription.current_period_start)
                          .format('DD/MM/YYYY HH:mm')}
                      </td>
                      <td>
                        {moment
                          .unix(subscription.canceled_at)
                          .format('DD/MM/YYYY HH:mm')}
                      </td>
                      <td>
                        {moment
                          .unix(subscription.current_period_end)
                          .format('DD/MM/YYYY HH:mm')}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    {t('profile.settings.subscriptions.table.not_found')}
                  </td>
                </tr>
              )}
            </tbody>
          </TableStyled>
        </div>
      </div>
    </>
  );
};
