import styled from 'styled-components';

export const CheckedUl = styled.ul`
  padding: 0;

  li {
    appearance: auto;

    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #111111;

    display: flex;
    align-items: center;
    text-align: left;

    gap: 8px;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4V12C0 13.0609 0.421427 14.0783 1.17157 14.8284C1.92172 15.5786 2.93913 16 4 16H12C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12V4C16 2.93913 15.5786 1.92172 14.8284 1.17157C14.0783 0.421427 13.0609 0 12 0H4ZM10.984 6.9472C11.0559 6.87051 11.1119 6.78041 11.149 6.68206C11.186 6.58371 11.2033 6.47902 11.1999 6.37398C11.1965 6.26894 11.1724 6.1656 11.129 6.06986C11.0857 5.97412 11.0239 5.88786 10.9472 5.816C10.8705 5.74414 10.7804 5.68809 10.6821 5.65105C10.5837 5.61401 10.479 5.5967 10.374 5.60012C10.2689 5.60354 10.1656 5.62761 10.0699 5.67097C9.97412 5.71432 9.88786 5.77611 9.816 5.8528L7.3496 8.4848L6.1312 7.4024C5.97157 7.2697 5.7666 7.20424 5.5596 7.21985C5.3526 7.23547 5.15977 7.33094 5.02186 7.4861C4.88394 7.64125 4.81174 7.84394 4.8205 8.05134C4.82926 8.25875 4.91829 8.45463 5.0688 8.5976L6.8688 10.1976C7.02455 10.3359 7.22812 10.4081 7.43623 10.3987C7.64434 10.3893 7.84058 10.299 7.9832 10.1472L10.9832 6.9472H10.984Z' fill='%23A37C51'/%3E%3C/svg%3E%0A");
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
`;
