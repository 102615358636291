import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #151515;

  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  padding: 16px 0 36px;
  z-index: 999999999;

  .nav-links {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 24px;

      li {
        appearance: none;

        font-family: 'Arimo';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;

        text-align: center;

        svg {
          display: block;
          margin-bottom: 6px;
        }

        a {
          text-decoration: none;
          color: #fafafa;

          display: flex;
          align-items: center;
          justify-content: center;

          flex-direction: column;

          svg,
          path {
            fill: #fafafa;
          }
        }

        .active {
          color: #a37c51;

          a {
            color: #a37c51;
          }
          svg,
          path {
            fill: #a37c51;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
