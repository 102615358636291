import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../Modal/Modal';
import * as Styled from './AccountSteps.style';
import * as UserGroup from '../../../assets/images/UserGroup';

const steps = [
  {
    key: 'Artist',
    title: 'Artist',
    icon: () => <UserGroup.Artist />,
  },
  {
    key: 'RoyalArtist',
    title: 'Royal Artist',
    icon: () => <UserGroup.RoyalArtist />,
  },
  {
    key: 'MasterAssistant',
    title: 'Master Associate',
    icon: () => <UserGroup.MasterAssistant />,
  },
  {
    key: 'CraftMaster',
    title: 'Craft Master',
    icon: () => <UserGroup.CraftMaster />,
  },
  {
    key: 'GrandMaster',
    title: 'Grand Master',
    icon: () => <UserGroup.GrandMaster />,
  },
];

export const AccountSteps = ({ checked = [] }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(null);
  const [showModal, setShowModal] = React.useState(null);

  return (
    <>
      <Styled.AccountSteps>
        {steps.map((step, index) => (
          <div
            className={`item ${checked[index] ? 'active' : ''}`}
            onClick={() => {
              if (!checked[index]) {
                setShowModal(true);
                setActiveStep(index);
              }
            }}
          >
            <step.icon />
            {checked[index] && <span className='checked'></span>}
          </div>
        ))}
      </Styled.AccountSteps>

      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        hideBrand
        className={'rounded'}
        style={{ maxWidth: '70%' }}
      >
        <div className='wrapper'>
          {steps?.[activeStep] && (
            <>
              <p className='title'>{steps?.[activeStep]?.title}</p>
              <p
                className='desc'
                dangerouslySetInnerHTML={{
                  __html: t(
                    `user_profile.next_step.description_${steps?.[
                      activeStep
                    ]?.key?.toLowerCase()}`
                  ),
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
