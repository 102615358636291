import styled from 'styled-components';

export { AccountHeaderElements, ReviewProgress } from '../../components';

export const Content = styled.div`
  text-align: center;
  padding: 86px 0;

  .profile-score-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  &.container-half + & {
    padding-top: 41px;
  }

  h1 {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    color: #111111;

    margin: 0 auto;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #111111;
    opacity: 0.32;
    max-width: 320px;
    margin: 0 auto;
  }

  .badge {
    width: 148px;
    height: 34px;
    background: rgb(163 124 82 / 24%);
    border-radius: 4px;

    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 24px auto;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #a37c51;
  }
`;
