import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import * as Styled from './User.style';
import { getUserInfo } from '../../../api';
import {
  Card,
  Header,
  Link,
  ProfileScore,
  ProgressRange,
  Section,
} from '../../../components';
import { GlobalContext } from '../../../hooks';
import { getUploads } from '../../../utils/functions';

import { ReactComponent as UserVerified } from '../../../assets/images/UserVerified.svg';
import { ReactComponent as Back } from '../../../assets/images/Back.svg';
import { ReactComponent as Instagram } from '../../../assets/images/Icons/instagram.svg';
import { ReactComponent as YouTube } from '../../../assets/images/Icons/youtube.svg';
import { ReactComponent as BuyAReview } from '../../../assets/images/Icons/buyAReview.svg';
import { MASTER_ROLES } from '../../../utils/constants';

export const UserPage = () => {
  const { setState: setGlobalState } = React.useContext(GlobalContext);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userData, setUserData] = React.useState({});

  if (!userId) {
    navigate('/account/rank-list');
  }

  React.useEffect(() => {
    if (!userData.id || userData.id !== userId) {
      setGlobalState({ loading: true });

      getUserInfo(userId)
        .then((res) => {
          setUserData(res.data);

          setTimeout(() => {
            setGlobalState({ loading: false });
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          setGlobalState({ loading: false });
          navigate('/account/rank-list');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userData.scores) {
    return;
  }

  const scoreGrouped = userData?.scores?.data?.reduce((acc, current) => {
    const allScoresGrouped = userData?.scores?.data.filter(
      (score) =>
        score.group === current?.group &&
        score?.score &&
        score.score?.category === current?.score?.category
    );

    if (
      MASTER_ROLES.includes(current?.group) &&
      !acc[current?.group]?.find(
        (item) => item.name === current?.score?.category
      ) &&
      current?.score
    ) {
      (acc[current?.group] = acc[current?.group] || []).push({
        name: current?.score?.category,
        range: [
          allScoresGrouped.reduce(
            (partialSum, a) =>
              partialSum +
              a?.score?.titles?.sort((a2, b2) => a2.value - b2.value)[0]?.value,
            0
          ),
          allScoresGrouped.reduce(
            (partialSum, a) =>
              partialSum +
              a?.score?.titles?.sort((a2, b2) => b2.value - a2.value)[0]?.value,
            0
          ),
        ],
        value: allScoresGrouped.reduce(
          (partialSum, a) => partialSum + a.value,
          0
        ),
        date: current?.createdAt,
      });
    }

    return acc;
  }, {});

  document.body.style.backgroundColor = '#000';
  return (
    <Styled.UserPageWrapper>
      <Header className='user_account'>
        <div className='container'>
          <Back
            onClick={() => navigate('/account/rank-list')}
            className='goBack desktop'
          />

          <Styled.AccountHeaderElements>
            <div className='user_photo'>
              <img
                src={getUploads(userData?.photo)}
                alt={`${userData.name} Avatar`}
                className='user_avatar'
              />
            </div>
            <p className='user_name'>
              {userData?.name} {userData?.verified && <UserVerified />}
            </p>
            <p className='user_group'>{userData?.title}</p>

            <div className='social_links'>
              {userData?.social?.instagram && (
                <a
                  href={userData?.social?.instagram}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Instagram />
                </a>
              )}
              {userData?.social?.youtube && (
                <a
                  href={userData?.social?.youtube}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <YouTube />
                </a>
              )}
            </div>
          </Styled.AccountHeaderElements>
        </div>
      </Header>

      <div className='container-half'>
        <ProfileScore
          user={userData}
          title={`${userData.first_name}'s Score`}
        />

        {Object.keys(scoreGrouped).map((group, iGroup) => {
          return (
            <Section key={iGroup}>
              <div className='flex-wrapper'>
                {scoreGrouped[group].map((score, iScore) => (
                  <Card
                    key={iScore}
                    className={'card-dark'}
                  >
                    <div className='header'>{score?.name}</div>
                    <div className='body'>
                      <ProgressRange
                        title='points'
                        range={score?.range}
                        value={score?.value}
                      />
                    </div>

                    <div className='footer flex'>
                      <div></div>
                      <p className='date'>
                        {moment(score?.date).format('MMMM D, YYYY')}
                      </p>
                    </div>
                  </Card>
                ))}
              </div>
            </Section>
          );
        })}
      </div>

      <div className='sticky-button'>
        <div className='container'>
          <Link
            to={'/account/buy-a-review'}
            variant='primary'
            className='button'
            styles={{
              padding: '16px 24px',
              display: 'flex',
              gap: '4px',
              textTransform: 'unset',
            }}
          >
            <BuyAReview
              width={16}
              height={16}
            />
            {t('buy_a_review.better_score')}
          </Link>
        </div>
      </div>
    </Styled.UserPageWrapper>
  );
};
