import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './SwiperContent.style';

import LocalPhiAward from '../../../assets/images/phiaward_local.jpg';
import GlobalPhiAward from '../../../assets/images/phiaward_global.jpg';
import RankingPhiAward from '../../../assets/images/phiaward_ranking list.jpg';

export const SwiperContent = () => {
  const { t } = useTranslation();

  return (
    <Styled.SwiperContentSection>
      <div className='container'>
        <h1
          className='title'
          data-aos='zoom-in-up'
          dangerouslySetInnerHTML={{ __html: t('home.swiper-content.title') }}
        />

        <div className='swiper-content'>
          <div className='swiper-slide'>
            <div className='info'>
              {/* <span className='page-number'>/ 01</span> */}
              <h3
                data-aos='fade-right'
                dangerouslySetInnerHTML={{
                  __html: t('home.swiper-content.swipes.0.title', {
                    escapeValue: false,
                  }),
                }}
              />
              <p
                data-aos='fade-up'
                dangerouslySetInnerHTML={{
                  __html: t('home.swiper-content.swipes.0.text', {
                    escapeValue: false,
                  }),
                }}
              />
            </div>

            <img
              src={LocalPhiAward}
              alt='Local Phi Award'
            />
          </div>

          <div className='swiper-slide'>
            <img
              src={GlobalPhiAward}
              alt='Global Phi Award'
            />

            <div className='info'>
              {/* <span className='page-number'>/ 02</span> */}
              <h3
                dangerouslySetInnerHTML={{
                  __html: t('home.swiper-content.swipes.1.title', {
                    escapeValue: false,
                  }),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('home.swiper-content.swipes.1.text', {
                    escapeValue: false,
                  }),
                }}
              />
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='info'>
              <p
                data-aos='fade-up'
                dangerouslySetInnerHTML={{
                  __html: t('home.swiper-content.swipes.2.text', {
                    escapeValue: false,
                  }),
                }}
              />
            </div>

            <img
              src={RankingPhiAward}
              alt='Local Phi Award'
            />
          </div>
        </div>
      </div>
    </Styled.SwiperContentSection>
  );
};
