import React from 'react';
import * as Styled from './Header.style';

export const Header = ({ className, children, dark, goBack, image }) => (
  <Styled.Header dark={dark} className={className} image={image}>
    {goBack && <a className='goBack' href={goBack}>Go Back</a>}

    <div className='body'>
      {children}
    </div>
  </Styled.Header>
);
