import styled from 'styled-components';

export const Header = styled.header`
  background-color: ${({ dark }) => (dark ? '#111111' : '#FAFAFA')};
  padding: 34px 0 132px;

  &.relative {
    position: relative;
  }

  .body {
    width: fit-content;
    margin: 0 auto;
    color: ${({ dark }) => (dark ? '#FAFAFA' : '#111111')};

    * {
      color: ${({ dark }) => (dark ? '#FAFAFA' : '#111111')};
    }
  }

  @media screen and (max-width: 768px) {
    height: ${({ image }) => image && '50vh'};
    background-image: ${({ image }) => image && `url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .body {
      display: flex;
      width: 90%;
      height: 100%;

      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }

    .user_avatar {
      display: none;
    }

    &.user_account {
      background: #000;
      & .body * {
        color: #f1f1f1;
      }

      .user_photo > .user_avatar {
        display: inline;
      }
    }
  }
`;
