import React from 'react';

import * as Styled from './Card.style';

export const Card = ({
  className,
  children,
  borderRadius,
  backgroundColor,
}) => (
  <Styled.Card
    className={`card ${className}`}
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
  >
    {children}
  </Styled.Card>
);
