import styled from 'styled-components';

export const Hero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  padding-top: 80px;

  color: #fff;
  background-color: #000;

  position: relative;

  .applyNow {
    display: none;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='183' height='183' viewBox='0 0 183 183' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M129.224 53.2098L53.2098 129.224' stroke='white'/%3E%3Cpath d='M53.2098 53.2097V129.224H129.224' stroke='white'/%3E%3C/svg%3E%0A");
    position: absolute;
    right: 0;
    bottom: -50px;

    z-index: 99;
  }

  & .container {
    display: flex;
    align-items: center;
  }

  & .text-wrap {
    display: flex;
    flex-wrap: wrap;

    width: fit-content;
    height: fit-content;
  }
  & .title {
    font-family: 'Saol Display';
    font-style: italic;
    font-weight: 300;
    font-size: 172px;
    line-height: 147px;
    color: #ffffff;

    margin: 0;
  }
  & .desc {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fafafa;

    margin: 0;

    max-width: 202px;
    align-self: flex-end;
    margin-left: 5%;
    margin-bottom: 30px;
  }
  & .heroIntroImage {
    position: relative;
    width: 100%;
    height: 100%;
    height: 300px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    height: 100%;
    overflow: hidden;

    & .text-wrap {
      width: 100% !important;
    }

    .applyNow {
      display: block;
      margin-top: 41px;
    }

    .heroIntroImage {
      & > img {
        margin-left: 50px;
      }
    }

    .container {
      flex-direction: column;
    }
    & .title {
      order: 1;
      font-size: 91px;
      line-height: 91px;
    }
    & .desc {
      order: 2;
      margin: 0;
      max-width: unset;
    }
  }
`;

export const IntroWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;

  z-index: 99999999999;
  gap: 24px;

  background-color: #000;
  overflow: hidden;

  .col {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &:first-child {
      margin-left: -141px;
    }

    img {
      /* width: 282px;
      height: 406px; */
      width: 100%;
      height: 100%;
    }
  }
`;
