import Api from './Api';

export const getAllScoreGroups = async () =>
  await Api.http({
    method: 'get',
    url: '/score-details/groups',
  });

export const getAllScoreCategories = async () =>
  await Api.http({
    method: 'get',
    url: '/users/score-details/categories',
  });
