import { useEffect } from 'react';
import styled from 'styled-components';

import { BottomStickyBar } from '../components';

const Wrapper = styled.div`
  span.s1 {
    text-decoration: underline;
  }
`;

export const TermsAndConditions = () => {
  document.body.style.backgroundColor = '#000';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Wrapper className='policy-page container'>
        <p style={{ textAlign: 'center' }}>
          General Terms and Conditions for PhiAwards
        </p>
        <ul>
          <li>We are pleased that you have decided to use PhiAwards</li>
          <li>
            These General Terms and Conditions govern the use of the "PhiAwards"
            Platform and provide information about rights and obligations and
            provide further details about our services. For consumers in the
            sense of § 1 Austrian Consumer Protection Act (KSchG), these GTC
            apply unless otherwise specified. For consumers, the terms and
            conditions set forth in sec. 16 are to be observed.
          </li>
          <li>Introduction</li>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>Who are we?</span>
            </li>
          </ul>
          <li>
            We are PhiAcademy GmbH, FN 461082 m, located at Wiegelestraße 10
            1230 Wien (hereinafter "PHI", "we" or "us"), and we are the operator
            of the web application "PhiAwards" (hereinafter the "Platform").
          </li>
          <li>
            The Platform offers beauty artists and especially members of the
            PhiAcademy community the opportunity to have their work officially
            evaluated by PHI and receive recognition for it. The results of the
            evaluation are made available to all users of the Platform. The
            Platform also keeps users up to date on news from the PhiAcademy
            community.
          </li>
          <li>
            You may use the Platform in accordance with these Terms and
            Conditions ("Ts&amp;Cs"). Please read these Ts&amp;Cs carefully and
            contact us if you have any questions. By using the Platform or
            subscribing to use the Platform, you agree to be bound by these
            Ts&amp;Cs. Any provisions deviating from these Terms and Conditions
            shall only apply if confirmed by us in writing.
          </li>
          <li>
            You can contact us by mail or email at the following address:
            phiawards@phiacademy.com&nbsp;
          </li>
          <li>Definitions</li>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>Platform</span>: The
              "PhiAwards" web application.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                PhiAcademy Community
              </span>
              : Artists, Royals, Master Associates, Masters &amp; Grand Masters
              of PhiAcademy GmbH.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                PhiAcademy Beauty Courses
              </span>
              : PhiBrows, PhiContour, BoldBrows, PhiSombre, PhiShading,
              PowderBrows, NanoBrows PMU, PhiLips, BreezyBrows, LatinBrows,
              DesignerBrows.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Top Lists</span>:
              PhiAwards top list of all participants evaluated by PHI.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>User</span>: Any
              visitor to the Platform.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Registered User
              </span>
              : Any User who is registered on the Platform.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Verified User</span>
              : Any Registered User whose profile has been verified by
              PHI.&nbsp;
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Participants</span>:
              Any verified User who has purchased a paid subscription to use the
              Platform.
            </li>
          </ul>
          <li>Registration</li>
          <ul>
            <li>
              In order to use all functions of the Platform, a one-time
              registration is required. The registration process leads to the
              creation of your profile.&nbsp;
            </li>
            <li>
              For registration, you must provide the data requested in the
              registration form (for example, first name, last name, date of
              birth, company, location) completely and correctly, unless this
              information is marked as voluntary. After registration, the
              respective profile can be created.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Consequences of registration
              </span>
            </li>
          </ul>
        </ul>
        <p>
          By registering, you confirm that you accept these Ts&amp;Cs, that all
          registration information is true, accurate, current, and complete, and
          that you will keep all information up to date.
        </p>
        <ul>
          <li>Verification</li>
          <ul>
            <li>
              After successful registration, the data provided will be checked
              by PHI for correctness. In particular, PHI will verify if the
              Registered User has successfully completed the specified
              PhiAcademy Beauty Courses. Verification is only possible if the
              Registered User has successfully completed at least one of the
              PhiAcademy Beauty Courses.
            </li>
            <li>
              In case of significant discrepancies, the Registered User will be
              informed about them, and the profile will be marked as "unverified
              user". To complete the verification process, the Registered User
              must contact PHI and resolve the significant discrepancies by
              providing appropriate evidence.
            </li>
          </ul>
          <li>Subject of the contract</li>
          <ul>
            <li>Users of the Platform can view the top lists.&nbsp;</li>
            <li>
              Registered Users have access to the following additional functions
              on the Platform:
            </li>
          </ul>
          <li>Access to their own area on the Platform;</li>
          <li>personal profile.</li>
          <ul>
            <li>
              In addition to this, Verified Users can purchase a paid
              subscription to evaluate their work (see section 5.4).
            </li>
            <li>
              Upon conclusion of a paid subscription, the work of the
              Participant is evaluated by PhiAcademy on the basis of criteria
              defined by us (e.g. skill, marketing, event activity, hygiene,
              participation in humanitarian activities, compliance with the
              rules of PhiAcademy, progress status, influence status, master
              activities; for further information on how we evaluate the
              Participants work you can contact us via
              phiawards@phiacademy.com).The review will take place once a year,
              close in time to the conclusion of the subscription. This
              evaluation includes all work related to PhiAcademy Beauty Courses
              that the Participant has completed at the time of review. In order
              to improve the quality of the Participant's work, PhiAcademy will
              provide guidelines and feedback to the Participant after review.
            </li>
            <li>
              Participants may again request an additional paid evaluation at
              any time during a subscription period after a review has been
              completed.&nbsp;
            </li>
            <li>
              Details on the functionalities can be found on the Platform at
              https://phiawards.phi-academy.com.
            </li>
          </ul>
          <li>Subscription regulations&nbsp;</li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          The evaluation of the work by PhiAcademy requires the conclusion of a
          paid subscription. We provide information about the different
          subscription models and the applicable prices as part of the
          subscription process.
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>Payment</span>
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          All payments and subscription fees are processed through the
          Platform.&nbsp;You can choose from the available payment methods. You
          will be informed about the available payment methods during the
          subscription process.&nbsp;By selecting your payment method, you
          confirm to be in agreement with the selected subscription. The payment
          process is carried out on our behalf by an online payment processor.
          Please note that online payment processors have their own terms and
          conditions, which may apply in addition to these Ts&amp;Cs. You will
          be informed about the current online payment processor during
          the&nbsp;subscription process.
        </p>
        <p style={{ textAlign: 'justify' }}>
          Once you have subscribed, we will automatically charge you the
          applicable fee until your subscription ends (e.g. upon cancellation).
          We may change the price of paid subscriptions, including recurring
          subscription fees. If we do so, we will notify you of such price
          changes in advance. Price changes will be effective at the beginning
          of the subscription period following the date of the notice of the
          price change. If you do not agree with the price change, you have the
          right to cancel your subscription (see section 12.1.) before the
          change becomes effective.
        </p>
        <p style={{ textAlign: 'justify' }}>
          If you request an additional paid evaluation during a subscription
          period (see section 5.5.) the respective payment will also be
          processed through the Platform and automatically charged to the
          payment method, you have chosen for your subscription.&nbsp;
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Contract duratio
              </span>
              n&nbsp;
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          The duration of the subscription is one year starting from the day you
          concluded the contract for the paid subscription.&nbsp;
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Automatic renewal
              </span>
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          Your subscription will be automatically renewed for one year at the
          end of the respective period and you will be automatically charged the
          fee for the selected subscription. Your subscription will be renewed
          for the duration of the period once selected as long as you do not
          cancel your subscription.
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>Cancellation</span>
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          You can cancel your paid subscription at any time but at least one
          month before the end of the current subscription period. Cancellations
          can be made through the Platform via your profile settings.
        </p>
        <p style={{ textAlign: 'justify' }}>
          The cancellation takes effect at the end of the selected subscription
          period.&nbsp;Until the end of this period, you may continue to use the
          functionalities of the paid subscription; the subscription is
          terminated when the period expires. You can find the duration of your
          current subscription period in the settings of your profile.
        </p>
        <p>
          Upon effectiveness of the termination, you may continue to use the
          functions of a Verified User.
        </p>
        <ul>
          <li>Our rights and duties</li>
          <ul>
            <li>
              The scope of the functionalities provided on the Platform depends
              on whether you are registered, verified or whether you have
              purchased a paid subscription.&nbsp;
            </li>
            <li>
              We grant you a personal, worldwide, non-assignable, non-exclusive,
              revocable and non-sublicensable license to use the Platform for
              the duration of the paid subscription (Participant) or for the
              duration of any other contractual relationship with us. This
              license is only for the purpose of using the provided
              functionalities of the Platform in a manner permitted by these
              Ts&amp;Cs. No rights to industrial property rights within the
              scope of the Platform (e.g. logos or other trademark information)
              may be derived by you from the use of the Platform.
            </li>
            <li>
              We may block, revoke, or restrict your use of the Platform if we
              determine that there has been a breach of your obligations, a
              security breach or a violation of law. If the reason for the
              action taken can be remedied in a proportionate manner, we will
              notify you of the steps you must take in order for us to allow you
              to use the Platform again. If you do not comply with our requests
              within a reasonable period of time, we may terminate the
              contractual relationship with you with immediate effect.
            </li>
            <li>
              All logins are individualized and may only be used by you
              personally. You are obliged to keep your login and password secret
              and to protect them from access by unauthorized third parties.
              Should we be notified by you of unauthorized use by a third party,
              we may block the access of the unauthorized User.
            </li>
          </ul>
          <li>Your rights and obligations&nbsp;</li>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Profile content
              </span>
            </li>
            <ul>
              <li>
                After registration you can create your profile. You are solely
                responsible for the content of your profile. We are not
                responsible for inaccurate or illegal content entered by Users.
              </li>
            </ul>
            <li>Every User of our services is obliged to:</li>
            <ul>
              <li>
                provide his registration data truthfully and to keep it up to
                date and complete and not to disclose it to third parties;
              </li>
              <li>
                not to violate any intellectual property rights or industrial
                property rights of other persons or data protection rights with
                the contents of his profile or to violate competition law;
              </li>
              <li>
                not to copy, modify, distribute, sell or rent any part of the
                Platform, or reverse engineer or attempt to extract the source
                code of the Platform;
              </li>
              <li>
                not to distribute, sell or otherwise commercially exploit the
                guidelines made available on the Platform;
              </li>
              <li>
                not to store, publish, transmit or distribute any content that
                is racist, offensive, discriminatory, denouncing, glorifying
                violence or otherwise unlawful;
              </li>
            </ul>
            <li>
              If you breach any of your obligations, we may, without prejudice
              to any further claims, block, delete, restrict or terminate your
              profile.
            </li>
          </ul>
          <li>Additional obligations for Participants</li>
          <ul>
            <li>
              When providing material for evaluation (e.g. photos of their
              customers), Participants warrant that such material has been
              collected in compliance with all statutory provisions, including
              but not limited to the provisions of the GDPR (General Data
              Protection Regulation) and that such material may be used for the
              purpose of this contract. Participants shall indemnify, defend and
              hold us, PhiAcademy GmbH, our affiliates and their respective
              officers, directors, employees and agents harmless from and
              against any and all claims, actions, proceedings, disputes,
              demands, liabilities, damages, losses, costs and expenses,
              including, but not limited to, reasonable legal fees and
              accounting fees (including the costs of defending any claim,
              action or proceeding brought by a third party) related in any way
              to the provision of material provided by the Participant.
            </li>
            <li>
              Regarding the photos provided (including photos made available via
              the provision of a social media or website link), Participants are
              obliged to provide us with proof, upon our request, that their
              customer has consented to the publication of their photo.
            </li>
          </ul>
          <li>Additional conditions for all Users</li>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Fraud protection&nbsp;
              </span>
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          In the event that we become aware of any unauthorized or fraudulent
          use of an account, we reserve the right to close or delete the
          account.
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Free offers&nbsp;
              </span>
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          From time to time, we may make free offers that may be subject to
          additional terms or restrictions.
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Changes to the Platform
              </span>
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          We reserve the right to change options or features - including
          features for Participants - on the Platform. We will make such changes
          primarily to improve the Platform.&nbsp;
        </p>
        <ul>
          <li>Setting and restriction</li>
          <ul>
            <li>
              We reserve the right to add or remove features or functionality,
              and to discontinue our services altogether. We may take these
              actions at any time without any obligation to provide prior
              notice.
            </li>
            <li>
              Without prejudice to section 11.1., Participants shall be notified
              in advance, if possible, of the discontinuation of the Platform or
              a significant restriction of the functions of the Platform. After
              notification of discontinuation, the Platform may still be used
              for the duration of the current subscription period. If we decide
              to discontinue the Platform, your subscription will no longer be
              automatically renewed and will expire at the end of the current
              period. In the event of a material limitation of the Platform's
              features, Participants have the right to terminate their
              subscription effective as of the date of such limitation. In this
              case, any fee already paid will be refunded to the Participant on
              a pro rata basis. We will inform you about such discontinuation or
              restriction either by e-mail or via the Platform.
            </li>
          </ul>
          <li>Termination of the contract</li>
          <ul>
            <li>
              You have the right to terminate the contract with us at any time
              and for any reason by cancelling your subscription (Participant,
              cf. section 6.4.) or delete your profile (Registered User). Please
              note that subscriptions must be cancelled separately. Deleting
              your profile does not automatically cancel your subscription. As
              long as your subscription is not cancelled, you will be charged
              the corresponding fee.&nbsp;
            </li>
            <li>
              We reserve the right to terminate the profile of a Registered User
              and the contract with a Participant at any time. Termination of a
              Registered User's profile is effective immediately. If the
              contract with a Participant is terminated, it will end at the end
              of the current subscription period. We reserve the right to
              terminate a contract with a Participant for cause with immediate
              effect, such as breach of the Participant’s obligations set forth
              in these Terms.
            </li>
          </ul>
          <li>Indemnification</li>
          <ul>
            <li>
              You agree to indemnify, defend and hold us, PhiAcademy GmbH, our
              affiliates and their respective officers, directors, employees and
              agents harmless from and against any and all claims, actions,
              proceedings, disputes, demands, liabilities, damages, losses,
              costs and expenses, including, but not limited to, reasonable
              legal fees and accounting fees (including the costs of defending
              any claim, action or proceeding brought by a third party) related
              in any way to your access to or use of our Platform, the contents
              of your Profile, or your breach of these Ts&amp;Cs.&nbsp;
            </li>
          </ul>
          <li>Warranty</li>
          <ul>
            <li>
              The functions and all contents of the Platform, including their
              availability, are provided without any express or implied
              warranty.&nbsp;
            </li>
            <li>
              Notwithstanding section 14.1, we warrant that the Platform has the
              properties usually assumed for a Platform of this type. Defects of
              the Platform shall be remedied by us within a reasonable period of
              time after the Participant has notified us of the defect.
            </li>
            <li>
              Any obligation on our part to provide updates to the Platform is
              excluded. Updates to the Platform may be provided by us on a
              voluntary basis. Even the regular provision of updates does not
              give rise to a claim for the provision of updates in the future
              (exclusion of § 7 VGG – Austrian Consumer Warranty Act).
            </li>
            <li>
              Except as required by law, we assume no responsibility and shall
              have no liability for any content that you or any other person or
              third party posts or transmits using the Platform.
            </li>
          </ul>
          <li>Limitation and exclusion of liability</li>
          <ul>
            <li>
              We shall be liable without limitation for intentional acts or
              bodily injury. In the case of negligence, our liability is limited
              to the foreseeable damage that arises in the event of a breach of
              essential obligations that are typical for the contract. We are
              not liable for damages caused by slight negligence, indirect
              damages and consequential damages as well as lost profit,
              business, value, turnover, goodwill or lost savings.
            </li>
            <li>
              The above limitations of liability shall also apply&nbsp;
              <i>mutatis mutandis in&nbsp;</i>favor of our vicarious agents.
            </li>
            <li>
              Unless required by law, neither we nor any of our affiliates shall
              be liable for any damages arising from the use of content made
              available through the Platform (e.g. through links or hyperlinks).
              This also applies to damages caused by errors, problems, viruses
              or loss of data. We are also not liable for the accessibility,
              existence or security of such accessible databases or services or
              for their content. In particular, we assume no liability for the
              legal admissibility, accuracy, completeness, timeliness, etc. of
              such content.
            </li>
          </ul>
          <li>Changes to the Ts&amp;Cs</li>
          <ul>
            <li>
              We reserve the right to change these Ts&amp;Cs from time to time
              for various reasons, including, without limitation, for commercial
              reasons, to comply with applicable laws or regulations, or for
              customer service reasons. The current version of the Ts&amp;Cs is
              available at all times on the Platform and at (https://
              <i>phiawards.phi-academy.com</i>)). Such changes will only be made
              in writing, verbal side agreements do not exist.
            </li>
            <li>
              In the event of minor changes to the Ts&amp;Cs (for example, if
              there is a change in the law that requires a change to the
              Ts&amp;Cs), we will make the new Ts&amp;Cs available for
              information on the Platform and at (https://
              <i>phiawards.phi-academy.com</i>).Please review the Ts&amp;Cs
              periodically to ensure that you understand the current version. In
              the event of material changes to the Ts&amp;Cs that do not have a
              merely minor adverse effect on you, we will either obtain your
              express consent to the changes via the Platform, or we will notify
              you of the changes by email to the address provided during
              registration within a reasonable period of time before the changes
              take effect ("Change Information"). You have an objection period
              of two weeks from receipt of the Change Information.
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          The Change Information contains the updated Ts&amp;Cs, the date on
          which the changes come into effect, a two-week objection period and
          instructions on the consequences of failing to object.
        </p>
        <p style={{ textAlign: 'justify' }}>
          If you do not object to the changes within that period, the updated
          Ts&amp;Cs are deemed accepted.
        </p>
        <p style={{ textAlign: 'justify' }}>
          In the event that you object to the changes, we are entitled to
          terminate the contract and delete your profile if the continuation of
          the contractual relationship under the previous conditions is not
          possible or not economically reasonable for us.&nbsp;
        </p>
        <ul>
          <li>
            Special provisions for consumers within the meaning of § 1 KSchG
          </li>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Right of withdrawal
              </span>
              : If you register to use the Platform or subsequently conclude a
              paid subscription, you enter into a contract with us. If you are a
              consumer as defined by § 1 of the Consumer Protection Act (KSchG),
              you have the right to withdraw from this contract without giving
              any reason (in accordance with the provisions of the Distance and
              Off-Site Selling Act, FAGG). The withdrawal period is 14 days from
              the day on which you concluded the contract (day of registration
              of your profile or conclusion of a paid subscription).&nbsp;
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          If you wish to withdraw from our contract for the use of the Platform,
          you may exercise your right of withdrawal by deleting your profile
          (Registered User) or by using the form below and sending it to us
          (Participant):
        </p>
        <p>
          <i>Cancellation form</i>
        </p>
        <p>
          <i>-To</i>
        </p>
        <p>
          <i>PhiAcademy GmbH, Wiegelestraße 10, 1230 Wien; e-mail:&nbsp;</i>
          phiawards@phiaca<i>demy.com&nbsp;</i>
        </p>
        <p>
          <i>
            I/we (*) hereby revoke the contract concluded by me/us (*) for the
            purchase of the following goods (*)/provision of the following
            service (*)&nbsp;
          </i>
        </p>
        <p>
          <i>-Ordered on (*)/Received on (*)&nbsp;</i>
        </p>
        <p>
          <i>-Name of the consumer(s)</i>
        </p>
        <p>
          <i>-Address of the consumer(s)</i>
        </p>
        <p>
          <i>
            -Signature of the consumer(s) (only in case of notification on
            paper)
          </i>
        </p>
        <p>
          <i>-Date</i>
        </p>
        <p>
          <i>(*) Delete as applicable.</i>
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Consequences of revocation:&nbsp;
              </span>
              If you revoke this contract, we must return to you all payments
              that we have received from you immediately and at the latest
              within fourteen days from the day on which we received the
              notification of your revocation of this contract. For this
              repayment, we will use the same means of payment that you used for
              the original transaction, unless expressly agreed otherwise with
              you; in no case will you be charged for this repayment. If you
              have requested that the services begin during the withdrawal
              period, you shall pay us a reasonable amount corresponding to the
              proportion of the services already provided up to the time you
              notify us of the exercise of the right of withdrawal with respect
              to this contract compared to the total scope of the services
              provided for in the contract.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Exclusion of the right of withdrawal:
              </span>
              &nbsp;Your right of withdrawal is excluded if you have requested
              that the services begin during the withdrawal period, and we have
              already provided our service in its entirety.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>Warranty</span>: We
              warrant that the Platform has the objectively required
              functionalities (compared to similar services); no other materials
              (in particular any marketing statements) shall be relevant for
              such warranty.&nbsp;
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          If you claim a bug or error of the Platform as a defect, then you are
          obliged to assist us in checking whether the defect is caused by your
          digital environment to the extent reasonably necessary and possible
          for this purpose. This obligation to cooperate is limited to the
          technically available means that involve the least interference for
          you. If you fail to comply with this obligation, it shall be your
          responsibility to prove that the defect existed during the period
          relevant for our warranty obligation.
        </p>
        <ul>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Updating the Platform
              </span>
              : The exclusion of the obligation to update (see section 14.3)
              only applies if you agreed to this when registering your profile.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Discontinuation and restriction
              </span>
              : If we intend to change our service, you will be informed of
              this. In case of a not only minor impairment, we will inform you
              reasonably in advance about the features and the time of the
              change. If you do not agree with a significant change, you can
              terminate the contractual relationship with us at any time by
              sending an informal notice to phiawards@phiacademy.com. You will
              then only be charged a pro rata fee until the termination becomes
              effective.
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                Termination of the contract
              </span>
              : Notwithstanding section 12.2 we reserve the right to terminate
              the profile of a Registered User with a one-month notice period.
              Termination of the profile of a Registered User for cause is
              effective immediately.
            </li>
          </ul>
          <li>Applicable law and place of jurisdiction&nbsp;</li>
          <ul>
            <li>
              These Ts&amp;Cs and all legal relationships in connection
              therewith shall be governed by the substantive laws of Austria
              with the exception of its conflict of law provisions and the UN
              Convention on Contracts for the International Sale of Goods.
              Notwithstanding this provision, the mandatory provisions of the
              national law of Users who are consumers within the meaning
              of&nbsp;§ 1 Consumer Protection Act (KSchG), or within the meaning
              of the respective applicable national consumer protection laws
              shall apply.
            </li>
            <li>
              Any disputes arising out of or in connection
              with&nbsp;these&nbsp;Ts&amp;Cs,&nbsp;in particular its formation,
              validity, binding effect, interpretation, performance, breach or
              termination as well as non-contractual claims, shall be subject to
              the jurisdiction of the court in Vienna having subject-matter
              jurisdiction for commercial matters. This section 18.2 shall not
              apply to Users who&nbsp;are consumers within the&nbsp;meaning of §
              1 Consumer Protection Act (KSchG).
            </li>
            <li>
              Notwithstanding the above agreement on the place of jurisdiction,
              PhiAcademy GmbH has the right to bring an action before the courts
              having local and subject matter jurisdiction over the User or to
              apply for interim legal protection.
            </li>
          </ul>
          <li>Final provision</li>
          <ul>
            <li>
              If any provision or part of a provision of
              these&nbsp;Ts&amp;Cs&nbsp;is or becomes invalid under applicable
              law, this shall not affect the validity and effectiveness of the
              remaining provisions.
            </li>
          </ul>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          In the event that a provision of these&nbsp;Ts&amp;Cs&nbsp;is invalid,
          it shall be deemed to be replaced by a provision that comes closest to
          the invalid provision in terms of content. Notwithstanding the
          foregoing, in the case of contracts concluded with consumers within
          the meaning of § 1 Consumer Protection Act (KSchG), an invalid
          provision shall be omitted without replacement. Instead of the omitted
          provision, the statutory provisions shall apply, provided that the
          contract cannot continue without the omitted provision, and this is
          not disadvantageous for the consumer.&nbsp;
        </p>
        <p style={{ textAlign: 'justify' }}>
          NOTE: In a period from 08th to 11th July 2024, we provide supscription
          discount for Participant who apply in this period, in a amount of 30%
          of regular price and the price for Phiaward prescriptions will be
          104+VAT.*
        </p>
        <p style={{ textAlign: 'justify' }}>
          *Discount is available only and exclusively for supscription made in a
          period from 08th to 11th July 2024.
        </p>
        <p style={{ textAlign: 'justify' }}>
          ** Discount is not available for Participants with Master title within
          Phiacademy.
        </p>
        <p>
          ***Discount will be effective for limited period of 1 (one) year and
          for Participants, who subscribe for reduced price of 104+VAT in period
          from 08th to 11th July 2024, their subscription will be renewed at the
          standard price after expiration of 1 (one) year from subscription for
          discount.
        </p>

        <br />
        <p class='p6'>Version 2024</p>
      </Wrapper>

      <BottomStickyBar />
    </>
  );
};
