import React from 'react';

import * as Styled from './SideMenu.style';

import { ReactComponent as Back } from '../../assets/images/Back.svg';
import { LanguageSelector } from '../Navbar/Navbar';

export const SideMenu = ({ isOpen, closeMenu, links = [] }) => {
  return (
    <>
      <Styled.Overlay
        className={`${isOpen ? 'show' : ''}`}
        onClick={closeMenu}
      />
      <Styled.SideMenu className={`${isOpen ? 'open' : ''}`}>
        <div className='header flex'>
          <Back
            onClick={closeMenu}
            className='goBack'
          />

          <LanguageSelector />
        </div>

        <ul>
          {links.map((link, iLink) => (
            <li
              key={iLink}
              onClick={closeMenu}
            >
              {link}
            </li>
          ))}
        </ul>
      </Styled.SideMenu>
    </>
  );
};
