import styled from 'styled-components';

export const CompetitionSection = styled.section`
  position: relative;
  background-color: #000;
  padding: 80px 0;
  overflow-x: hidden;

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='183' height='183' viewBox='0 0 183 183' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M53.2097 53.2106L129.224 129.225' stroke='white'/%3E%3Cpath d='M129.224 53.2095V129.223H53.2098' stroke='white'/%3E%3C/svg%3E%0A");
    position: absolute;
    left: 0;
    top: -50px;

    z-index: 99;
  }

  .top_of_section {
    max-height: 865px;
    height: 100vh;
    position: relative;

    .center {
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: fit-content;

      z-index: 9999;
    }

    .subtitle {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #a37c51;
      margin-bottom: 72px;
    }

    h1.title {
      font-family: 'Saol Display';
      font-style: normal;
      font-weight: 300;
      font-size: 114px;
      line-height: 104px;
      text-align: center;
      color: #fafafa;

      height: fit-content;
      margin: 0 auto;
    }
  }

  .bottom_of_section {
    width: 80%;
    border-bottom: 1px solid #2f2f2f;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-left: auto;
    gap: 24px;
    margin-top: -10%;
    z-index: 9999999;
    position: relative;

    p {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #fafafa;

      max-width: 384px;
      margin: 23px 0;
    }
  }

  .image1,
  .image2 {
    p {
      position: absolute;
      bottom: 12%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: fit-content;
      z-index: 1;

      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #fafafa;
      /* text-align: center; */
    }
  }

  .image1 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .image2 {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media screen and (max-width: 768px) {
    .image1,
    .image2 {
      p {
        margin: 0;
        font-size: 28px;
        line-height: 34px;
      }
    }

    .top_of_section {
      .center {
        top: 30%;
      }

      h1.title {
        font-size: 50px;
        line-height: 50px;
      }

      .image1,
      .image2 {
        width: 70%;

        img {
          width: 100%;
        }
      }
    }
    .bottom_of_section {
      width: 100%;
      flex-direction: column;
      border-bottom: 0;

      p {
        margin: 0;
      }
    }
  }
`;
