export const MASTER_ROLES = [
  'Artist',
  'Royal Artist',
  'Master Associate',
  'CraftMaster',
  'Grand Master',
];

export const TECHNIQUES = [
  'PhiBrows',
  'PhiContour',
  'BoldBrows',
  'PhiSombre',
  'PhiShading',
  'PowderBrows',
  'NanoBrows PMU',
  'PhiLips',
  'BreezyBrows',
  'LatinBrows',
  'DesignerBrows',
];

export const SocialLinks = {
  instagram: 'https://instagram.com/',
  facebook: 'https://facebook.com/',
  tiktok: 'https://www.tiktok.com/',
  youtube: 'https://www.youtube.com/',
};
