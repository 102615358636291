import styled from 'styled-components';

export const Section = styled.section`
  margin-top: 64px;

  .flex-wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 24px;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    .flex-wrapper {
      flex-direction: row;

      & > * {
        width: calc(50% - 12px);
      }
    }
  }
`;

export const Title = styled.div`
  position: relative;
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #111111;
  margin-bottom: 24px;

  display: flex;
  align-items: center;

  span.group:nth-child(1) {
    /* opacity: 0.24; */
    margin-right: 4px;
    word-break: keep-all;
    flex-shrink: 0;
  }

  span.group:nth-child(2) {
    flex-shrink: 0;
  }

  &::after {
    content: '';
    width: calc(100% - 16px);
    height: 1px;
    background-color: #d9d9d9;
    margin-left: 16px;
  }
`;
