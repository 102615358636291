import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUserScoresByCategory } from '../../../api';
import { ImproveScoreModal, ScoreCard } from '../../../components';
import { useAuth } from '../../../hooks';

import * as Styled from './Scores.style';

export const ScoresPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { category, group } = useParams();

  const [data, setData] = React.useState([]);
  const [improveScore, setImproveScore] = React.useState(null);
  const [showHowToImprove, setHowToImprove] = React.useState(false);

  React.useEffect(() => {
    document.body.style.backgroundColor = '#f5f5f5';
  }, []);

  React.useEffect(() => {
    getUserScoresByCategory(user.id, category, group)
      .then((res) => setData(res.data))
      .catch(console.error);
  }, [category, group, user.id]);

  const getNextCategory = () => {
    const allScores = user?.scores?.data?.reduce((acc, score) => {
      if (
        score.score?.category &&
        !acc.some((item) => item.score?.category === score.score?.category)
      ) {
        acc.push(score);
      }

      return acc;
    }, []);

    return allScores
      ?.filter(
        (score) => score?.score?.category && score?.score?.category !== category
      )
      ?.reduce((acc) => {
        const curCatIndex = allScores?.findIndex(
          (item) => item.score?.category === category
        );
        const nextCatIndex = curCatIndex + 1;

        if (nextCatIndex < allScores.length) {
          acc = allScores[nextCatIndex];
        } else {
          acc = allScores[0];
        }

        return acc;
      }, null);
  };

  const nextCategory = getNextCategory();

  return (
    <>
      <Styled.Wrapper>
        <div className='container'>
          <div className='breadcrumb'>
            <p className='current'>
              {data?.[0]?.score?.translations?.[i18n.language]?.category ||
                data?.[0]?.score?.category ||
                category}
            </p>

            {nextCategory && (
              <p
                className='next'
                onClick={() =>
                  navigate(
                    `/account/profile/scores/${nextCategory.group}/${nextCategory.score.category}`
                  )
                }
              >
                <span>
                  {nextCategory?.score?.translations?.[i18n.language]
                    ?.category || nextCategory?.score?.category}
                </span>
                {t('profile.scores.next')}
                <svg
                  width='48'
                  height='48'
                  viewBox='0 0 48 48'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clipPath='url(#clip0_1111_12725)'>
                    <path
                      d='M19 24H29'
                      stroke='#111111'
                    />
                    <path
                      d='M24 19L29 24L24 29'
                      stroke='#111111'
                    />
                  </g>
                  <circle
                    r='23.5'
                    transform='matrix(-1 0 0 1 24 24)'
                    stroke='#DFDFDF'
                  />
                  <defs>
                    <clipPath id='clip0_1111_12725'>
                      <rect
                        width='12'
                        height='12'
                        fill='white'
                        transform='translate(18 18)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </p>
            )}
          </div>
        </div>

        <div className='container-half'>
          {data?.map((score, index) => (
            <ScoreCard
              score={score}
              key={`s${index}`}
              showHowToImprove={() => {
                if (score?.improveScore) {
                  setImproveScore(score?.improveScore);
                  setHowToImprove(!showHowToImprove);
                }
              }}
              hideImproveButton={!score?.improveScore}
            />
          ))}
        </div>
      </Styled.Wrapper>

      <ImproveScoreModal
        showModal={improveScore && showHowToImprove}
        closeModal={() => setHowToImprove(false)}
        improveScore={improveScore}
      />
    </>
  );
};
