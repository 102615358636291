import styled from 'styled-components';

import Checked from '../../../assets/images/Checked.svg'

export const AccountSteps = styled.div`
  background: #111111;
  color: #e4e4e4;
  border-radius: 8px;

  display: flex;
  align-items: center;

  height: 72px;
  overflow: hidden;

  .item {
    position: relative;
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    
    & .checked {
      content: url(${Checked});
      position: absolute;
      top: 10px;
      left: 30px;
      right: 0;
      margin: 0 auto;
    }

    &:not(.active) {
      background: #e4e4e4;
      color: #111;

      svg {
        opacity: 0.2;
      }
    }

    &:not(:last-child)::after {
      content: url("data:image/svg+xml,%3Csvg width='8' height='72' viewBox='0 0 8 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M1 29L7 35.5L1 42' stroke='white'/%3E%3Cline opacity='0.2' x1='0.5' y1='29' x2='0.499999' y2='2.18557e-08' stroke='white'/%3E%3Cline opacity='0.2' x1='0.5' y1='72' x2='0.500001' y2='42' stroke='white'/%3E%3C/svg%3E%0A");
      position: absolute;
      right: -6px;
      width: fit-content;
      height: 100%;
      z-index: 9999;
    }
    &.last-active::after {
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0L6 6.5L0 13' fill='black'/%3E%3C/svg%3E%0A");
      height: fit-content;
    }
  }
`;
