import styled from 'styled-components';

const StyledReviewProgress = styled.div`
  width: 100%;

  &.absolute {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .progress-value {
    font-family: 'Saol Display';
    font-style: italic;
    font-weight: 300;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;

    width: fit-content;
    margin-left: calc(${({ value }) => value || '60'}% - 1.5ch);

    &::after {
      content: '';
      width: 1px;
      height: 18px;
      background-color: #fff;
      display: block;
      margin: 0 auto 8px;
    }
  }
  .progress {
    width: 100%;
    height: 4px;
    background-color: #d9d9d9;

    position: relative;

    .line {
      position: absolute;
      width: ${({ value }) => value || '60'}%;
      height: 100%;
      background-color: #a37c51;
    }
  }
`;

export const ReviewProgress = ({ className, value }) => (
  <StyledReviewProgress className={className} value={value}>
    <div className='progress-value'>{value}%</div>

    <div className='progress'>
      <div className='line'></div>
    </div>
  </StyledReviewProgress>
);
