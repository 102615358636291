export const getUploads = (filename) => {
  return `${process.env.REACT_APP_API_STORAGE}/${filename}`;
};

export const getScoreRange = (titles) => {
  const sorted = titles.sort((a, b) => a.value - b.value);

  return [sorted[0].value, sorted[sorted.length - 1].value];
};

export const getScoreRangeByLength = (titles) => {
  const sorted = titles.sort((a, b) => a.value - b.value);

  return [
    sorted[0].value,
    sorted[sorted.length - 1].value / (sorted.length + 1),
  ];
};

export const isScoreCompleted = (score) => {
  if (!score || !score.score || score.score.titles.length === 0) {
    return null;
  }

  const sorted = score?.score?.titles?.sort((a, b) => a.value - b.value);

  let highValue = sorted[sorted.length - 1].value;
  if (sorted.length >= 3) {
    highValue = sorted[sorted.length - 2].value;
  }

  return score.value >= highValue;
};

export const getSocialUserName = (url) => {
  return url.split('/')[4];
};
