import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../../../hooks';
import { Navbar } from '../../../components';

import * as Styled from './Settings.style';

export const Settings = () => {
  const { isLoggedIn, logout } = useAuth();
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.style.backgroundColor = '#f5f5f5';
  }, []);

  if (!isLoggedIn) {
    return <Navigate to='/' />;
  }

  const sideMenuLinks = [
    <NavLink
      to={'/profile/settings/personal'}
      children={t('links.user_profile.personal')}
    />,
    <NavLink
      to={'/profile/settings/subscriptions'}
      children={t('links.user_profile.subscriptions')}
    />,
    <NavLink
      to={'/profile/settings/purchase-history'}
      children={t('links.user_profile.purchase_history')}
    />,
    <NavLink
      to={'/profile/settings/payment-method'}
      children={t('links.user_profile.payment_method')}
    />,
    <NavLink
      to={'/?login=true'}
      onClick={logout}
      children={t('links.logout')}
    />,
  ];

  return (
    <div className='layout account'>
      <Navbar dark hideAccount sideMenuLinks={sideMenuLinks} />

      <Styled.Settings>
        <p className='pageTitle'>{t('profile.title')}</p>

        <div className='container flex'>
          <div className='sidebar'>
            <ul>
              <li>
                <NavLink
                  to={'/profile/settings/personal'}
                  children={t('links.user_profile.personal')}
                />
              </li>
              <li>
                <NavLink
                  to={'/profile/settings/subscriptions'}
                  children={t('links.user_profile.subscriptions')}
                />
              </li>
              <li>
                <NavLink
                  to={'/profile/settings/purchase-history'}
                  children={t('links.user_profile.purchase_history')}
                />
              </li>
              <li>
                <NavLink
                  to={'/profile/settings/payment-method'}
                  children={t('links.user_profile.payment_method')}
                />
              </li>
            </ul>
          </div>

          <div className='main'>
            <Outlet />
          </div>
        </div>
      </Styled.Settings>
    </div>
  );
};
