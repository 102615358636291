import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './CompetitionSection.style';

import Image1 from '../../../assets/images/CompetitionImage1.png';
import Image2 from '../../../assets/images/CompetitionImage2.png';

export const CompetitionSection = () => {
  const { t } = useTranslation();

  return (
    <Styled.CompetitionSection>
      <div className='container'>
        <div className='top_of_section'>
          <div className='image1'>
            <img
              src={Image1}
              alt='Competition Section 1'
              data-aos='zoom-in-right'
              data-aos-delay='100'
            />
            <p
              data-aos='zoom-in-right'
              data-aos-delay='200'
              style={{
                fontFamily: 'Saol Display',
                fontStyle: 'italic',
                fontWeight: 300,
              }}
            >
              {t('home.competition.sub-title')}
            </p>
          </div>
          <div className='center'>
            {/* <p className='subtitle' data-aos='zoom-in-up'>
            phiawards
          </p> */}
            <h1
              className='title'
              data-aos='zoom-in-up'
              data-aos-delay='100'
              dangerouslySetInnerHTML={{
                __html: t('home.competition.title', { escapeValue: false }),
              }}
            />
          </div>
          <div className='image2' data-aos='zoom-in-left'>
            <img src={Image2} alt='Competition Section 2' />
          </div>
        </div>

        <div className='bottom_of_section'>
          <p
            data-aos='zoom-in-up'
            dangerouslySetInnerHTML={{
              __html: t('home.competition.text.0', { escapeValue: false }),
            }}
          />
          <p
            data-aos='zoom-in-up'
            dangerouslySetInnerHTML={{
              __html: t('home.competition.text.1', { escapeValue: false }),
            }}
          />
        </div>
      </div>
    </Styled.CompetitionSection>
  );
};
