import styled from 'styled-components';

export const RankListWrapper = styled.div`
  h1 {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
    text-align: center;
    color: #ffffff;
    margin: 56px auto 48px;
  }

  .paginate {
    width: fit-content;
    display: flex;
    gap: 40px;
    flex-wrap: nowrap;
    margin: 0 auto;

    span {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #fafafa;
      opacity: 0.24;

      cursor: pointer;

      &.dots {
        cursor: default;
      }

      &.active {
        background: #a37c51;
        color: #111111;
        padding: 3px 11px;
        border-radius: 100%;
        opacity: 1;
      }
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    color: #fff;
    opacity: 0.5;

    .tag {
      background-color: rgb(163, 124, 81);
      border-radius: 20px;
      padding: 10px 20px;

      display: flex;
      align-items: center;

      cursor: pointer;

      &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 5.25L5.25 18.75' stroke='white' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M18.75 18.75L5.25 5.25' stroke='white' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        margin-left: 10px;

        display: inline-block;
        width: 18px;
        height: 18px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .container-half.mobile {
      width: 100%;
    }
  }
`;
