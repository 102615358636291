import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  z-index: 99999999999;

  width: 100%;
  height: 0;

  bottom: 0;
  left: 0;

  background-color: #f5f5f5;

  transition: all 0.5s ease-in-out;

  display: flex;
  flex-direction: column;

  &.show {
    height: 100%;
  }

  svg.icon-clickable {
    cursor: pointer;

    circle {
      stroke: #dfdfdf;
    }
    path {
      stroke: #111111;
    }

    &[disabled] {
      opacity: 0;
      visibility: hidden;
      cursor: not-allowed;

      path {
        stroke: #dfdfdf;
      }
    }
  }

  .header {
    width: 100%;
    height: 98px;
    padding: 27px 24px 23px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: #111111;
      text-align: center;
      font-family: Arimo;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      margin: 0;
      height: fit-content;
    }

    order: 1;
  }

  .body {
    height: calc(100vh - 98px);
    overflow: auto;

    padding-bottom: 19px;

    img.featured {
      display: block;

      width: 100%;
      max-height: 374px;

      object-fit: contain;
      background-color: rgb(17, 17, 17);
    }

    p.desc {
      margin: 34px 0;
    }

    order: 2;
  }

  .footer {
    border-top: 1px solid #dfdfdf;
    background-color: #fff;

    width: 100%;
    height: 96px;
    padding: 16px 0;
    z-index: 1;

    .container-half {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }

    .next-index {
      display: flex;
      gap: 17px;
      align-items: center;

      p {
        margin: 0;
      }
      .next {
        color: #111;
        font-family: Arimo;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.7px;
        text-transform: uppercase;

        margin-bottom: 8px;
      }
      .headline {
        color: #111;
        font-family: Arimo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      img {
        border-radius: 8px;
        width: 64px;
        height: 64px;
      }
    }

    order: 3;
  }

  .footer + .body {
    height: calc(100vh - 96px - 98px);
  }

  @media only screen and (max-width: 768px) {
    .container-half {
      width: calc(100% - (2 * 32px)) !important;
    }

    .body {
      img.featured {
        object-fit: cover;
      }
    }
  }
`;
