import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AccountInfo } from './AccountInfo';

import { ReactComponent as Next } from '../../assets/images/Next.svg';

const StyledList = styled.ul`
  padding: 0;

  li {
    display: flex;
    align-items: center;
    gap: 23px;

    color: #fff;
    padding: 24px 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #2f2f2f;

    cursor: pointer;

    &.current {
      background-color: #2f2f2f;
      cursor: unset;
    }

    &:not(:first-child) {
      padding-top: 16px;
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      object-fit: cover;
    }

    .number {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #a37c51;
    }

    .enter {
      margin-left: auto;
    }
  }
`;

export const RankList = ({ items, onClick, current }) => {
  const { t } = useTranslation();

  const handleClick = (e, item) => {
    e.preventDefault();
    onClick && onClick(item);
  };

  const currentUserIsOnPage =
    current && items.findIndex((item) => item.id === current?.id) > -1;

  let iItem = 0;
  return (
    <StyledList>
      {items.length > 0 ? (
        <>
          {current && current?.rank > 0 && !currentUserIsOnPage && (
            <li
              className='current'
              key={current?.rank}
            >
              <span className='number'>
                {String(current?.rank).padStart(2, '0')}
              </span>

              <AccountInfo
                name={current.name}
                title={`${current.points || 0} PT`}
                avatar={current.avatar}
                group={current.title}
              />
            </li>
          )}

          {items.map((item, index) => {
            iItem++;

            let isCurrent = false;
            if (item?.id === current?.id) {
              isCurrent = true;
            }

            return (
              <li
                key={index}
                className={isCurrent && 'current'}
                onClick={(e) => !isCurrent && handleClick(e, item)}
              >
                <span className='number'>
                  {String(item.index || iItem).padStart(2, '0')}
                </span>

                <AccountInfo
                  name={item.name}
                  title={`${item.points || 0} PT`}
                  avatar={item.avatar}
                  group={item.title}
                />

                {!isCurrent && (
                  <Next
                    className='enter'
                    onClick={(e) => handleClick(e, item)}
                  />
                )}
              </li>
            );
          })}
        </>
      ) : (
        <li>
          <span
            dangerouslySetInnerHTML={{ __html: t('rank-list.no-result') }}
          />
        </li>
      )}
    </StyledList>
  );
};
