import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

export const locales = ['EN', 'SR', 'IT', 'DE', 'ES'];

i18n
  .use(
    resourcesToBackend((language) => import(`./translations/${language}.json`))
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (language) => {
  console.log(`i18n language changed to ${language}`);

  moment.locale(language);
});

export default i18n;
