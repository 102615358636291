import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 9999999;

  transition: all 0.5s ease-in-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;

  min-width: 25%;
  width: fit-content;
  height: fit-content;

  background-color: #fff;

  z-index: 99999999;

  visibility: hidden;
  top: 100%;
  transition: all 0.5s ease-in-out;

  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius};

  .logo {
    margin: 0 !important;
  }

  &.show {
    visibility: visible;
    top: 50%;
    transform: translateY(-50%);
  }

  .closeIcon {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  .header {
    padding: 16px 16px 16px 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    width: 100%;
    z-index: 99;
  }

  .body {
    width: 100%;
    height: 100%;
    /* height: fit-content; */

    .flex {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    .wrapper {
      /* width: fit-content; */
      width: 100%;
      padding: 36px 48px 36px 24px;
    }
    * + .wrapper {
      padding: 86px 105px;
    }

    .title {
      font-family: 'Saol Display';
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #111111;
      /* max-width: 330px; */
    }
    h2 {
      font-family: 'Saol Display';
      font-style: normal;
      font-weight: 300;
      font-size: 56px;
      line-height: 56px;
      color: #111111 !important;
    }
    p.desc {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #111111 !important;
      opacity: 0.84;
      /* max-width: 330px; */

      & span,
      & p {
        font-size: unset !important;
        letter-spacing: unset !important;
        font-family: unset !important;
      }
    }
  }
  .send-again {
    small {
      color: #8f8f8f;
    }
    a {
      display: block;
      text-decoration: none;
      font-size: 24px;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  & .swiper-container:not(.root) {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 406px;
    width: 406px;
    flex-shrink: 0;
    user-select: none;

    img {
      object-fit: unset;
    }

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 100% !important;
      height: auto;
      user-select: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      bottom: 50%;
      transform: translateY(-50%);
      z-index: 99;
      user-select: none;
    }
    .swiper-button-prev {
      left: 24px;
    }
    .swiper-button-next {
      right: 24px;
    }
  }

  & .improve-modal {
    width: 996px !important;
    max-width: 100%;

    .wrapper {
      .swiper-slide {
        transition: all 0.3s;

        &.swiper-slide-next,
        &.swiper-slide-prev {
          opacity: 0;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
  }

  .w-50 {
    width: 50% !important;
  }

  @media screen and (max-width: 768px) {
    z-index: 99999999999;
    width: 100% !important;
    max-height: 70%;
    height: auto;

    padding-bottom: 30px;
    max-width: 100% !important;
    max-height: 100vh !important;
    /* min-height: 50vh; */
    overflow: auto;

    & .swiper-container:not(.root) {
      max-width: 100%;
      width: 100%;
      height: 362px;
      flex-shrink: 0;
    }

    &.rounded-mobile {
      height: 100% !important;
    }

    &.rounded {
      background: transparent;
      padding-bottom: 0;
      overflow: unset !important;

      .wrapper {
        margin-top: -14px;
        background: #fff;
        border-radius: 16px 16px 0px 0px;
        z-index: 9;
      }

      .flex > img {
        border-radius: 16px 16px 0px 0px;
      }

      .desc,
      .title {
        max-width: unset !important;
      }
    }

    .w-50 {
      width: 100% !important;
    }

    .flex {
      flex-direction: column;

      & > img {
        width: 100% !important;
        max-height: 40vh;
      }

      & > img + .wrapper,
      & > .swiper + .wrapper {
        height: 100%;
      }

      .wrapper {
        overflow: auto;
        padding: 24px !important;
        height: 100%;
      }
    }

    top: unset;
    bottom: 0;

    transform: translateY(100%);
    &.show {
      top: unset;
      bottom: 0;
      /* transform: unset; */
      transform: translateY(0);
    }
  }

  .swiper-button-next {
    transform: translateY(-50%) rotate(180deg) !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    content: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.2' cx='24' cy='24' r='24' fill='%23FAFAFA'/%3E%3Cg clipPath='url(%23clip0_1116_15403)'%3E%3Cpath d='M29 24H19' stroke='%23FAFAFA'/%3E%3Cpath d='M24 19L19 24L24 29' stroke='%23FAFAFA'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1116_15403'%3E%3Crect width='12' height='12' fill='white' transform='matrix(-1 0 0 1 30 18)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
`;
