import Api from './Api';

export const validateByEmail = async (formData) =>
  await Api.http({
    method: 'get',
    url: `/users/validate`,
    query: formData,
  });

export const getPhiCertUser = async (formData) =>
  await Api.http({
    method: 'get',
    url: `/users/phi-cert`,
    query: formData,
  });

export const getUserInfo = async (userId) =>
  await Api.http({
    method: 'get',
    url: userId ? `/users/${userId}` : `/users/profile`,
  });

export const getUserTransactions = async () =>
  await Api.http({
    method: 'get',
    url: '/users/profile/transactions',
  });

export const updateUserData = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/users/profile`,
    body: formData,
  });

export const getRankList = async (query) =>
  await Api.http({
    method: 'get',
    url: `/users/rank-list`,
    query,
  });

export const getAvailableLocations = async () =>
  await Api.http({
    method: 'get',
    url: `/users/available-locations`,
  });

export const uploadUserAvatar = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/users/upload`,
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8;',
    },
    body: formData,
  });

export const getUserScoresByCategory = async (userId, category, group) =>
  await Api.http({
    method: 'get',
    url: `/users/scores/${userId}/${group}/${category}`,
  });

export const getImproveByCategory = async (category) => {
  const query = new URLSearchParams();
  category.forEach((cat) => {
    query.append('category', cat);
  });

  return await Api.http({
    method: 'get',
    url: `/users/improve-score?${query.toString()}`,
  });
};
