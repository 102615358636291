import styled from 'styled-components';
import { Modal } from '../../../components';

export const Wrapper = styled.div`
  h1 {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 104px;
    text-align: center;
    color: #111111;

    margin: 32px 0 24px;
  }

  p.subTitle {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    margin: 16px auto 0;
  }
  p.description {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #111111;
    /* opacity: 0.4; */

    margin: 16px auto 32px;

    max-width: 588px;
  }

  ul.additional-info {
    width: 70%;
    margin: 40px auto;
    text-align: left;
  }

  .buyNow,
  .enterVoucher {
    /* display: block;
    width: fit-content; */
    margin: 32px auto 20px;
  }

  @media only screen and (max-width: 768px) {
    .enterVoucher {
      margin-top: 0;
    }
  }
`;

export const StyledModal = styled(Modal)`
  overflow: unset;

  background: #ffffff !important;
  border-radius: 16px;

  padding: 46px 26px !important;

  @media only screen and (max-width: 768px) {
    border-radius: 16px 16px 0px 0px;
    padding: 46px 16px 80px !important;
  }

  .header-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 44px;
  }

  h3 {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }

  .error {
    margin-top: 10px;
    text-align: left;
  }
`;
