import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './ScoreSlider.style';
import { ProgressRange } from '../ProgressRange/ProgressRange';
import { getScoreRange, getScoreRangeByLength } from '../../utils/functions';

export const ScoreSlider = ({ score }) => {
  const { i18n } = useTranslation();
  const scrollable = React.useRef(null);

  React.useEffect(() => {
    if (scrollable.current) {
      const active = scrollable.current
        ?.querySelector('.badge.active')
        ?.getBoundingClientRect();

      if (active) {
        scrollable.current.scrollLeft = active.left - 100;
      }
    }
  }, [scrollable]);

  return (
    <Styled.Wrapper>
      <div
        className='scrollable'
        ref={scrollable}
      >
        <div className='wrapper'>
          <div className='flex badges'>
            <div className='badge'>n/a</div>
            {(
              score?.score?.translations?.[i18n.language]?.titles ||
              score?.score?.titles
            )
              .sort((a, b) => a?.value - b?.value)
              .map(({ name, value }, index) => (
                <div
                  className={`badge ${value === score?.value ? 'active' : ''}`}
                  key={`n${index}`}
                >
                  {name}
                </div>
              ))}
          </div>

          <ProgressRange
            value={score?.value}
            hideRange
            range={
              score?.score?.type === 'calculate'
                ? getScoreRange(score?.score?.titles)
                : getScoreRangeByLength(score?.score?.titles)
            }
            steps={
              score?.score?.type === 'calculate'
                ? null
                : Array.from(score?.score?.titles?.keys()) || []
            }
            activeStep={
              score?.score?.type === 'calculate'
                ? null
                : score?.score?.titles.findIndex(
                    (item) => item.value === score?.value
                  )
            }
          />

          <div className='flex badges values'>
            <div className='badge'>n/a</div>
            {(
              score?.score?.translations?.[i18n.language]?.titles ||
              score?.score?.titles
            ).map(({ value }, index) => (
              <div
                className='badge'
                key={`v${index}`}
              >
                {value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Styled.Wrapper>
  );
};
