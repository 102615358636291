import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { updateUserData, uploadUserAvatar } from '../../../../api';
import { Button, FileUpload } from '../../../../components';
import { GlobalContext, useAuth } from '../../../../hooks';
import { getUploads } from '../../../../utils/functions';

export const Personal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setUser } = useAuth();

  const formRef = React.createRef();
  const [formData, setFormData] = React.useState({});
  const [avatarUploaded, setAvatarUploaded] = React.useState(false);
  const { setState: setGlobalState } = React.useContext(GlobalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setGlobalState({ loading: true });

    const values = {
      email: formData?.email,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      password: formData?.password,
      address: {
        city: formData?.city || user?.address?.city,
        country: formData?.country || user?.address?.country,
      },
      social: {
        instagram: formData?.instagram || user?.social?.instagram,
        youtube: formData?.youtube || user?.social?.youtube,
        website: formData?.website || user?.social?.website,
      },
    };

    updateUserData(values)
      .then((res) => {
        setUser({
          ...user,
          email: res.data?.email,
          photo: res.data?.photo,
          first_name: res.data?.first_name,
          last_name: res.data?.last_name,
          social: res.data?.social,
          address: res.data?.address,
        });

        setGlobalState({ loading: false });

        navigate('/account/profile');
      })
      .catch((err) => {
        setGlobalState({ loading: false });

        console.error(err);
      });
  };

  const handleChange = (e, key) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleFileChange = (event) => {
    const form_data = new FormData();
    form_data.append('avatar', event.target.files[0]);

    uploadUserAvatar(form_data)
      .then((res) => {
        setAvatarUploaded(true);
        setUser({ ...user, photo: res });
      })
      .catch(console.log);
  };

  return (
    <form ref={formRef} className='form-wrapper' onSubmit={handleSubmit}>
      <div className='input_group'>
        <label htmlFor='name'>
          {t('fields.professional_photo')} <span className='required'>*</span>
        </label>
        <FileUpload
          id='photoUpload'
          onChange={handleFileChange}
          image={getUploads(user?.photo)}
          allowed={t('fields.photo_allowed')}
          recommendation={t('fields.photo_recommendation')}
          type={1}
          accept='image/*'
          maxFileSize={2}
        />
        {avatarUploaded && (
          <p>
            <small style={{ color: '#007000' }}>
              {t('fields.photo_updated')}
            </small>
          </p>
        )}
      </div>

      <div className='input_group'>
        <label htmlFor='name'>
          {t('fields.first_name')} <span className='required'>*</span>
        </label>
        <input
          id='name'
          type='text'
          defaultValue={user.first_name}
          onChange={(e) => handleChange(e, 'first_name')}
        />
      </div>
      <div className='input_group'>
        <label htmlFor='Surname'>
          {t('fields.last_name')} <span className='required'>*</span>
        </label>
        <input
          id='Surname'
          type='text'
          defaultValue={user.last_name}
          onChange={(e) => handleChange(e, 'last_name')}
        />
      </div>
      <div className='input_group'>
        <label htmlFor='email'>
          {t('fields.email')} <span className='required'>*</span>
        </label>
        <input
          id='email'
          type='text'
          defaultValue={user.email}
          onChange={(e) => handleChange(e, 'email')}
        />
      </div>
      <div className='input_group'>
        <label htmlFor='password'>
          {t('fields.password')} <span className='required'>*</span>
        </label>
        <input
          id='password'
          type='password'
          onChange={(e) => handleChange(e, 'password')}
        />
      </div>
      <div className='input_group'>
        <label htmlFor='country'>
          {t('fields.country')} <span className='required'>*</span>
        </label>
        <input
          id='country'
          type='text'
          defaultValue={user?.address?.country}
          onChange={(e) => handleChange(e, 'country')}
        />
      </div>
      <div className='input_group'>
        <label htmlFor='city'>
          {t('fields.city')} <span className='required'>*</span>
        </label>
        <input
          id='city'
          type='text'
          defaultValue={user?.address?.city}
          onChange={(e) => handleChange(e, 'city')}
        />
      </div>

      <Button
        variant='primary'
        type='submit'
        styles={{ 'border-radius': 0, width: '100%' }}
        disabled={Object.values(formData).length <= 0}
      >
        {t('fields.submit')}
      </Button>
    </form>
  );
};
