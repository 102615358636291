import styled from 'styled-components';

export const Wrapper = styled.div`
  .breadcrumb {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #111111;

    padding: 24px 0;
    border-bottom: 1px solid #dfdfdf;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .current {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #111111;
      text-transform: capitalize;
    }

    p {
      margin: 0;

      &.next {
        cursor: pointer;

        span {
          opacity: 0.24;
        }

        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .modal {
    max-height: 376px;
    height: 100%;

    .wrapper {
      overflow: auto;
    }
  }
`;
