import React from 'react';
import { useTranslation } from 'react-i18next';

import { getUploads } from '../../utils/functions';
import * as Styled from './ImproveScoreModal.style';

import { ReactComponent as Back } from '../../assets/images/Back.svg';
import { ReactComponent as Next } from '../../assets/images/Next.svg';

const ImprovementContentGenerator = ({ improveScore, index }) => {
  const { i18n } = useTranslation();

  const images = [...improveScore?.images],
    title =
      i18n.language === 'en'
        ? improveScore?.title
        : improveScore?.translations?.[i18n.language]?.title ||
          improveScore?.title,
    content = [
      ...((i18n.language === 'en'
        ? improveScore?.text
        : improveScore?.translations?.[i18n.language]?.text ||
          improveScore?.text) || []),
    ];

  return (
    <>
      <img
        src={getUploads(images?.[index] || images?.[0])}
        alt={`${title} img`}
        className='featured'
      />

      <div className='container-half'>
        <p
          className='desc'
          style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: content?.[index] || '' }}
        />
      </div>
    </>
  );
};

export const ImproveScoreModal = ({
  className,
  showModal,
  closeModal,
  improveScore,
}) => {
  const { i18n } = useTranslation();

  const modalRef = React.createRef();
  const [activeIndex, setActiveIndex] = React.useState(0);

  if (showModal) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }

  React.useEffect(() => {
    const modalWrapper = modalRef?.current?.querySelector('.body');

    if (showModal && modalWrapper) {
      modalWrapper.scroll(0, 0);
    }
  }, [showModal, modalRef]);

  const handleClose = () => {
    if (!closeModal) {
      return;
    }

    document.body.style.overflow = 'unset';

    closeModal();
    setActiveIndex(0);
  };

  const nextHeadline =
    activeIndex < improveScore?.text?.length - 1 &&
    improveScore?.text?.[activeIndex + 1]
      ?.replace(/<\/?[^>]+(>|$)/g, '')
      .substr(0, 20);

  return (
    <Styled.Modal
      className={`modal ${className} ${showModal ? 'show' : ''}`}
      ref={modalRef}
    >
      <div className='header'>
        <Back
          onClick={() =>
            activeIndex > 0 &&
            setActiveIndex(activeIndex - 1 >= 0 ? activeIndex - 1 : 0)
          }
          className='icon-clickable'
          disabled={activeIndex <= 0}
        />

        <h3>
          {i18n.language === 'en'
            ? improveScore?.title
            : improveScore?.translations?.[i18n.language]?.title ||
              improveScore?.title}
        </h3>

        <svg
          width='45'
          height='45'
          viewBox='0 0 45 45'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='icon-clickable'
          onClick={handleClose}
        >
          <path
            d='M29.75 16.25L16.25 29.75'
            stroke='black'
            strokeLinecap='square'
            strokeLinejoin='round'
          />
          <path
            d='M29.75 29.75L16.25 16.25'
            stroke='black'
            strokeLinecap='square'
            strokeLinejoin='round'
          />
          <circle cx='22.5' cy='22.5' r='22' stroke='#DFDFDF' />
        </svg>
      </div>

      {activeIndex < improveScore?.text?.length - 1 && (
        <div className='footer'>
          <div className='container-half'>
            <div className='next-index'>
              <>
                <img
                  src={getUploads(
                    improveScore?.images?.[activeIndex + 1] ||
                      improveScore?.images?.[0]
                  )}
                  alt={`${nextHeadline && `${nextHeadline}...`} img`}
                />

                <div>
                  <p className='next'>Next</p>
                  <p className='headline'>
                    {nextHeadline && `${nextHeadline}...`}
                  </p>
                </div>
              </>
            </div>

            <Next
              onClick={() =>
                setActiveIndex(
                  activeIndex + 1 <= improveScore?.text?.length
                    ? activeIndex + 1
                    : 0
                )
              }
              className='icon-clickable'
            />
          </div>
        </div>
      )}

      <div className='body'>
        {improveScore && (
          <ImprovementContentGenerator
            improveScore={improveScore}
            index={activeIndex}
          />
        )}
      </div>
    </Styled.Modal>
  );
};
