import styled from 'styled-components';

export const PhiSteps = styled.section`
  background-color: #000;
  overflow: hidden;

  .container.withPadding {
    padding: 45px 106px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(216, 216, 216, 0.2);
  }
  h2.title {
    font-family: 'Saol Display';
    font-style: italic;
    font-weight: 300;
    font-size: 64px;
    line-height: 64px;
    color: #fff;

    margin: 0;
  }

  .icons_group {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;

    .icon_group {
      text-align: center;
      color: #fff;
      width: 100%;

      display: flex;
      max-width: 20%;
      flex-direction: column;
      align-items: center;

      a {
        cursor: pointer;
        text-transform: lowercase;
      }

      .icon {
        width: 100px;
        height: 100px;
        background: #ffffff;
        border-radius: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: inline-block;

          font-family: 'Saol Display';
          font-style: normal;
          font-weight: 300;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          color: #000000;
        }
      }
      .title {
        font-family: 'Arimo';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #ffffff;
        margin-top: 24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .icons_group {
      flex-wrap: wrap;
      gap: 24px;

      .icon_group {
        max-width: calc(50% - 12px);

        & .icon {
          margin: 0 auto;
        }
      }
    }
  }
`;
