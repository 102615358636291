import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  max-width: 100%;
  /* height: fit-content; */
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#fff'};
  border-radius: ${({ borderRadius }) => borderRadius || ''};

  &.plan {
    width: fit-content;
    margin: 0 auto;
    overflow: hidden;

    h2 {
      font-family: 'Saol Display';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 32px;
      color: #111111;

      margin: 0 0 32px;
    }

    .price {
      margin: 32px 0 32px;
    }

    img {
      width: 205px;
      height: 324px;
    }

    .wrapper {
      padding: 32px;

      hr {
        width: 336px;
        border: 0.5px solid #dfdfdf;
      }
    }
  }

  & > .header {
    background-color: #fafafa;
    padding: 24px;
  }

  .header {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #111111;
  }

  .points {
    margin-left: 24px;

    sup,
    sub {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      vertical-align: baseline;
    }
  }

  .body {
    padding: 40px 24px 24px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer {
    padding: 24px;

    &.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      flex-direction: row;
    }

    a {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #111111;
      text-decoration: none;
    }
    .date {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: #111111;
      opacity: 0.24;
      margin: 0;
    }
  }

  @media screen and (max-width: 768px) {
    & > .flex {
      .wrapper {
        width: 100%;
      }
      flex-direction: column;

      img {
        width: 100%;
        height: 210px;
        object-fit: cover;
      }
    }

    &.card.plan {
      & + .buyNow {
        margin-top: -70px;
      }
      
      
      & + .buyNow,
      & + .buyNow + .enterVoucher {
        z-index: 999999;
        position: relative;

        width: 100%;
        border-radius: 0;

        padding: 30px 0;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    & > .flex {
      flex-direction: row;

      img {
        width: 205px;
      }
    }
  }
`;
