import styled from 'styled-components';

import ArrowNext from '../../../assets/images/ArrowNext.svg';

export const Settings = styled.div`
  .pageTitle {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #111111;
    /* opacity: 0.24; */
    margin: 32px auto;
  }

  .file-upload {
    p {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 18px !important;
    }
  }

  .container {
    &.flex {
      display: flex;
      gap: 24px;
    }

    .sidebar {
      display: none;
      max-width: 384px;
      width: 100%;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          border-bottom: 1px solid #dfdfdf;
          &:last-child {
            border-bottom: none;
          }

          a {
            position: relative;
            padding: 24px;
            width: 100%;
            display: inline-block;

            font-family: 'Arimo';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #111111;
            text-decoration: none;

            &.active {
              background-color: #fff;
              border-left: 4px solid #a37c51;
            }

            &::after {
              content: url(${ArrowNext});

              position: absolute;
              right: 24px;
            }
          }
        }
      }
    }

    .main {
      width: 100%;
      background-color: #fff;
      overflow-x: auto;

      padding: 65px 48px 87px;

      .form-wrapper {
        max-width: 384px;
        margin: 0 auto;
      }

      h3 {
        font-family: 'Arimo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #2f2f2f;
        margin: 0 0 13px;
      }
      & > h3 {
        margin-bottom: 40px;
      }

      p {
        font-family: 'Arimo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8f8f8f;
        margin: 0;
      }

      .group {
        margin-bottom: 40px;

        h3 {
          margin-bottom: 16px;
        }
        p.status {
          text-transform: capitalize;
        }
      }

      .card {
        .card_img {
        }
        .card_number {
          margin-right: 8px;

          span {
            color: #2f2f2f;
            &:not(:first-child) {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 100%;

      .main {
        padding: 40px 16px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .sidebar {
      display: block !important;
    }
    .main {
      width: 75%;

      .form-wrapper {
        min-width: 384px;
        width: fit-content;
      }
    }
  }
`;
