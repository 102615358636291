import React from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenu } from '../SideMenu/SideMenu';
import { AccountInfo } from '../Shared';
import { useAuth } from '../../hooks';
import { getUploads } from '../../utils/functions';

import * as Styled from './Navbar.style';
import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';
import { ReactComponent as BrandPhiLogo } from '../../assets/images/PhiLogo.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/Hamburger.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { locales } from '../../i18n/i18n';

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const selectedLocale = locales.find((locale) =>
    i18n.language.toLowerCase().includes(locale.toLowerCase())
  );

  return (
    <Styled.LanguageSelector>
      <span>{selectedLocale}</span>

      <ul>
        {locales
          .filter((lang) => lang.toLowerCase() !== i18n.language.toLowerCase())
          .map((lang) => (
            <li
              key={lang}
              onClick={() => i18n.changeLanguage(lang.toLowerCase())}
            >
              {lang}
            </li>
          ))}
      </ul>
    </Styled.LanguageSelector>
  );
};

export const Navbar = ({
  dark = false,
  navLinks,
  sideMenuLinks,
  hideAccount = false,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuState, setMenuState] = React.useState(false);

  const isLoggedIn = !hideAccount && user;
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const goBackButton = [
    '/profile/settings',
    '/account/profile/scores',
    '/account/user',
  ].some((route) => pathname.includes(route));

  return (
    <>
      <Styled.Navbar dark={dark}>
        <div className='container'>
          <div className='brand-wrapper'>
            <Link
              to='/'
              className={`brand-logo ${
                goBackButton || isLoggedIn ? 'go-back' : ''
              }`}
            >
              {goBackButton && isMobile ? (
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={() => navigate(-1)}
                >
                  <g clipPath='url(#clip0_1007_13819)'>
                    <path
                      d='M14.6668 8L1.3335 8'
                      stroke='#FAFAFA'
                    />
                    <path
                      d='M8.00016 1.33366L1.3335 8.00033L8.00016 14.667'
                      stroke='#FAFAFA'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1007_13819'>
                      <rect
                        width='16'
                        height='16'
                        fill='white'
                        transform='matrix(-1 0 0 1 16 0)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <>
                  <BrandLogo className='logo desktop' />
                  <BrandPhiLogo className='logo mobile' />
                </>
              )}
            </Link>
            {!isMobile && <LanguageSelector />}
          </div>

          <div className='nav-links'>{navLinks}</div>

          {(isLoggedIn || sideMenuLinks) && (
            <div className='right_column'>
              {isLoggedIn && (
                <AccountInfo
                  name={user?.name}
                  title={`${user?.scoresSum} pt`}
                  avatar={getUploads(user?.photo)}
                  group={user?.title}
                  verified={user?.verified}
                  rank={user?.rank}
                />
              )}

              {sideMenuLinks && (
                <MenuIcon
                  className='hamburger-menu'
                  onClick={() => setMenuState(true)}
                />
              )}
            </div>
          )}
        </div>
      </Styled.Navbar>

      {sideMenuLinks && (
        <SideMenu
          isOpen={menuState}
          closeMenu={() => setMenuState(false)}
          links={sideMenuLinks}
        />
      )}
    </>
  );
};
