import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import * as Styled from './Profile.style';
import {
  AccountSteps,
  Card,
  Header,
  Link,
  ProfileScore,
  ProgressRange,
  Section,
} from '../../../components';
import { useAuth } from '../../../hooks';
import { getUploads } from '../../../utils/functions';
import { MASTER_ROLES } from '../../../utils/constants';

import { ReactComponent as UserVerified } from '../../../assets/images/UserVerified.svg';
import { ReactComponent as BuyAReview } from '../../../assets/images/Icons/buyAReview.svg';
import AvatarBG from '../../../assets/images/defaultAvatar.png';

export const ProfilePage = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.style.backgroundColor = '#f5f5f5';
  }, []);

  const scoreGrouped = user?.scores?.data?.reduce((acc, current) => {
    const allScoresGrouped = user?.scores?.data.filter(
      (score) =>
        score.group === current?.group &&
        score?.score &&
        score.score?.category === current?.score?.category
    );

    if (
      MASTER_ROLES.includes(current?.group) &&
      !acc[current?.group]?.find(
        (item) => item.name === current?.score?.category
      ) &&
      current?.score
    ) {
      (acc[current?.group] = acc[current?.group] || []).push({
        name: current?.score?.category,
        range: [
          allScoresGrouped.reduce(
            (partialSum, a) =>
              partialSum +
              a?.score?.titles?.sort((a2, b2) => a2.value - b2.value)[0]?.value,
            0
          ),
          allScoresGrouped.reduce(
            (partialSum, a) =>
              partialSum +
              a?.score?.titles?.sort((a2, b2) => b2.value - a2.value)[0]?.value,
            0
          ),
        ],
        value: allScoresGrouped.reduce(
          (partialSum, a) => partialSum + a.scoreSum,
          0
        ),
        date: current?.createdAt,
      });
    }

    return acc;
  }, {});

  const isCompleted = () => {
    const currentIndex = MASTER_ROLES.findIndex((role) => role === user?.title);

    const completedTitles = [];
    MASTER_ROLES.forEach((role, index) => {
      if (index === currentIndex || index < currentIndex) {
        completedTitles.push(true);
      } else {
        completedTitles.push(false);
      }
    });

    return completedTitles;
  };

  console.log(scoreGrouped);
  return (
    <>
      <Header
        dark
        image={
          user?.photo !== 'defaultAvatar.svg'
            ? getUploads(user?.photo)
            : AvatarBG
        }
      >
        <Styled.AccountHeaderElements>
          <div className='user_photo'>
            <img
              src={getUploads(user?.photo)}
              alt={`${user.name} Avatar`}
              className='user_avatar'
            />
            {/* {UserGroupIcon && <UserGroupIcon className='user_group' />} */}
          </div>
          <p className='user_name'>
            {user.name} {user.verified && <UserVerified />}
          </p>
          <p className='user_group'>{user.title}</p>
        </Styled.AccountHeaderElements>
      </Header>

      <div className='container-half'>
        <ProfileScore
          user={user}
          showBuyButton
        />

        <AccountSteps checked={isCompleted()} />

        {/* Object.keys(scoreGrouped[group]).map((group2) => ( */}
        {scoreGrouped &&
          Object.keys(scoreGrouped).map((group, iGroup) => {
            const checkUserTitle =
              user?.title?.toLowerCase() === group?.toLowerCase();

            let title = t('user_profile.completed');
            if (checkUserTitle) {
              title = t('user_profile.your_level');
            }

            return (
              <Section
                key={iGroup}
                title={
                  <>
                    <span className='group'>{title}: </span>
                    <span className='group'>{group}</span>
                  </>
                }
              >
                <div className='flex-wrapper'>
                  {scoreGrouped[group].map((score, iScore) => (
                    <Card key={iScore}>
                      <div className='header'>{score?.name}</div>
                      <div className='body'>
                        <ProgressRange
                          title={t('user_profile.points')}
                          range={score?.range}
                          value={score?.value}
                        />
                      </div>

                      <div className='footer flex'>
                        <p className='date'>
                          {moment(score?.date).format('MMMM D, YYYY')}
                        </p>

                        <Link
                          to={`/account/profile/scores/${group}/${score?.name}`}
                          variant='secondary2'
                          styles={{ padding: '8px 16px' }}
                        >
                          {t('user_profile.see_more')}
                        </Link>
                      </div>
                    </Card>
                  ))}
                </div>
              </Section>
            );
          })}
      </div>

      <div className='sticky-button'>
        <div className='container'>
          <Link
            to={'/account/buy-a-review'}
            variant='primary'
            className='button'
            styles={{
              padding: '16px 24px',
              display: 'flex',
              gap: '4px',
              textTransform: 'unset',
            }}
          >
            <BuyAReview
              width={16}
              height={16}
            />
            {t('buy_a_review.better_score')}
          </Link>
        </div>
      </div>
    </>
  );
};
