import React from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../../hooks';

import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;

  top: 0;
  left: 0;

  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  visibility: visible;
  
  z-index: 9999999999;
  
  &.hide {
    opacity: 0;
    visibility: hidden;
    
    transition: all 0.5s ease-in-out;
  }
`;

export const Loader = ({ loading }) => {
  const { state } = React.useContext(GlobalContext);

  return (
    <LoaderWrapper className={!state.loading && !loading ? 'hide' : ''}>
      <BrandLogo />
    </LoaderWrapper>
  );
};
