import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, NavLink, Outlet } from 'react-router-dom';

import { useAuth } from '../hooks';
import { BottomNavigation, Navbar } from '../components';

import { ReactComponent as RankList } from '../assets/images/Icons/rankList.svg';

export const AccountUnVerified = () => {
  const { isLoggedIn, user, logout } = useAuth();
  const { t } = useTranslation();

  if (!isLoggedIn) {
    return <Navigate to='/' />;
  } else if (user && user?.reviewed && user.verified && user.emailVerified) {
    return <Navigate to='/account' />;
  }

  const navLinks = (
    <>
      <ul>
        <li>
          <NavLink to={'/account/rank-list'}>{t('links.rank-list')}</NavLink>
        </li>
      </ul>
    </>
  );

  const navBottomLinks = (
    <>
      <ul>
        <li>
          <NavLink to={'/account/rank-list'}>
            <RankList />
            {t('links.rank-list')}
          </NavLink>
        </li>
      </ul>
    </>
  );

  const sideMenuLinks = [
    <NavLink
      to={'/profile/settings/personal'}
      children={t('links.user_profile.personal')}
    />,
    <NavLink
      to={'/profile/settings/subscriptions'}
      children={t('links.user_profile.subscriptions')}
    />,
    <NavLink
      to={'/profile/settings/purchase-history'}
      children={t('links.user_profile.purchase_history')}
    />,
    <NavLink
      to={'/profile/settings/payment-method'}
      children={t('links.user_profile.payment_method')}
    />,
    <NavLink
      to={'/?login=true'}
      onClick={logout}
      children={t('links.logout')}
    />,
  ];

  return (
    <div className='layout account'>
      <Navbar
        dark
        hideAccount
        navLinks={navLinks}
        sideMenuLinks={sideMenuLinks}
      />
      <Outlet />

      <BottomNavigation navLinks={navBottomLinks} />
    </div>
  );
};
