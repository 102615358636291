import styled from 'styled-components';

export const Wrapper = styled.div`
  .scrollable {
    width: 100%;
    overflow-x: auto;

    .wrapper {
      display: inline-block;
      white-space: nowrap;
      min-width: 100%;
    }

    .flex {
      flex-wrap: nowrap;
      width: 100%;
      justify-content: space-between;
    }

    .badges {
      margin: 32px 0 22px;

      &.values {
        margin: 16px 0 0;

        .badge {
          border: none !important;
        }
      }

      .badge {
        border: 1px solid #efefef;
        border-radius: 4px;
        /* width: 132px; */
        width: fit-content;
        padding: 0 10px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        flex-shrink: 0;

        margin: 0 10px;

        font-family: 'Arimo';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #cbcbcb;

        &.active {
          background-color: #eec141;
          color: #fafafa;

          position: relative;

          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent !important;
            border-right: 10px solid transparent !important;
            border-bottom: 0 solid transparent !important;
            border-top: 12px solid #eec141;
            border-radius: 1px;

            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
