import Api from './Api';

export const connectPayment = async () =>
  await Api.http({
    method: 'post',
    url: `/payment/connect`,
  });

export const checkoutSession = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/payment/stripe/checkout`,
    body: formData,
  });

export const getUserSubscriptions = async () =>
  await Api.http({
    method: 'get',
    url: `/payment/stripe/subscriptions`,
  });

export const cancelUserSubscriptions = async () =>
  await Api.http({
    method: 'get',
    url: `/payment/stripe/subscriptions/cancel`,
  });

export const getUserStripePaymentMethods = async () =>
  await Api.http({
    method: 'get',
    url: `/payment/stripe/customer/payment-methods`,
  });

export const validateCouponCode = async (code) =>
  await Api.http({
    method: 'post',
    url: `/payment/coupons/validate`,
    body: { code },
  });

export const couponExists = async (email) =>
  await Api.http({
    method: 'get',
    url: `/payment/coupons/${email}`,
  });
