import { useTranslation } from 'react-i18next';

import * as Styled from './ScoreCard.style';
import { Card } from '../Card/Card';
import { ScoreSlider } from '../ScoreSlider/ScoreSlider';
import { Button } from '../Shared';

import * as progressIcons from '../../assets/images/scoreProgress';

export const ScoreCard = ({ score, showHowToImprove, hideImproveButton }) => {
  const { t, i18n } = useTranslation();

  if (!score || !score.score) {
    return null;
  }

  const getProgressColor = () => {
    const titles = [...score?.score?.titles].sort((a, b) => b.value - a.value);

    if (titles[1]?.value < score?.value) {
      return '#94B909';
    } else if (score?.value < 5) {
      return '#BF3532';
    }

    return '#eec141';
  };

  const progressColor = getProgressColor();

  const getProgressButton = () => {
    if (progressColor === '#94B909') {
      return {
        message: t('profile.scores.progress.titles.0'),
        icon: () => <progressIcons.GoodJob />,
      };
    } else if (progressColor === '#BF3532') {
      return {
        message: t('profile.scores.progress.titles.1'),
        icon: () => <progressIcons.NeedWork />,
      };
    }

    return {
      message: t('profile.scores.progress.titles.2'),
      icon: () => <progressIcons.AlmostThere />,
    };
  };

  const progressButton = getProgressButton();

  return (
    <Styled.Wrapper color={progressColor}>
      <Card>
        <div className='body'>
          <div className='header'>
            {score?.score?.translations?.[i18n.language]?.name ||
              score?.score?.name}
            <span className='points'>
              {score?.value} <sub>{t('user_profile.points')}</sub>
            </span>
          </div>

          <ScoreSlider score={score} />
        </div>

        <div className='footer flex'>
          {progressButton && (
            <div className='progress-button'>
              <progressButton.icon />
              {progressButton.message}
            </div>
          )}
          {!hideImproveButton && (
            <Button
              variant='secondary2'
              styles={{ width: 'fit-content' }}
              onClick={showHowToImprove}
            >
              {t('profile.scores.how_to_improve')}
            </Button>
          )}
        </div>
      </Card>
    </Styled.Wrapper>
  );
};
