import React from 'react';

import * as Styled from './BottomNavigation.style';

export const BottomNavigation = ({
  navLinks,
}) => {
  return (
    <>
      <Styled.Wrapper>
        <div className='nav-links'>{navLinks}</div>
      </Styled.Wrapper>
    </>
  );
};
