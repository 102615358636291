import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 32px;

  button {
    color: #111111;
  }

  .header {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #111111;

    text-transform: capitalize;
  }

  .progress-wrapper {
    width: 100%;
    margin: 0;
    overflow: hidden;

    .progress {
      width: 100%;
      height: 5px;

      .line {
        background-color: ${({ color }) => color || '#eec141'};

        &::after {
          background-color: ${({ color }) => color || '#eec141'};
        }
      }
    }
  }

  .badge.active {
    background-color: ${({ color }) => color || '#eec141'} !important;

    &::after {
      border-color: ${({ color }) => color || '#eec141'} !important;
    }
  }

  .progress-button {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    align-items: center;
    gap: 8px;

    color: ${({ color }) => color || '#eec141'};

    svg {
      width: 32px;
      height: 32px;
      padding: 8px;
      background-color: ${({ color }) => color || '#eec141'}33;
      border-radius: 100%;

      &,
      path {
        fill: ${({ color }) => color || '#eec141'};
      }
    }
  }
`;
