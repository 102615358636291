import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, NavLink, Outlet } from 'react-router-dom';

import { BottomNavigation, Navbar } from '../components';
import { useAuth } from '../hooks';

import { ReactComponent as User } from '../assets/images/Icons/user.svg';
import { ReactComponent as RankList } from '../assets/images/Icons/rankList.svg';
import { ReactComponent as BuyAReview } from '../assets/images/Icons/buyAReview.svg';

export const Account = ({ onlyVerified = true }) => {
  const { isLoggedIn, user, logout } = useAuth();
  const { t } = useTranslation();

  if (!isLoggedIn) {
    return <Navigate to='/' />;
  } else if (
    onlyVerified &&
    (!user?.reviewed || !user.verified || !user.emailVerified)
  ) {
    return <Navigate to='/under/review' />;
  }

  const showToVerifiedUser =
    user?.reviewed && user.verified && user.emailVerified;
  const navLinks = (
    <>
      <ul>
        {showToVerifiedUser && (
          <li>
            <NavLink to={'/account/profile'}>{t('links.my-profile')}</NavLink>
          </li>
        )}
        <li>
          <NavLink to={'/account/rank-list'}>{t('links.rank-list')}</NavLink>
        </li>
        {showToVerifiedUser && (
          <li>
            <NavLink to={'/account/buy-a-review'}>
              {t('links.buy-a-review')}
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );

  const navBottomLinks = (
    <>
      <ul>
        {showToVerifiedUser && (
          <li>
            <NavLink to={'/account/profile'}>
              <User />
              {t('links.my-profile')}
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={'/account/rank-list'}>
            <RankList />
            {t('links.rank-list')}
          </NavLink>
        </li>
        {showToVerifiedUser && (
          <li>
            <NavLink to={'/account/buy-a-review'}>
              <BuyAReview />
              {t('links.buy-a-review')}
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );

  const sideMenuLinks = [
    <NavLink
      to={'/profile/settings/personal'}
      children={t('links.user_profile.personal')}
    />,
    <NavLink
      to={'/profile/settings/subscriptions'}
      children={t('links.user_profile.subscriptions')}
    />,
    <NavLink
      to={'/profile/settings/purchase-history'}
      children={t('links.user_profile.purchase_history')}
    />,
    <NavLink
      to={'/profile/settings/payment-method'}
      children={t('links.user_profile.payment_method')}
    />,
    <NavLink
      to={'/?login=true'}
      onClick={logout}
      children={t('links.logout')}
    />,
  ];

  return (
    <div className='layout account'>
      <Navbar dark navLinks={navLinks} sideMenuLinks={sideMenuLinks} />
      <Outlet />

      <BottomNavigation navLinks={navBottomLinks} />
    </div>
  );
};
