import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';

import {
  getPhiCertUser,
  registerUser,
  uploadUserAvatar,
  validateByEmail,
} from '../../api';
import { useAuth, useFreshchat } from '../../hooks';
import {
  Button,
  Card,
  FileUpload,
  Loader,
  Modal,
  PhiLine,
} from '../../components';
import * as Styled from './Register.style';

import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';
import { ReactComponent as InputEye } from '../../assets/images/Icons/input_eye.svg';
import CertificateImg from '../../assets/images/certificate.jpg';
import { SocialLinks } from '../../utils/constants';

const defaultValues = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  certificateNumber: '',
  title: '',
  technique: '',
  city: '',
  country: '',
  instagram: SocialLinks.instagram,
  youtube: SocialLinks.youtube,
  tiktok: SocialLinks.tiktok,
  facebook: SocialLinks.facebook,
  website: '',
  communication_channel: 'viber',

  agreeTerms: false,
  agreePhiAwardTerms: false,
};

export const RegisterPage = ({ show, toggleable, togglePopup }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoggedIn, setUser } = useAuth();
  const [step, setStep] = React.useState(0);
  const passwordFieldRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [showCertInfo, setShowCertInfo] = React.useState(false);
  const [showEmailHint, setShowEmailHint] = React.useState(false);
  const [formData, setFormData] = React.useState({ ...defaultValues });

  const WrapperRef = React.useRef(null);

  const showFreshChat = show || show === undefined;
  const { setShowFreshdesk, updateUser } = useFreshchat({
    show: showFreshChat,
  });

  React.useEffect(() => {
    setShowFreshdesk(showFreshChat);
  }, [showFreshChat, setShowFreshdesk]);

  React.useEffect(() => {
    if (
      step !== 1 &&
      loading === false &&
      formData.email &&
      formData.first_name &&
      formData.last_name &&
      !formErrors?.find((error) => error?.field === 'email')
    ) {
      setFormErrors([]);
      setStep(1);
    }
  }, [
    formData.email,
    formData.first_name,
    formData.last_name,
    step,
    formErrors,
    loading,
  ]);

  React.useEffect(() => {
    if (show && toggleable) {
      document.body.style.overflow = 'hidden';
    }
  }, [toggleable, show]);

  // if (isLoggedIn && !user.registered) {
  //   return <Navigate to='/account' />;
  // } else if (isLoggedIn && user.registered) {
  //   return <Navigate to='/register/buy-a-review' />;
  // }

  if (isLoggedIn) {
    window.dataLayer.push({
      event: 'gtm_profile_loaded',
    });

    return <Navigate to='/account' />;
  }

  const syncPhiUser = (key, value, oldData = {}) => {
    setLoading(true);

    const byEmail = key === 'email';
    return getPhiCertUser({ [key]: value, isAlreadyRegistered: true })
      .then((res) => {
        setFormErrors([]);
        setLoading(false);

        window.dataLayer.push({
          event: 'gtm_credentials_validated',
        });

        const userData = {
          ...formData,

          phiCertRef: byEmail ? res?.data?.id : res?.data?.member?.id,
          ...(!formData?.first_name && {
            first_name: res?.data?.firstname || res?.data?.member?.firstname,
          }),
          ...(!formData?.last_name && {
            last_name: res?.data?.lastname || res?.data?.member?.lastname,
          }),
          ...(!formData?.email && {
            email: res?.data?.member?.email || res?.data?.email,
          }),
          ...(res?.data?.address || {}),
          ...(res?.data?.title && {
            title: res?.data?.title,
          }),
          ...(res?.data?.technique && {
            technique: res?.data?.technique,
          }),
          ...(res?.data?.courses && { techniques: res?.data?.courses || [] }),
          ...(res?.data?.titles && { titles: res?.data?.titles || [] }),
        };

        setFormData({ ...oldData, ...userData });
        updateUser({
          email: userData?.email,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
        });

        return userData;
      })
      .catch((error) => {
        const errorExists = formErrors.some((formError) =>
          error?.errors.some((err) => err.field === formError.field)
        );

        setLoading(false);

        if (!errorExists && step === 0) {
          setFormErrors([
            ...(formErrors || []),
            ...(oldData?.errors || []),
            ...(error?.errors || []),
          ]);
        }
      });
  };

  const handleValidation = async (e) => {
    e.preventDefault();

    let emailIsValid = true;
    if (formData?.email) {
      await validateByEmail({ email: formData.email }).then((res) => {
        emailIsValid = !res?.isValid;

        if (!emailIsValid) {
          setFormErrors([
            ...formErrors,
            {
              field: 'email',
              messages: 'User with this email already exists!',
            },
          ]);
        } else {
          setFormErrors(formErrors.filter((error) => error.field !== 'email'));
        }
      });
    }

    if (emailIsValid) {
      if (formData?.certificateNumber) {
        syncPhiUser('certificateNumber', formData.certificateNumber).then(
          async (res) => {
            if (!res?.email && formData.email) {
              await syncPhiUser('email', formData?.email, res);
            }
          }
        );
      } else if (formData?.email) {
        syncPhiUser('email', formData.email).then(async (res) => {
          if (!res?.email && formData.certificateNumber) {
            await syncPhiUser(
              'certificateNumber',
              formData.certificateNumber,
              res
            );
          }
        });
      }
    }
  };

  const handleValueChange = (event, key) => {
    let { value } = event.target;

    if (['instagram', 'tiktok', 'youtube', 'facebook'].includes(key)) {
      value = SocialLinks[key] + value.substr(SocialLinks[key].length);
    }

    setFormData({ ...formData, [key]: value });
  };

  const handleFileChange = (event, key) => {
    setFormData({ ...formData, [key]: event.target.files[0] });
  };

  const handleSocialPaste = (event, key) => {
    event.preventDefault();

    const clipboardText = event.clipboardData.getData('text/plain');
    let newValue = clipboardText;

    if (!clipboardText.includes(SocialLinks[key])) {
      const urlRegex = new RegExp(
        '[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}/'
      );

      newValue = clipboardText.replace(urlRegex, '');
      newValue = SocialLinks[key] + newValue;
    }

    setFormData({ ...formData, [key]: newValue });
  };

  const validateSocialLinks = (event, key) => {
    const { value } = event.target;

    if (!['instagram', 'tiktok', 'youtube', 'facebook'].includes(key)) {
      return;
    }

    if (value === SocialLinks[key]) {
      setFormErrors([
        ...formErrors,
        {
          field: key,
          messages: `Invalid ${key} url`,
        },
      ]);
    } else {
      deleteError(key);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData?.instagram === SocialLinks.instagram) {
      validateSocialLinks({ target: formData.instagram }, 'instagram');
      return;
    }

    const values = {
      email: formData?.email,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      password: formData?.password,
      phone: formData?.phone,
      communication_channel: formData?.communication_channel,
      certificateNumber: formData?.certificate_number,
      title: formData?.title,
      titles: formData?.titles,
      technique: formData?.techniques[0],
      techniques: formData?.techniques,
      phiCertRef: formData?.phiCertRef,
      address: {
        city: formData?.city,
        country: formData?.country,
      },
      social: {
        facebook: formData?.facebook,
        instagram: formData?.instagram,
        tiktok: formData?.tiktok,
        youtube: formData?.youtube,
        website: formData?.website,
      },
    };

    registerUser(values)
      .then((res) => {
        if (formData.avatar) {
          const form_data = new FormData();
          form_data.append('avatar', formData.avatar);

          uploadUserAvatar(form_data)
            .then((img) =>
              setUser({ ...res?.data?.user, photo: img, registered: true })
            )
            .catch(console.error);
        }

        return res;
      })
      .then((res) => {
        window.dataLayer.push({
          event: 'gtm_profile_created',
        });

        setUser({ ...res?.data?.user, registered: true });
      })
      .catch((error) => {
        (toggleable ? WrapperRef?.current : window).scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        setFormErrors(error?.errors || error.message);
      });
  };

  const getErrors = (field) =>
    formErrors?.find((error) => error?.field === field)?.messages || null;

  const deleteError = (field) => {
    const index = formErrors?.findIndex((error) => error?.field === field);

    if (index > -1) {
      setFormErrors(formErrors.filter((error, i) => i !== index));
    }
  };

  document.body.style.backgroundColor = '#EAEAEA';
  return (
    <Styled.Wrapper
      className={`${toggleable && 'toggleable'} ${show && 'show'}`}
      ref={WrapperRef}
    >
      <Loader loading={loading} />

      <div className='container'>
        <h1>
          <BrandLogo className='logo' />
          {t('register.title')}
          <svg
            width='44'
            height='44'
            viewBox='0 0 44 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='goBack'
            onClick={() => (show ? togglePopup() : navigate('/'))}
          >
            <circle
              cx='22'
              cy='22'
              r='21.5'
              stroke='black'
              strokeOpacity='0.2'
            />
            <path
              d='M27.625 16.375L16.375 27.625'
              stroke='#111111'
              strokeLinecap='square'
              strokeLinejoin='round'
            />
            <path
              d='M27.625 27.625L16.375 16.375'
              stroke='#111111'
              strokeLinecap='square'
              strokeLinejoin='round'
            />
          </svg>
        </h1>
        <PhiLine />
        <p className='subTitle'>{t('register.required_info')}</p>

        {step === 0 && (
          <form onSubmit={handleValidation}>
            <Card>
              <div
                className='wrapper'
                style={{ maxWidth: '384px' }}
              >
                <div className='input_group'>
                  <label
                    htmlFor='email'
                    className='flex'
                    style={{ gap: '4px', justifyContent: 'baseline' }}
                  >
                    {t('fields.email')} <span className='required'>*</span>
                    <span
                      className='hint'
                      onClick={(e) => {
                        e.preventDefault();
                        setShowEmailHint(true);
                      }}
                    >
                      {t('register.hints.email')}
                    </span>
                  </label>
                  <input
                    id='email'
                    type='text'
                    value={formData.email}
                    onChange={(e) => handleValueChange(e, 'email')}
                    required={!formData.certificateNumber}
                  />

                  {(getErrors('email') || getErrors('cert_email')) && (
                    <>
                      {getErrors('email') && (
                        <p className='error'>
                          <small>{getErrors('email')}</small>
                        </p>
                      )}
                      {getErrors('cert_email') && (
                        <p className='error'>
                          <small>{getErrors('cert_email')}</small>
                        </p>
                      )}
                    </>
                  )}
                </div>

                <div className='input_group'>
                  <label
                    htmlFor='certificateNumber'
                    className='flex'
                    style={{ gap: '4px' }}
                  >
                    {t('fields.certificate_number')}{' '}
                    {!formData.email && !formData.phiCertRef && (
                      <span className='required'>*</span>
                    )}
                    <span
                      className='hint'
                      onClick={(e) => {
                        e.preventDefault();
                        setShowCertInfo(true);
                      }}
                    >
                      {t('register.hints.certificate_number')}
                    </span>
                  </label>
                  <input
                    id='certificateNumber'
                    type='text'
                    value={formData.certificateNumber}
                    onChange={(e) => handleValueChange(e, 'certificateNumber')}
                    required={!formData.email && !formData.phiCertRef}
                  />

                  {getErrors('certificateNumber') && (
                    <p className='error'>
                      <small>{getErrors('certificateNumber')}</small>
                    </p>
                  )}
                </div>

                <Button
                  variant='primary'
                  className='flex proceed validate'
                  type='submit'
                  styles={{
                    borderRadius: '60px',
                    width: '100%',
                    padding: '10px',
                    justifyContent: 'baseline',
                    gap: '0 !important',
                    color: 'white !important',
                    textTransform: 'uppercase',
                  }}
                >
                  <svg
                    width='40'
                    height='40'
                    viewBox='0 0 40 40'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <ellipse
                      cx='19.9394'
                      cy='20'
                      rx='19.2929'
                      ry='20'
                      fill='white'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M21.7141 21.7546C21.1912 21.7113 20.7 21.9084 20.2087 22.0543C20.082 22.0937 19.9552 22.1332 19.8284 22.1765L19.8403 20.8359C24.2495 20.8359 27.6565 18.2809 27.6565 13.8016C27.6565 10.939 25.8738 7.19715 22.1261 7.18926C19.8443 7.18532 18.4854 8.67577 18.2676 10.4186C18.0338 12.2639 18.0497 13.5138 18.1527 15.0634L18.4696 19.8265C15.7559 19.3139 14.3456 16.3449 14.3456 13.3324C14.3456 9.97695 16.0451 7.59933 17.9823 7C15.0468 7.29967 12.2222 10.4422 12.2222 13.3324C12.2222 16.9205 14.3614 20.2366 18.4696 20.7728L18.6558 22.5432C18.1646 22.697 17.6733 22.8508 17.1781 22.9809C16.1243 23.2372 15.0864 23.5684 14.0168 23.7498C13.8187 23.7971 13.5612 23.8286 13.5057 24.0613C13.3908 24.4516 13.4384 24.8696 13.478 25.2639C14.1396 25.0943 14.817 25.0076 15.4905 24.8735C16.6433 24.6882 17.7248 24.2702 18.7944 23.8207L19.3926 29.5223L19.7413 33L19.8205 23.387C20.2285 23.2175 20.6366 23.0519 21.0525 22.902C21.8171 22.5432 22.6808 22.4052 23.5167 22.5314C24.4397 22.6931 25.3588 22.8705 26.2383 23.1899C25.9966 22.9888 25.6955 22.8823 25.4143 22.7443C24.2535 22.1962 22.9937 21.869 21.7141 21.7507V21.7546ZM20.0503 12.8869C20.1057 10.9982 20.082 8.22232 22.0628 8.24598C23.9089 8.26964 25.5291 11.3373 25.5331 13.707C25.5331 17.5751 23.3067 19.9685 19.8403 19.9685L20.0542 12.8869H20.0503Z'
                      fill='#A37C51'
                    />
                  </svg>

                  <span style={{ margin: '0 auto' }}>
                    {t('register.validate')}
                  </span>
                </Button>
              </div>
            </Card>
          </form>
        )}

        {step === 1 && (
          <form
            onSubmit={handleSubmit}
            encType='multipart/form-data'
          >
            <div className='user_data'>
              <div>
                <label>{t('fields.name')}</label>
                {formData?.first_name + ' ' + formData?.last_name}
              </div>

              <div>
                <label>{t('fields.location')}</label>
                {formData?.country && formData?.city
                  ? formData?.country + ', ' + formData?.city
                  : t('register.not_specified')}
              </div>
            </div>

            {(getErrors('email') || getErrors('certificateNumber')) && (
              <Card className={'error-wrapper'}>
                <div className='wrapper flex'>
                  {getErrors('email') && (
                    <p className='error'>
                      <small>{getErrors('email')}</small>
                    </p>
                  )}
                  {getErrors('certificateNumber') && (
                    <p className='error'>
                      <small>{getErrors('certificateNumber')}</small>
                    </p>
                  )}
                </div>
              </Card>
            )}

            <Card>
              <div className='wrapper flex'>
                <div className='input_group col'>
                  <label htmlFor='password'>
                    {t('fields.password')} <span className='required'>*</span>
                  </label>
                  <div className='input_password'>
                    <input
                      id='password'
                      type='password'
                      value={formData.password}
                      onChange={(e) => handleValueChange(e, 'password')}
                      required
                      ref={passwordFieldRef}
                    />
                    <InputEye
                      onClick={() => {
                        const passwordInput = passwordFieldRef.current;

                        if (passwordInput.type === 'password') {
                          passwordInput.type = 'text';
                        } else {
                          passwordInput.type = 'password';
                        }
                      }}
                    />
                  </div>
                  <p className='input-help'>{t('fields.password_help')}</p>

                  {getErrors('password') && (
                    <p className='error'>
                      <small>{getErrors('password')}</small>
                    </p>
                  )}
                </div>
              </div>
            </Card>

            <Card>
              <div className='wrapper flex'>
                <div className='input_group col'>
                  <label htmlFor='phone'>
                    {t('fields.phone_number')}{' '}
                    <span className='required'>*</span>
                  </label>
                  <div className='input_phone'>
                    <IntlTelInput
                      containerClassName='intl-tel-input'
                      inputClassName='form-control'
                      autoPlaceholder
                      onPhoneNumberChange={(valid, value) => {
                        if (!valid && !getErrors('phone')) {
                          setFormErrors([
                            ...formErrors,
                            {
                              field: 'phone',
                              messages: t('fields.invalid_phone_number'),
                            },
                          ]);
                        } else {
                          deleteError('phone');
                        }

                        handleValueChange({ target: { value } }, 'phone');
                      }}
                      telInputProps={{ required: true }}
                    />
                  </div>

                  {getErrors('phone') && (
                    <p className='error'>
                      <small>{getErrors('phone')}</small>
                    </p>
                  )}
                </div>

                <div className='input_group col'>
                  <label htmlFor='communication_channel'>
                    {t('fields.choose_communication_channel')}
                    <span className='required'>*</span>
                  </label>
                  <div className='input_communication_channel'>
                    <select
                      onChange={(e) =>
                        handleValueChange(e, 'communication_channel')
                      }
                    >
                      <option value='viber'>Viber</option>
                      <option value='whatsapp'>Whats App</option>
                    </select>
                  </div>

                  {getErrors('communication_channel') && (
                    <p className='error'>
                      <small>{getErrors('communication_channel')}</small>
                    </p>
                  )}
                </div>
              </div>
            </Card>

            <Card>
              <div className='wrapper flex'>
                <div className='input_group col'>
                  <label htmlFor='instagram'>
                    Instagram <span className='required'>*</span>
                  </label>
                  <input
                    id='instagram'
                    type='text'
                    placeholder={SocialLinks.instagram}
                    value={formData?.instagram}
                    onChange={(e) => handleValueChange(e, 'instagram')}
                    onBlur={(e) => validateSocialLinks(e, 'instagram')}
                    onPaste={(e) => handleSocialPaste(e, 'instagram')}
                    pattern={`${SocialLinks.instagram}.+`}
                    required
                  />

                  {(getErrors('instagram') ||
                    getErrors('social.instagram')) && (
                    <p className='error'>
                      <small>
                        {getErrors('instagram') ||
                          getErrors('social.instagram')}
                      </small>
                    </p>
                  )}
                </div>

                <div className='input_group col'>
                  <label htmlFor='facebook'>Facebook</label>
                  <input
                    id='facebook'
                    type='text'
                    placeholder={SocialLinks.facebook}
                    value={formData?.facebook}
                    onChange={(e) => handleValueChange(e, 'facebook')}
                    onPaste={(e) => handleSocialPaste(e, 'facebook')}
                  />

                  {(getErrors('facebook') || getErrors('social.facebook')) && (
                    <p className='error'>
                      <small>
                        {getErrors('facebook') || getErrors('social.facebook')}
                      </small>
                    </p>
                  )}
                </div>

                <div className='input_group col'>
                  <label htmlFor='tiktok'>TikTok</label>
                  <input
                    id='tiktok'
                    type='text'
                    placeholder={SocialLinks.tiktok}
                    value={formData?.tiktok}
                    onChange={(e) => handleValueChange(e, 'tiktok')}
                    onPaste={(e) => handleSocialPaste(e, 'tiktok')}
                  />

                  {(getErrors('tiktok') || getErrors('social.tiktok')) && (
                    <p className='error'>
                      <small>
                        {getErrors('tiktok') || getErrors('social.tiktok')}
                      </small>
                    </p>
                  )}
                </div>

                <div className='input_group col'>
                  <label htmlFor='youtube'>Youtube</label>
                  <input
                    id='youtube'
                    type='text'
                    placeholder={SocialLinks.youtube}
                    value={formData?.youtube}
                    onChange={(e) => handleValueChange(e, 'youtube')}
                    onPaste={(e) => handleSocialPaste(e, 'youtube')}
                  />

                  {(getErrors('youtube') || getErrors('social.youtube')) && (
                    <p className='error'>
                      <small>
                        {getErrors('youtube') || getErrors('social.youtube')}
                      </small>
                    </p>
                  )}
                </div>

                <div className='input_group col'>
                  <label htmlFor='website'>{t('fields.website')}</label>
                  <input
                    id='website'
                    type='text'
                    value={formData?.website}
                    onChange={(e) => handleValueChange(e, 'website')}
                  />

                  {(getErrors('website') || getErrors('social.website')) && (
                    <p className='error'>
                      <small>
                        {getErrors('website') || getErrors('social.website')}
                      </small>
                    </p>
                  )}
                </div>
              </div>
            </Card>

            <Card>
              <div className='wrapper'>
                <div className='input_group'>
                  <label htmlFor='photoUpload'>
                    {t('fields.professional_photo')}
                  </label>
                  <FileUpload
                    id='photoUpload'
                    onChange={(e) => handleFileChange(e, 'avatar')}
                    allowed={t('fields.photo_allowed')}
                    recommendation={t('fields.photo_recommendation')}
                    accept='image/*'
                  />
                </div>
              </div>

              <div className='wrapper flex'>
                <div className='input_group'>
                  <label htmlFor='agreeTerms'>
                    <input
                      id='agreeTerms'
                      type='checkbox'
                      required
                      value={formData?.agreeTerms}
                      onChange={(e) => handleValueChange(e, 'agreeTerms')}
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('register.terms_and_conditions'),
                      }}
                    />
                  </label>
                </div>

                <div className='input_group'>
                  <label htmlFor='agreePhiAwardTerms'>
                    <input
                      id='agreePhiAwardTerms'
                      type='checkbox'
                      required
                      value={formData?.agreePhiAwardTerms}
                      onChange={(e) =>
                        handleValueChange(e, 'agreePhiAwardTerms')
                      }
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('register.privacy_policy'),
                      }}
                    />
                  </label>
                </div>
              </div>
            </Card>

            <Button
              variant='primary'
              className='flex proceed'
              type='submit'
              styles={{ borderRadius: 0, marginBottom: '5%', width: '100%' }}
            >
              <span>{t('register.proceed')}</span>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_526_2508)'>
                  <path
                    d='M1.33317 8L14.6665 8'
                    stroke='white'
                  />
                  <path
                    d='M7.99984 1.33341L14.6665 8.00008L7.99984 14.6667'
                    stroke='white'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_526_2508'>
                    <rect
                      width='16'
                      height='16'
                      fill='white'
                    />
                  </clipPath>
                </defs>
              </svg>
            </Button>
          </form>
        )}
      </div>

      <Modal
        showModal={showCertInfo}
        closeModal={() => setShowCertInfo(false)}
        className='rounded rounded-mobile'
        style={{ width: '70%', height: '65%' }}
      >
        <div className='flex'>
          <img
            src={CertificateImg}
            alt='Certificate'
            className='w-50'
            style={{
              margin: '0 auto',
              maxHeight: '100%',
              height: '100vh',
              display: 'block',
            }}
          />

          <div
            className='wrapper'
            style={{ padding: '50px' }}
          >
            {t('register.hints.certificate_number_messages', {
              returnObjects: true,
            }).map((message, index) => (
              <p
                className='desc'
                style={{ maxWidth: '100%' }}
                key={index}
              >
                {message}
              </p>
            ))}
          </div>
        </div>
      </Modal>

      <Modal
        showModal={showEmailHint}
        closeModal={() => setShowEmailHint(false)}
        className='rounded'
        hideBrand
        style={{ width: '40%' }}
      >
        <div
          className='wrapper'
          style={{ padding: '50px 40px 40px' }}
        >
          {t('register.hints.email_messages', {
            returnObjects: true,
          }).map((message, index) => (
            <p
              className='desc'
              style={{ maxWidth: '100%' }}
              key={index}
            >
              {message}
            </p>
          ))}
        </div>
      </Modal>
    </Styled.Wrapper>
  );
};
