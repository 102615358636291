import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './Footer.style';
import { Link, SocialLinks } from '../Shared';

import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';
import { ReactComponent as XLogo } from '../../assets/images/xLogo.svg';
import { ReactComponent as XLogo2 } from '../../assets/images/xLogo2.svg';
import { ReactComponent as FaceBook } from '../../assets/images/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/images/twitter.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/linkedin.svg';
import { ReactComponent as YouTube } from '../../assets/images/youtube.svg';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Styled.Footer>
      <div className='container'>
        <div className='top_group'>
          <a
            href='https://phi-academy.com/'
            target='_blank'
            rel='noreferrer'
            className='go_back'
          >
            <XLogo className='xLogo' />
            <XLogo2 className='xLogo2' />
          </a>

          <BrandLogo />
        </div>

        <div className='bottom_group'>
          <p className='poweredBy'>{t('footer.powered-by')}</p>

          <SocialLinks className='socialLinks'>
            <a href='#f' className='link' target='_blank'>
              <FaceBook />
            </a>
            <a href='#f' className='link' target='_blank'>
              <Instagram />
            </a>
            <a href='#f' className='link' target='_blank'>
              <Twitter />
            </a>
            <a href='#f' className='link' target='_blank'>
              <LinkedIn />
            </a>
            <a href='#f' className='link' target='_blank'>
              <YouTube />
            </a>
          </SocialLinks>

          <p className='copyright'>
            {t('footer.all-rights-reserved')}{' '}
            <Link to='/privacy-policy' variant='none'>
              {t('footer.privacy-policy')}
            </Link>{' '}
            <Link to='/terms-and-conditions' variant='none'>
              {t('footer.terms-and-conditions')}
            </Link>
            .<br />{' '}
            <a href='https://phi-academy.com/pdf/final_doo_EN.pdf'>
              ISO 9001:2015
            </a>
          </p>
        </div>
      </div>
    </Styled.Footer>
  );
};
