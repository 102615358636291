import React from 'react';

const defaultValues = {
  loading: false,
  fetching: false,
  introLoaded: false,
};

export const GlobalContext = React.createContext({
  state: defaultValues,
  setState: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = React.useState(defaultValues);

  const updateGlobalState = (data) => {
    setGlobalState({
      ...globalState,
      ...data,
    });
  };

  return (
    <GlobalContext.Provider
      value={{ state: globalState, setState: updateGlobalState }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
