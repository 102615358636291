import styled from 'styled-components';

export const ProgressRange = styled.div`
  margin-bottom: 45px;

  .range {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    width: 100%;

    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #111111;
    margin-bottom: 9px;
  }

  .progress {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #dfdfdf;

    .line {
      position: absolute;
      left: 0;

      background-color: #a37c51;
      width: ${({ value }) => value || '0'}%;
      max-width: 100%;
      height: 100%;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translateY(-50%);
        right: 0;

        width: 2px;
        height: 12px;
        background-color: #a37c51;
      }
    }

    .tooltip {
      position: absolute;

      padding: 4px 8px;

      background: #a37c51;
      border-radius: 2px;

      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #fafafa;

      left: 0;
      right: 0;
      width: fit-content;
      margin: 0 auto;
      margin-top: 18px;

      &::before {
        content: '';
        position: absolute;
        top: -13px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #a37c51;
        height: 5px;

        width: fit-content;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
`;
