import Marquee from 'react-fast-marquee';
import styled from 'styled-components';

export const StyledMarquee = styled(Marquee)`
  background-color: #000;

  font-family: 'Saol Display';
  font-style: italic;
  font-weight: 300;
  font-size: 114px;
  text-transform: uppercase;
  color: #fafafa;

  .overlay::before,
  .overlay::after {
    content: unset;
  }
`;
