import styled, { css } from 'styled-components';

import { Link as RouteLink } from 'react-router-dom';

const ButtonCss = css`
  width: fit-content;
  height: fit-content;

  background: #a37c51;
  border-radius: 30px;
  border: 0;

  cursor: pointer;

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ButtonLabelCss = css`
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
`;

const ButtonVariants = (variant = 'primary', styles) =>
  ({
    primary: css`
      ${ButtonCss}
      ${ButtonLabelCss}
      padding: 22px 56px;
      color: #fff;
      background: #a37c51;
      ${styles}
    `,
    secondary: css`
      ${ButtonCss}
      ${ButtonLabelCss}
      padding: 9px 16px;
      color: #fff;
      background: transparent;
      border: 1px solid #393939;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      ${styles}

      &.active {
        background-color: #fafafa;
        color: #111111;

        svg,
        path {
          fill: #111111;
        }
      }
    `,
    secondary2: css`
      ${ButtonCss}
      ${ButtonLabelCss}
      padding: 9px 16px;
      color: #fff !important;
      background: #000;
      border: 1px solid #000;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      ${styles}
    `,
    gray: css`
      ${ButtonCss}
      ${ButtonLabelCss}
      padding: 22px 56px;
      color: #fff;
      background: rgba(255, 255, 255, 0.3);
      line-height: 16px;
      ${styles}
    `,
    voucher: css`
      ${ButtonCss}
      ${ButtonLabelCss}
      padding: 22px 56px;
      background: #dfdfdf;
      color: #2f2f2f;
      ${styles}
    `,
    'link-primary': css`
      ${ButtonLabelCss}
      color: #A37C51;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      text-transform: capitalize;
      ${styles}
    `,
    link: css`
      ${ButtonLabelCss}
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      background: transparent;
      border: none;
      ${styles}
    `,
  }[variant]);

export const ExternalLink = styled.a`
  display: inline-block;
  ${({ variant, styles }) => ButtonVariants(variant, styles)}
`;
export const Link = styled(RouteLink)`
  display: inline-block;
  ${({ variant, styles }) => ButtonVariants(variant, styles)}
`;

export const Button = styled.button`
  width: 100%;
  cursor: pointer;
  ${({ variant, styles }) => ButtonVariants(variant, styles)}

  &.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &.proceed:not(.validate) {
    padding: 36px 56px;
  }

  &.proceed,
  &.buyNow,
  &.enterVoucher {
    position: relative;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &:not(.validate) {
      gap: 45px;
    }

    & > * {
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0;
      height: 100%;
      background: #2f2f2f;
      z-index: 1;
      transition: all 1s cubic-bezier(0.12, 0.02, 0, 1);
    }

    &:hover {
      gap: 72px;
      color: #a37c51;

      svg,
      path {
        stroke: #a37c51;
      }

      &::after {
        width: 100%;
      }
    }
  }
`;
