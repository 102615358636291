import styled from 'styled-components';

export const Navbar = styled.div`
  position: ${({ dark }) => (dark ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ dark }) => dark && '#111'};
  z-index: 999;
  height: 80px;

  .brand-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 100%;
  }

  & .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .hamburger-menu {
    cursor: pointer;
  }

  .nav-links {
    display: none;
    padding: ${({ dark }) => (dark ? '21px 0' : '32px 0')};

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 24px;

      li {
        appearance: none;

        font-family: 'Arimo';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fafafa;

        a {
          text-decoration: none;
          color: #fafafa;
        }

        .active {
          color: #a37c51;

          a {
            color: #a37c51;
          }
        }
      }
    }
  }

  .right_column {
    display: flex;
    align-items: center;
    gap: 24px;

    width: 100%;
    justify-content: space-between;
    margin-left: 24px;

    padding: ${({ dark }) => (dark ? '21px 0' : '32px 0')};
  }

  .hamburger-menu {
    margin-left: auto;
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 100%;
      padding-right: 24px;
    }
    .brand-logo {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 25px;

      &.go-back {
        background-color: #000;
      }

      .mobile {
        width: 30px;
        height: 80px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .nav-links {
      display: block;
    }
    .right_column {
      margin-left: 0;
      width: fit-content;
      justify-content: unset;
    }
  }
`;

export const LanguageSelector = styled.div`
  color: #fff;
  font-family: Arimo;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  cursor: pointer;

  span::after {
    content: '';
    margin: 2px;
    margin-left: 5px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px 2px 5px 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &:hover ul {
    opacity: 1;
    visibility: visible;
  }

  ul {
    position: absolute;
    background-color: rgb(17, 17, 17);

    padding: 10px 20px;
    margin: 0;
    list-style: none;

    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
`;
