import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { Button, Link, RankList } from '../../../components';
import {
  getAllScoreCategories,
  getAvailableLocations,
  getRankList,
} from '../../../api';
import { getUploads } from '../../../utils/functions';
import { MASTER_ROLES, TECHNIQUES } from '../../../utils/constants';

import * as Styled from './RankList.style';
import { ReactComponent as BuyAReview } from '../../../assets/images/Icons/buyAReview.svg';

const iRankItem = {};
export const RankListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [locations, setLocations] = React.useState({});
  const [ranks, setRanks] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [pagination, setPagination] = React.useState({});
  const [toggleFilters, setToggleFilters] = React.useState(false);
  const [filters, setFilters] = React.useState({
    page: 1,
  });
  const [categories, setCategories] = React.useState([]);

  let paginationDots = false;

  React.useEffect(() => {
    document.body.style.backgroundColor = '#000';

    setFilters({ page: 1 });

    getAvailableLocations()
      .then(({ countries, cities }) => {
        setLocations({ countries, cities });
      })
      .catch((err) => console.error(err));

    getAllScoreCategories()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getRankList(filters)
      .then((res) => {
        setCurrentUser(res?.currentUser || null);
        setRanks(res.data);
        setPagination(res.pagination);
      })
      .catch((err) => console.error(err));
  }, [filters]);

  const setFilter = (key, value) => {
    const newFilters = { ...filters, [key]: value };

    if (key !== 'page') {
      newFilters['page'] = 1;
    }

    setFilters(newFilters);
  };

  const handleMultiSelect = (key, value) => {
    const values = filters[key] || [];

    if (value === null) {
      setFilter(key, null);
      return;
    }

    if (values.includes(value)) {
      setFilter(
        key,
        values.filter((v) => v !== value)
      );
      return;
    }

    setFilter(key, [...values, value]);
  };

  const generateRankItems = (ranks, page) => {
    iRankItem[page] = iRankItem[page - 1] || 0;

    return ranks?.map((item) => {
      iRankItem[page] = iRankItem[page] + 1;

      const user = item?.user || item;

      return {
        id: user?._id || user?.id,
        name: `${user?.first_name} ${user?.last_name}`,
        avatar: getUploads(user?.photo),
        points: item?.scoreSum || item?.scoresSum,
        title: user?.title,
        index: iRankItem[page],
        ...(user?.rank && { rank: user?.rank }),
      };
    });
  };

  return (
    <Styled.RankListWrapper>
      <div className='container-half'>
        <h1 dangerouslySetInnerHTML={{ __html: t('rank-list.title') }} />

        <Button
          variant='secondary'
          className={`flex ${toggleFilters ? 'active' : ''} toggleFilters`}
          onClick={() => setToggleFilters(!toggleFilters)}
          styles={{
            borderRadius: '8px',
            width: '100%',
            marginBottom: '16px',
            padding: '20px 0',
          }}
        >
          {t('rank-list.filters')}
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.11667 14V10.25H8.11667V11.6333H14V12.6333H8.11667V14H7.11667ZM2 12.6333V11.6333H6.11667V12.6333H2ZM5.11667 9.86667V8.5H2V7.5H5.11667V6.1H6.11667V9.86667H5.11667ZM7.11667 8.5V7.5H14V8.5H7.11667ZM9.88333 5.75V2H10.8833V3.36667H14V4.36667H10.8833V5.75H9.88333ZM2 4.36667V3.36667H8.88333V4.36667H2Z'
              fill='#FAFAFA'
            />
          </svg>
        </Button>

        <div className={`filter ${toggleFilters ? 'show' : ''}`}>
          <label htmlFor=''>{t('rank-list.search')}</label>

          <div className='input_group'>
            {/* <select placeholder='By Continent'>
              <option value='1'>Europe</option>
            </select> */}
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              options={locations?.countries?.map((country) => ({
                value: country,
                label: country,
              }))}
              placeholder={t('rank-list.by_country')}
              onChange={(selected) =>
                handleMultiSelect('address.country', selected.value || null)
              }
              value={''}
            />

            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              options={locations?.cities?.map((city) => ({
                value: city,
                label: city,
              }))}
              placeholder={t('rank-list.by_city')}
              onChange={(selected) =>
                handleMultiSelect('address.city', selected.value || null)
              }
              value={''}
            />

            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              options={MASTER_ROLES?.map((title) => ({
                value: title,
                label: title,
              }))}
              placeholder={t('rank-list.by_title')}
              onChange={(selected) =>
                handleMultiSelect('title', selected.value || null)
              }
              value={''}
            />

            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              options={TECHNIQUES?.map((technique) => ({
                value: technique,
                label: technique,
              }))}
              placeholder={t('rank-list.by_technique')}
              onChange={(selected) =>
                handleMultiSelect('technique', selected.value || null)
              }
              value={''}
            />

            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              options={categories?.map((category) => ({
                value: category,
                label: category,
              }))}
              placeholder={t('rank-list.by_category')}
              onChange={(selected) =>
                handleMultiSelect('category', selected.value || null)
              }
              value={''}
            />
          </div>
        </div>

        <div className='tags'>
          {Object.keys(filters).map((key) => {
            if (key === 'page') return null;

            return filters[key]?.map((value, index) => (
              <span
                className='tag'
                key={index}
                onClick={() => handleMultiSelect(key, value)}
                title={`${(
                  key.split('.')[1] ||
                  key ||
                  ''
                ).toUpperCase()}: ${value}`}
              >
                {value}
              </span>
            ));
          })}
        </div>
      </div>

      <div className='container-half mobile'>
        <RankList
          items={generateRankItems(ranks, pagination.page || 1)}
          current={currentUser && generateRankItems([currentUser], -1)?.[0]}
          onClick={(user) => {
            navigate(`/account/user/${user.id}`);
          }}
        />

        <div className='paginate'>
          {[...Array(pagination.pages)].map((item, index) => {
            if (
              index <= 1 ||
              index >= pagination.pages - 1 ||
              (index >= pagination.page - 2 && index <= pagination.page + 1)
            ) {
              return (
                <span
                  className={`${index === pagination.page - 1 ? 'active' : ''}`}
                  onClick={() => setFilter('page', index + 1)}
                  key={index}
                >
                  {index + 1}
                </span>
              );
            } else {
              if (!paginationDots) {
                paginationDots = true;
                return (
                  <span
                    className='dots'
                    key={index}
                  >
                    ...
                  </span>
                );
              }

              return null;
            }
          })}
        </div>
      </div>

      <div className='sticky-button'>
        <div className='container'>
          <Link
            to={'/account/buy-a-review'}
            variant='primary'
            className='button'
            styles={{
              padding: '16px 24px',
              display: 'flex',
              gap: '4px',
              textTransform: 'unset',
            }}
          >
            <BuyAReview
              width={16}
              height={16}
            />
            {t('buy_a_review.better_score')}
          </Link>
        </div>
      </div>
    </Styled.RankListWrapper>
  );
};
