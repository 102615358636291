import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BottomStickyBar,
  CompetitionSection,
  Hero,
  Loader,
  PhiSteps,
  SwiperContent,
} from '../../components';

import { StyledMarquee } from './Home.style';
import { RegisterPage } from '../Register/Register';

export const HomePage = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [registerPopup, setRegisterPopup] = React.useState(false);

  const PhiStepsIcons = [
    {
      title: 'Join the program',
      key: 0,
      icon: () => <span>1</span>,
    },
    {
      title: 'Improve your technique',
      key: 1,
      icon: () => <span>2</span>,
    },
    {
      title: 'Support for marketing and selling',
      key: 2,
      icon: () => <span>3</span>,
    },
    {
      title: 'Become an influencer',
      key: 3,
      icon: () => <span>4</span>,
    },
    {
      title: 'Boost personal development',
      key: 4,
      icon: () => <span>5</span>,
    },
    {
      title: 'Get an award',
      key: 5,
      icon: () => <span>6</span>,
    },
  ];

  setTimeout(() => setLoading(false), 1000);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <>
      <Hero />

      <PhiSteps items={PhiStepsIcons} />

      <CompetitionSection />

      <SwiperContent />

      <StyledMarquee speed={150}>{t('home.marquee')}</StyledMarquee>

      <BottomStickyBar onClick={() => setRegisterPopup(true)} />

      <RegisterPage
        toggleable
        show={registerPopup}
        togglePopup={() => {
          document.body.style.overflow = 'unset';
          setRegisterPopup(false);
        }}
      />
    </>
  );
};
