import React from 'react';
import { useTranslation } from 'react-i18next';

import { getUserTransactions } from '../../../../api';
import { Table } from '../../../../components';

export const PurchaseHistory = () => {
  const { t } = useTranslation();
  const [userTransactions, setUserTransactions] = React.useState([]);

  React.useEffect(() => {
    getUserTransactions()
      .then((res) => {
        setUserTransactions(res.data);
      })
      .catch(console.error);
  }, []);

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('profile.settings.purchase_history.table.date')}</th>
          <th>{t('profile.settings.purchase_history.table.description')}</th>
          <th>{t('profile.settings.purchase_history.table.ref')}</th>
          <th>{t('profile.settings.purchase_history.table.total')}</th>
          <th>{t('profile.settings.purchase_history.table.download')}</th>
        </tr>
      </thead>

      <tbody>
        {userTransactions.map((item, i) => (
          <tr key={i}>
            <td>{item?.createdAt}</td>
            <td>{item?.order}</td>
            <td>{item?.payment?.payment}</td>
            <td>
              {item?.total} {item?.currency}
            </td>
            <td>
              <a href='#link'>
                {t('profile.settings.purchase_history.table.download')}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
