import React from 'react';

const freshDeskURL = process.env.REACT_APP_FRESHDESK;

export const useFreshchat = ({ userInfo = {}, show }) => {
  const [showFreshdesk, setShowFreshdesk] = React.useState(show);
  const [user, setUser] = React.useState(userInfo);

  const init = () => {
    if (freshDeskURL && !document.getElementById('freshDesk')) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'freshDesk';
      scriptTag.type = 'text/javascript';
      scriptTag.src = freshDeskURL;
      scriptTag.setAttribute('chat', 'true');

      document.body.appendChild(scriptTag);

      window.fcWidgetMessengerConfig = {
        config: {
          agent: {
            hideName: true,
            hidePic: true,
            hideBio: true,
          },
        },
      };
    } else {
      toggle();
    }
  };

  const toggle = () => {
    const elements = [
      document.querySelector('#fc_frame'),
      document.querySelector('#fc_push_frame'),
    ];

    elements.forEach((element) => {
      if (element) {
        if (showFreshdesk) {
          element.style.display = '';
        } else {
          element.style.display = 'none';
        }
      }
    });
  };

  const updateUser = (data) => {
    setUser(data);

    console.log(data);
    if (window.fcWidget) {
      window.fcWidget.user.setEmail(data.email);
      window.fcWidget.user.setFirstName(data.firstName);
      window.fcWidget.user.setLastName(data.lastName);
    }
  };

  React.useEffect(() => {
    if (!showFreshdesk) {
      toggle();
      return;
    }

    init();

    return () => {
      toggle();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFreshdesk]);

  return { showFreshdesk, setShowFreshdesk, updateUser };
};
