import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    &:last-child {
      white-space: nowrap;
      width: 1%;
    }

    a {
      text-decoration-line: underline;
      color: #a37c51;
    }
  }

  thead {
    th,
    td {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #2f2f2f;
      padding: 0 10px 24px;
      white-space: nowrap;

      border-bottom: 1px solid #dfdfdf;
    }
  }

  tbody {
    tr {
      th,
      td {
        font-family: 'Arimo';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #2f2f2f;
        white-space: nowrap;

        padding: 24px 10px;
        border-bottom: 1px solid #dfdfdf;
      }

      &:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }
  }
`;
