import React from 'react';

import * as Styled from './Modal.style';

import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';

export const Modal = ({
  hideHeader = false,
  hideBrand = false,
  className,
  showModal,
  closeModal,
  children,
  borderRadius = 0,
  style,
}) => {
  const modalRef = React.createRef();

  if (showModal) {
    document.body.style.overflow = 'hidden';
  } else {
    // document.body.style.overflow = 'unset';
  }

  React.useEffect(() => {
    const modalWrapper = modalRef?.current?.querySelector('.body .wrapper');

    if (showModal && modalWrapper) {
      modalWrapper.scroll(0, 0);
    }
  }, [showModal, modalRef]);

  const handleClose = () => {
    if (!closeModal) {
      return;
    }

    document.body.style.overflow = 'unset';

    closeModal();
  };

  return (
    <>
      <Styled.Overlay
        className={`${showModal ? 'show' : ''}`}
        onClick={handleClose}
      />

      <Styled.Modal
        className={`modal ${className} ${showModal ? 'show' : ''}`}
        borderRadius={borderRadius}
        style={style}
        ref={modalRef}
      >
        {!hideHeader && (
          <div className='header'>
            {!hideBrand && (
              <BrandLogo
                className='logo'
                onClick={handleClose}
              />
            )}
            {/* {closeModal && <CloseIcon className='closeIcon' onClick={handleClose} />} */}

            {closeModal && (
              <svg
                width='44'
                height='44'
                viewBox='0 0 44 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='closeIcon'
                onClick={handleClose}
              >
                <circle
                  cx='22'
                  cy='22'
                  r='21.5'
                  fill='black'
                />
                <path
                  d='M27.625 16.375L16.375 27.625'
                  stroke='#fff'
                  strokeLinecap='square'
                  strokeLinejoin='round'
                />
                <path
                  d='M27.625 27.625L16.375 16.375'
                  stroke='#fff'
                  strokeLinecap='square'
                  strokeLinejoin='round'
                />
              </svg>
            )}
          </div>
        )}

        <div className='body'>{children}</div>
      </Styled.Modal>
    </>
  );
};
