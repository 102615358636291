import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './LoginModal.style';

import { GlobalContext, useAuth } from '../../hooks';
import { Button, Link } from '../Shared';
import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';
import LoginImageDesktop from '../../assets/images/login-desktop.jpg';
import LoginImageMobile from '../../assets/images/login-mobile.jpg';

export const LoginModal = ({ showModal, closeModal }) => {
  const { login } = useAuth();
  const { t } = useTranslation();
  const { setState: setGlobalState } = React.useContext(GlobalContext);

  const [formErrors, setFormErrors] = React.useState([]);
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
  });

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const handleValueChange = (event, key) => {
    const { value } = event.target;

    setFormData({ ...formData, [key]: value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setGlobalState({ loading: true });

    login({ ...formData })
      .then(() => {
        setGlobalState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        setGlobalState({ loading: false });
        setFormErrors(error.errors);
      });
  };

  const getErrors = (field) =>
    formErrors?.find((error) => error.field === field)?.messages || null;

  return (
    <Styled.LoginModal className={`${showModal && 'show'}`}>
      <div className='header'>
        <BrandLogo
          className='logo'
          onClick={closeModal}
        />

        <div className='right_group'>
          <Link
            variant='link'
            className='register'
            to='/register'
          >
            {t('login_modal.register')}
          </Link>
          <Link
            variant='gray'
            to='/register'
          >
            {t('login_modal.register_now')}
          </Link>
        </div>

        <svg
          width='44'
          height='44'
          viewBox='0 0 44 44'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='close'
          onClick={closeModal}
        >
          <circle
            opacity='0.24'
            cx='22'
            cy='22'
            r='21.5'
            stroke='#DFDFDF'
          />
          <path
            d='M27.625 16.375L16.375 27.625'
            stroke='#FAFAFA'
            strokeLinecap='square'
            strokeLinejoin='round'
          />
          <path
            d='M27.625 27.625L16.375 16.375'
            stroke='#FAFAFA'
            strokeLinecap='square'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <div className='wrapper'>
        <img
          src={isMobile ? LoginImageMobile : LoginImageDesktop}
          alt='Login Women'
        />
        <form
          className='form_wrapper login_form'
          onSubmit={handleLogin}
        >
          <div className='input_group'>
            <label htmlFor='email'>{t('fields.email')}</label>
            <input
              id='email'
              type='text'
              value={formData.email}
              placeholder={t('fields.email_placeholder')}
              onChange={(e) => handleValueChange(e, 'email')}
            />

            {getErrors('email') && (
              <p className='error'>
                <small>{getErrors('email')}</small>
              </p>
            )}
          </div>

          <div className='input_group'>
            <div className='label_group'>
              <label htmlFor='password'>{t('fields.password')}</label>
              <Link
                variant='link-primary'
                styles={{ letterSpacing: 'unset' }}
                to='?forget-password=true'
              >
                {t('fields.forget_password')}
              </Link>
            </div>
            <input
              id='password'
              type='password'
              value={formData.password}
              placeholder={t('fields.password_placeholder')}
              onChange={(e) => handleValueChange(e, 'password')}
            />

            {getErrors('password') && (
              <p className='error'>
                <small>{getErrors('password')}</small>
              </p>
            )}
          </div>

          <div className='input_group'>
            <label
              htmlFor='checkbox'
              className='checkbox'
            >
              {/* <input id='checkbox' type='checkbox' required /> */}
              <span
                dangerouslySetInnerHTML={{ __html: t('login_modal.agree') }}
              />
            </label>
          </div>

          <Button
            variant='primary'
            type='submit'
            styles={{ width: '100%' }}
          >
            {t('login_modal.login')}
          </Button>
        </form>
      </div>
    </Styled.LoginModal>
  );
};
