import styled from 'styled-components';

export const SwiperContentSection = styled.section`
  background-color: #000;
  overflow: hidden;
  position: relative;
  padding: 80px 0;

  h1.title {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 300;
    font-size: 114px;
    line-height: 104px;
    text-align: center;
    color: #fafafa;
    margin: 0;
    margin-bottom: 98px;
  }

  .swiper-content {
    .swiper-slide {
      & + .swiper-slide {
        margin-top: 160px;
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: fit-content;
    height: fit-content;

    font-family: 'Arimo';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #a37c51;
  }
  .page-number {
    display: block;

    font-family: 'Arimo';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #a37c51;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 50%;
      height: 50%;
      max-height: 443px;
    }
    h3 {
      font-family: 'Saol Display';
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 48px;
      color: #fafafa;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      margin-top: 30px;
    }
    p {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #fafafa;
      margin-left: 102px;
      max-width: 384px;

      .subtitle {
        font-family: 'Saol Display';
        font-size: 25px;
        line-height: 38px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .swiper-content {
      .swiper-slide {
        img {
          order: 2;
        }

        .info {
          order: 1;
        }

        & + .swiper-slide {
          margin-top: 64px;
        }
      }
    }

    .swiper-slide {
      flex-direction: column;

      p {
        margin-left: 0;
        margin-bottom: 32px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    h1.title {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 52px;
    }
  }
`;
