import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './LoginModal.style';

import { Button } from '../Shared';
import { forgetPassword } from '../../api';
import { useNavigate } from 'react-router-dom';

import GoBack from '../../assets/images/goBack.svg';

export const ForgetModal = ({ showModal, closeModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = React.useState([]);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: '',
  });

  const handleValueChange = (event, key) => {
    const { value } = event.target;

    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    forgetPassword(formData)
      .then((res) => {
        setFormErrors([]);
        setFormSuccess(true);
      })
      .catch((err) => setFormErrors(err.errors));
  };

  const getErrors = (field) =>
    formErrors.find((error) => error.field === field)?.messages || null;

  return (
    <Styled.LoginModal className={`${showModal ? 'show' : ''} fit`}>
      <div className='header'>
        <div className='goBack' onClick={() => navigate('/')}>
          <img src={GoBack} alt='Go Back' />
          {t('forget_password_modal.go_back')}
        </div>
      </div>
      <div
        className='wrapper'
        style={{ maxWidth: '75%', margin: '0 auto', display: 'block' }}
      >
        {!formSuccess ? (
          <>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('forget_password_modal.title.0'),
              }}
            />

            <form className='form_wrapper reset_form'>
              <div className='input_group'>
                <label htmlFor='email'>{t('fields.email')}</label>
                <input
                  id='email'
                  type='text'
                  value={formData.email}
                  onChange={(e) => handleValueChange(e, 'email')}
                />

                {getErrors('email') && (
                  <p className='error'>
                    <small>{getErrors('email')}</small>
                  </p>
                )}
              </div>

              <Button
                variant='primary'
                onClick={handleSubmit}
                styles={{ width: '100%' }}
              >
                {t('forget_password_modal.reset_button')}
              </Button>
            </form>
          </>
        ) : (
          <div className='success'>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('forget_password_modal.title.1'),
              }}
            />
            <p className='primary-color sended'>
              {t('forget_password_modal.message.0')}
            </p>
            <p>{t('forget_password_modal.message.1')}</p>
          </div>
        )}
      </div>
    </Styled.LoginModal>
  );
};
