import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './BottomStickyBar.style';
import { ReactComponent as BrandLogo } from '../../assets/images/logo.svg';
import { Link } from '../Shared';

export const BottomStickyBar = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Styled.BottomStickyBar className='BottomStickyBar'>
      <div className='container'>
        <BrandLogo />

        <div className='wrapped'>
          <p className='application'>{t('bottom-sticky-bar.text')}</p>

          <Link to={!onClick && '/register'} onClick={onClick && onClick}>
            {t('bottom-sticky-bar.button')}
          </Link>
        </div>
      </div>
    </Styled.BottomStickyBar>
  );
};
