import styled from 'styled-components';

export const AccountHeaderElements = styled.div`
  text-align: center;

  .user_avatar {
    width: 88px;
    height: 88px;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 16px;
  }
  .user_photo {
    width: fit-content;
    height: fit-content;
    position: relative;
    margin: 0 auto;

    img {
      width: 88px;
      height: 88px;
      object-fit: cover;
      border-radius: 100%;
    }

    .user_group {
      width: 15px;
      height: 15px;

      position: absolute;
      bottom: 16px;
      right: 0;

      background-color: #1a1919;
      padding: 3px;
      border-radius: 100%;
    }
  }
  .user_name {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #fafafa;
    margin: 0 0 8px;
  }
  .user_group {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fafafa;
    margin: 0;
  }

  .social_links {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;

    gap: 8px;

    path {
      fill: #111;
    }
  }

  @media screen and (max-width: 768px) {
    .social_links {
      path {
        fill: #fff;
      }
    }
  }
`;
