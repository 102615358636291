import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './UnderReview.style';
import { Header, Modal, ProfileScore } from '../../components';
import { useAuth } from '../../hooks';
import { getUploads } from '../../utils/functions';
import { getUserSubscriptions, sendVerificationEmail } from '../../api';
import { BuyAReviewPage } from '../Account';

import { ReactComponent as UserVerified } from '../../assets/images/UserVerified.svg';
import LoginImageDesktop from '../../assets/images/login-desktop.jpg';
import LoginImageMobile from '../../assets/images/login-mobile.jpg';
import AvatarBG from '../../assets/images/defaultAvatar.png';

const titles = ['Almost There', 'Please be patient', 'Jury is voting'];

export const UnderReviewPage = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [userSubscription, setUserSubscription] = React.useState(null);
  const [iBadge, setBadgeIndex] = React.useState(1);
  const [badgeTitle, setBadgeTitle] = React.useState(titles[0]);
  const [emailSent, setEmailSent] = React.useState(false);

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const isSubscriptionActive = userSubscription?.subscriptions?.find(
    (subscription) => subscription.status === 'active'
  );

  const shuffle = React.useCallback(() => {
    if (iBadge + 1 === titles.length) {
      setBadgeIndex(0);
    } else {
      setBadgeIndex(iBadge + 1);
    }

    setBadgeTitle(titles[iBadge]);
  }, [iBadge]);

  React.useEffect(() => {
    if (!userSubscription) {
      getUserSubscriptions()
        .then((res) => {
          setUserSubscription(res.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscription]);

  React.useEffect(() => {
    if (!isSubscriptionActive) {
      return;
    }

    const intervalID = setInterval(shuffle, 600);
    return () => clearInterval(intervalID);
  }, [shuffle, isSubscriptionActive]);

  const sendEmailVerification = async (e) => {
    e.preventDefault();

    await sendVerificationEmail();
    setEmailSent(true);
  };

  return (
    <>
      <Header
        dark
        className={isSubscriptionActive && 'relative'}
        image={
          user?.photo !== 'defaultAvatar.svg'
            ? getUploads(user?.photo)
            : AvatarBG
        }
      >
        <Styled.AccountHeaderElements>
          <img
            src={getUploads(user?.photo)}
            alt={`${user?.name} Avatar`}
            className='user_avatar'
          />
          <p className='user_name'>
            {user?.name} {user?.verified && <UserVerified />}
          </p>
          <p className='user_group'>{user?.title}</p>

          {isSubscriptionActive && user?.verified && (
            <Styled.ReviewProgress
              value={60}
              className='absolute'
            />
          )}
        </Styled.AccountHeaderElements>
      </Header>

      {!isSubscriptionActive && (
        <Styled.Content
          className='container-half'
          style={{ padding: 0 }}
        >
          <ProfileScore />
        </Styled.Content>
      )}

      <Styled.Content>
        {!isSubscriptionActive ? (
          <>
            <BuyAReviewPage
              title={t('buy_a_review.title')}
              order={{
                name: 'Annual Plan',
                name_key: 'plan.0',
                price: 149,
                priceId: process.env.REACT_APP_ANNUAL_PLAN,
                period: 'year',
                period_key: 'period.0',
              }}
              mode='subscription'
              hideHeader
            />
          </>
        ) : (
          <>
            {user?.verified ? (
              <>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t('under_review.title.0'),
                  }}
                />

                <div className='badge'>{badgeTitle}</div>

                <p>{t('under_review.description.0')}</p>
              </>
            ) : (
              <>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t('under_review.title.1'),
                  }}
                />
                <p style={{ marginTop: '20px' }}>
                  {t('under_review.description.1')}
                </p>
              </>
            )}
          </>
        )}
      </Styled.Content>

      <Modal
        showModal={!user?.emailVerified}
        style={{
          maxWidth: '70%',
          width: '100%',
          maxHeight: '70%',
          height: '100%',
        }}
      >
        <div className='flex'>
          <img
            src={isMobile ? LoginImageMobile : LoginImageDesktop}
            alt='Modal Img'
            style={{ width: '40%' }}
          />

          <div className='wrapper'>
            <h2>{t('email_verification.title')}</h2>
            <p
              className='desc'
              style={{ color: '#000000', opacity: 1 }}
            >
              {t('email_verification.description')}
            </p>
            <p>
              <p
                style={{ margin: '15px 0 0' }}
                className='send-again'
              >
                <small>{t('email_verification.not_received')}</small>
                <a
                  href='#'
                  onClick={sendEmailVerification}
                >
                  {t('email_verification.resend')}
                </a>

                {emailSent && (
                  <p style={{ margin: 0 }}>
                    <small style={{ color: '#007000' }}>
                      {t('email_verification.verification_sent')}
                    </small>
                  </p>
                )}
              </p>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
