import React from 'react';
import { useTranslation } from 'react-i18next';

import { connectPayment, getUserStripePaymentMethods } from '../../../../api';
import { Button } from '../../../../components';
import { useAuth } from '../../../../hooks';

export const PaymentMethod = () => {
  const { t } = useTranslation();
  const { user, fetchUser } = useAuth();
  const [paymentMethod, setPaymentMethod] = React.useState(null);

  const connectStripe = () => {
    connectPayment()
      .then(() => {
        fetchUser();
      })
      .catch((error) => console.error(error));
  };

  const stripeCustomer = user?.paymentMethods?.find(
    (payment) => payment.paymentMethod === 'stripe'
  );

  if (stripeCustomer && paymentMethod === null) {
    getUserStripePaymentMethods()
      .then((res) => {
        setPaymentMethod(res.data);
      })
      .catch(console.error);
  }

  return (
    <>
      <div className='group'>
        {stripeCustomer ? (
          <div className='card'>
            <h3>{t('profile.settings.payment_method.title')}</h3>
            <p>{t('profile.settings.payment_method.description')}</p>
          </div>
        ) : (
          <Button
            variant='primary'
            onClick={connectStripe}
            styles={{ maxWidth: 'fit-content' }}
          >
            {t('profile.settings.payment_method.connect')}
          </Button>
        )}
      </div>

      {paymentMethod && paymentMethod.length > 0 && (
        <div className='group'>
          <div className='card'>
            <h3>{t('profile.settings.payment_method.credit_card.title')}</h3>

            <p>
              <span className='card_number'>
                <span>****</span>
                <span>****</span>
                <span>****</span>
                <span>{paymentMethod[0]?.card.last4}</span>
              </span>

              <span>
                (expires {paymentMethod[0]?.card.exp_month}/
                {paymentMethod[0]?.card.exp_year})
              </span>
            </p>
          </div>

          <p>
            {t('profile.settings.payment_method.credit_card.this_card_used')}
          </p>
        </div>
      )}
    </>
  );
};
