import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './Hero.style';
import { gsap } from 'gsap';

import HeroIntro from '../../assets/images/HeroIntro.png';
import Image1 from '../../assets/images/Intro/1.png';
import Image2 from '../../assets/images/Intro/2.png';
import Image3 from '../../assets/images/Intro/3.png';
import Image4 from '../../assets/images/Intro/4.png';
import Image5 from '../../assets/images/Intro/5.png';
import Image6 from '../../assets/images/Intro/6.png';
import Image7 from '../../assets/images/Intro/7.png';
import Image8 from '../../assets/images/Intro/8.png';
import Image9 from '../../assets/images/Intro/9.png';
import Image10 from '../../assets/images/Intro/10.png';
import Image11 from '../../assets/images/Intro/11.png';
import { Link } from '../Shared/Buttons';
import { GlobalContext } from '../../hooks';

const IntroAnimation = ({ wrapperRef, imgRef }) => {
  const { setState: setGlobalState, state: globalState } =
    React.useContext(GlobalContext);

  const introImg = React.useRef(null);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  React.useEffect(() => {
    let ctx = gsap.context(() => {}, wrapperRef.current);

    const imgRect = imgRef?.current?.getBoundingClientRect();
    const imgToLoadRect = introImg?.current?.getBoundingClientRect();

    window.scrollTo(0, 0);

    const anim = gsap.timeline({
      onComplete: () => setGlobalState({ introLoaded: true }),
    });
    if (globalState.introLoaded) {
      anim.set('.col img:not(.heroImg)', { opacity: 0 });
      anim.set('.heroImg', {
        scale: !isMobile ? 1.5 : 1,
        duration: 1,
        x: !isMobile ? imgRect.x - imgToLoadRect.x + 200 : imgRect.x - 100,
        y: !isMobile ? imgRect.y - imgToLoadRect.y + 200 : imgRect.y,
        zIndex: 9999999,
      });
      anim.set('.introAnim', { zIndex: 9 });
      anim.fromTo(
        '.text-wrap',
        { x: '-100%', zIndex: 99 },
        { x: 0, zIndex: 99 }
      );
      anim.set('body', { overflow: 'unset' });

      return;
    } else {
      anim.set('body', { overflow: 'hidden' });
      anim.fromTo(
        '.col .fade-down',
        { y: '-100%', duration: 0.5 },
        { y: 0, duration: 0.5 },
        0
      );
      anim.fromTo(
        '.col .fade-up',
        { y: '100%', duration: 0.5 },
        { y: 0, duration: 0.5 },
        0
      );
      anim.to('.col.anim:nth-child(odd)', {
        y: '-15%',
        duration: 0.5,
        delay: 1,
      });
      anim.to('.col.anim:nth-child(even)', { y: '-20%', duration: 0.5 });
      anim.to('.col img:not(.heroImg)', { opacity: 0, delay: 0.5 });
      anim.to('.heroImg', {
        scale: !isMobile ? 1.5 : 1,
        duration: 1,
        // x: !isMobile ? -150 : -100,
        // y: !isMobile ? 250 : 450,
        // width: imgRef?.current.offsetWidth,
        // height: imgRef?.current.offsetHeight,
        x: !isMobile ? imgRect.x - imgToLoadRect.x + 200 : imgRect.x - 100,
        y: !isMobile ? imgRect.y - imgToLoadRect.y + 350 : imgRect.y,
        zIndex: 9999999,
        delay: 0.5,
      });
      // anim.to('.introAnim', { opacity: 0, duration: 0.5, delay: 0.5 });
      anim.to('.introAnim', { zIndex: 9 });
      anim.fromTo(
        '.text-wrap',
        { x: '-100%', zIndex: 99, opacity: 0 },
        { x: 0, zIndex: 99, opacity: 1 }
      );
      // anim.to('.introAnim', { display: 'none', delay: 0.5 });
      anim.fromTo('.BottomStickyBar', { y: 200 }, { y: 0 });
      anim.set('body', { overflow: 'unset' });
    }

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, imgRef, isMobile]);

  if (isMobile) {
    return (
      <Styled.IntroWrapper className='introAnim'>
        <div className='col anim'>
          <img src={Image8} alt='Hero Intro Women' className='fade-down' />
          <img src={Image9} alt='Hero Intro Women' className='fade-down' />
          <img src={Image10} alt='Hero Intro Women' className='fade-up' />
        </div>
        <div className='col anim'>
          <img src={Image11} alt='Hero Intro Women' className='fade-down' />
          <img
            src={HeroIntro}
            alt='Hero Intro Women'
            className='heroImg fade-up'
            ref={introImg}
          />
          <img src={Image11} alt='Hero Intro Women' className='fade-up' />
        </div>
      </Styled.IntroWrapper>
    );
  }

  return (
    <Styled.IntroWrapper className='introAnim'>
      <div className='col'>
        <img src={Image1} alt='Hero Intro Women' className='fade-down' />
        <img src={Image2} alt='Hero Intro Women' className='fade-up' />
      </div>
      <div className='col anim'>
        <img src={Image3} alt='Hero Intro Women' className='fade-down' />
        <img src={Image4} alt='Hero Intro Women' className='fade-down' />
        <img src={Image5} alt='Hero Intro Women' className='fade-up' />
      </div>
      <div className='col anim'>
        <img src={Image6} alt='Hero Intro Women' className='fade-down' />
        <img src={Image7} alt='Hero Intro Women' className='fade-up' />
        <img src={Image3} alt='Hero Intro Women' className='fade-up' />
      </div>
      <div className='col anim'>
        <img src={Image8} alt='Hero Intro Women' className='fade-down' />
        <img src={Image9} alt='Hero Intro Women' className='fade-down' />
        <img src={Image10} alt='Hero Intro Women' className='fade-up' />
      </div>
      <div className='col anim'>
        <img src={Image11} alt='Hero Intro Women' className='fade-down' />
        <img
          src={HeroIntro}
          alt='Hero Intro Women'
          className='heroImg fade-up'
          ref={introImg}
        />
        <img src={Image11} alt='Hero Intro Women' className='fade-up' />
      </div>
      <div className='col'>
        <img src={Image3} alt='Hero Intro Women' className='fade-down' />
        <img src={Image4} alt='Hero Intro Women' className='fade-down' />
        <img src={Image5} alt='Hero Intro Women' className='fade-up' />
      </div>
    </Styled.IntroWrapper>
  );
};

export const Hero = () => {
  const { t } = useTranslation();
  const wrapper = React.useRef(null);
  const imgRef = React.useRef(null);

  return (
    <Styled.Hero ref={wrapper}>
      <div className='container'>
        <div></div>
        <div className='text-wrap'>
          <div className='text-wrap' style={{ width: '50%' }}>
            <h1 className='title'>{t('home.hero.title1')}</h1>
            <p className='desc'>{t('home.hero.description')}</p>
            <h1 className='title'>{t('home.hero.title2')}</h1>
          </div>

          <p className='desc' style={{ maxWidth: 'unset', margin: '20px 0 0' }}>
            <span
              dangerouslySetInnerHTML={{ __html: t('home.hero.description2') }}
            />

            <Link variant='gray' className='applyNow' to='/register'>
              {t('home.hero.button')}
            </Link>
          </p>
        </div>

        {/* <div className='heroIntroImage' ref={imgRef}>
          <img src={HeroIntro} alt='Hero Intro Women' ref={imgRef} />
        </div> */}
      </div>

      {/* <IntroAnimation wrapperRef={wrapper} imgRef={imgRef} /> */}
    </Styled.Hero>
  );
};
