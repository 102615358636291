import styled from 'styled-components';

export const Wrapper = styled.div`
  .container {
    position: relative;
  }

  .logo {
    display: block;
    margin: 0 auto;

    path {
      fill: #000;
    }
  }

  .buyNow {
    margin-bottom: 24px;
  }
  .skipForNow {
    margin-bottom: 38px;
  }

  &.toggleable {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    overflow-y: scroll;
    background: #f1f1f1;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999999999999;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  .goBack {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
  .goBackLeft {
    position: absolute;
    left: 0;
    z-index: 9999;
  }

  h1 {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 104px;
    text-align: center;
    color: #111111;

    margin: 32px 0 24px;

    position: relative;
  }

  p.subTitle {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    margin: 16px auto 56px;
  }

  label {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2f2f2f;
  }

  & .card {
    margin-bottom: 32px;

    &.error-wrapper {
      padding: 15px 0 !important;
    }

    .wrapper {
      max-width: 792px;
      margin: 0 auto;

      &.flex {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: flex-start;
        align-items: baseline;
      }

      .input_group.col {
        width: calc(50% - 12px);
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 0;
      }
    }
  }

  .user_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-bottom: 80px;

    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #111111;

    label {
      display: block;
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2f2f2f;
      margin-bottom: 12px;
    }
  }

  .hint {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #a37c51;
    margin-left: auto;
  }

  form .card {
    padding: 80px 0;
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 100%;
      margin-bottom: 80px;
    }

    form {
      margin-bottom: 100px;
    }

    & .card {
      padding: 40px 0 !important;
    }

    .proceed:not(.validate),
    .buyNow:not(.validate),
    .enterVoucher:not(.validate) {
      position: fixed !important;
      width: 100% !important;
      left: 0 !important;
      bottom: 0 !important;
      margin: 0 !important;
      z-index: 99 !important;
    }

    .enterVoucher:not(.validate) {
      bottom: 76px !important;
    }

    .skipForNow {
      margin-bottom: 200px;
    }

    h1 {
      margin-top: 70px;
    }
    .goBack,
    h1 .goBack,
    .goBackLeft,
    h1 .goBackLeft {
      top: -40px;
      right: 24px;
    }
  }
`;
