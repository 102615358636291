import axios from 'axios';

export const API = axios.create();
API.defaults.baseURL = process.env.REACT_APP_API;
API.defaults.headers.common['Content-Type'] = 'application/json';

class Api {
  http = (options) => {
    const { url, method, body, query, auth } = options;
    const headers = {};

    if (!auth) {
      headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    return new Promise((resolve, reject) => {
      API.request({
        url,
        method,
        data: body,
        headers: {
          ...headers,
          ...options.headers,
        },
        params: query,
      })
        .then((res) => resolve(res?.data))
        .catch((error) => reject(error?.response?.data || error));
    });
  };
}

const ApiInstance = new Api();

export default ApiInstance;
