import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Link } from '../../Shared';

import { ReactComponent as BuyAReview } from '../../../assets/images/Icons/buyAReview.svg';
import * as Styled from './ProfileScore.style';

export const ProfileScore = ({ user, title, showBuyButton = false }) => {
  const { t } = useTranslation();

  return (
    <Styled.ProfileScore className='profile-score-card'>
      {!user ? (
        <h4>{t('user_profile.score.score_will_appears_here')}</h4>
      ) : (
        <>
          <h4>{title || t('user_profile.score.title')}</h4>
          <p>
            {user?.scores?.sum}
            <br />
            <span className='points'>{t('user_profile.points')}</span>
          </p>

          <div className='last_refresh'>
            <p>{t('user_profile.score.status')}</p>
            <p className='date'>
              {moment(user?.scores?.data?.[0]?.createdAt).format(
                'MMMM D, YYYY'
              )}
            </p>
          </div>

          {showBuyButton && (
            <Link
              to={'/account/buy-a-review'}
              variant='primary'
              className='button buy-a-review'
              styles={{
                padding: '10px 15px',
                display: 'flex',
                gap: '4px',
                textTransform: 'unset',
              }}
            >
              <BuyAReview
                width={24}
                height={20}
              />
              <span>{t('user_profile.score.buy_review')}</span>
            </Link>
          )}
        </>
      )}
    </Styled.ProfileScore>
  );
};
