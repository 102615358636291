import Api from './Api';

export const getCurrentUser = async () =>
  await Api.http({
    method: 'get',
    url: `/users/profile`,
  });

export const loginUser = async (formData) => {
  const res = await Api.http({
    method: 'post',
    url: `/auth/login`,
    body: formData,
  });

  localStorage.setItem('token', res.data.tokens.accessToken);

  return res;
};

export const registerUser = async (formData) => {
  const res = await Api.http({
    method: 'post',
    url: `/auth/register`,
    body: formData,
  });

  localStorage.setItem('token', res.data.token);

  return res;
};

export const forgetPassword = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/auth/forget-password`,
    body: formData,
  });

export const updateResetPassword = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/auth/update-password`,
    body: formData,
  });

export const sendVerificationEmail = async () =>
  await Api.http({
    method: 'get',
    url: `/auth/send-verification-email`,
  });
