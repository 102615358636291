import styled from 'styled-components';

export const Footer = styled.footer`
  padding-top: 80px;
  padding-bottom: calc(40px + 85px);

  background-color: #000;
  color: #fff;

  .top_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 91px;
    width: 100%;
  }
  .bottom_group {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    
    font-family: 'Arimo';
    font-style: normal;

    .socialLinks {
      margin: 0 auto;
    }

    .poweredBy {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      margin-bottom: 32px;
    }
    .copyright {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.01em;
      margin-top: 32px;

      a {
        color: #fff;
      }
    }
  }

  .go_back {
    /* padding: 10px 16px; */
    padding-right: 16px;

    position: relative;
    background: #000000;
    border: 1px solid #424242;
    border-radius: 4px;

    width: 82px;
    height: fit-content;

    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 11L1 6L6 1' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 3px 0px 0px 3px;
      border-right: 1px solid #424242;
      width: fit-content;
      height: 100%;
      padding: 0 14px 0 12px;

      transition: all 0.5s ease-in-out;
    }

    .xLogo {
      margin: 10px 0;
      margin-left: 48px;
      flex-shrink: 0;
    }
    .xLogo2 {
      display: none;
      margin-left: 8px;
      flex-shrink: 0;
    }

    &:hover {
      width: 192px;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 11L1 6L6 1' stroke='%23111111' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-color: #fff;
      }

      .xLogo2 {
        display: block;
      }
    }
  }
`;
