import styled from 'styled-components';

import ArrowNext from '../../assets/images/ArrowNext.svg';

export const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 9999999;

  transition: all 0.5s ease-in-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }
`;

export const SideMenu = styled.div`
  position: fixed;
  top: 0;
  right: -100%;

  width: 100%;
  height: 100vh;
  max-width: 402px;

  background-color: #f5f5f5;

  z-index: 99999999;

  transition: right 0.5s ease-in-out;

  &.open {
    right: 0;
  }

  .header {
    background: #000000;
    padding: 16px 24px;

    .goBack {
      cursor: pointer;
    }

    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  & ul {
    margin-top: 24px;
    background-color: #fff;
    padding: 0;
    list-style: none;

    li {
      appearance: auto;
      position: relative;

      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #111111;

      a {
        color: #111111;
        text-decoration: none;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 32px 24px;

        &.active {
          color: #a37c51;
        }

        &::after {
          content: url(${ArrowNext});
        }
      }

      &:not(:first-child) {
        border-top: 1px solid #dfdfdf;
      }
    }
  }
`;
