import styled from 'styled-components';

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: fit-content;

  .link {
    background: #FFFFFF;
    color: #000;
    text-decoration: none;

    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;