import styled from 'styled-components';

export const LoginModal = styled.div`
  background-color: #111111;
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;

  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  .checkbox {
    justify-content: center;

    a {
      margin: 0 10px;
    }
  }

  &.fit {
    width: 50%;
    height: fit-content;

    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    .header {
      padding-top: 41px;
    }
    .header + .wrapper {
      padding-top: 120px;
      padding-bottom: 50px;
    }
  }

  .goBack {
    display: flex;
    align-items: center;
    gap: 16px;

    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;

    cursor: pointer;
  }

  &.show ~ .BottomStickyBar {
    display: none;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .header {
    padding: 16px 16px 16px 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    width: 100%;

    span,
    a.register {
      margin-right: 16px;
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
      text-decoration: underline;
    }
  }

  .wrapper {
    display: flex;

    & > img {
      height: 100vh;
      object-fit: cover;
    }
  }

  .form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fff;
    width: 100%;
    max-width: 486px;

    &.reset_form {
      max-width: unset;
    }

    .input_group {
      width: 100%;

      margin-bottom: 35px;
    }
  }

  .footer {
    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    span {
      margin-right: 16px;
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
      text-decoration: underline;
    }

    &.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }

    &.mobile {
      display: none;
    }
  }

  .close {
    display: none;
  }

  h1 {
    font-family: 'Saol Display';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
    color: #ffffff;
  }

  .success {
    text-align: center;
    h1 {
      text-align: center;
      margin: 0 auto;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fafafa;
    }

    .sended {
      font-family: 'Arimo';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      color: #a37c51;
    }
  }

  @media screen and (max-width: 768px) {
    overflow: auto;
    top: unset !important;
    bottom: 0 !important;
    transform: translateY(100%);

    &.show {
      transform: translateY(0);
    }

    &.fit {
      width: 100%;
      height: 100%;
    }

    .close {
      display: block;
    }

    .header .right_group {
      display: none;
    }

    .footer.mobile {
      display: flex;
      margin-top: 50px;
    }

    .wrapper {
      flex-direction: column;

      img {
        height: 50vh;
        object-fit: cover;
      }

      .form_wrapper {
        padding: 30px 0;

        &.login_form {
          width: calc(100% - 32px);
        }
      }
    }
  }
`;
