import styled from 'styled-components';

export const BottomStickyBar = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 12px 0;

  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;

  & .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .wrapped {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    & > p.application {
      font-family: 'Saol Display';
      font-style: italic;
      font-weight: 300;
      font-size: 24px;
      line-height: 27px;
      text-align: right;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: baseline;
    }

    p {
      margin: 0;

      &.application {
        font-family: 'Saol Display';
        font-style: italic !important;
        font-weight: 300 !important;
        font-size: 20px !important;
        line-height: 27px !important;
      }
    }

    .wrapped {
      width: 100%;
      a {
        padding: 16px 20px;
      }
    }
  }
`;
