import { useState } from 'react';
import styled from 'styled-components';

const FileWrapper = styled.div`
  padding: 80px 0;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border: 1px dashed #dfdfdf;
  position: relative;

  &.type-2 {
    display: flex;
    align-items: flex-end;

    padding: 24px;
    gap: 24px;

    .image-holder {
      display: flex;
      flex-direction: column;

      img {
        width: 124px;
        height: 124px;
      }

      span {
        width: 100%;
        background: #a37c51;
        padding: 11px 18px;

        font-family: 'Arimo';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  input {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  h6 {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1a1919;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #8f8f8f;
    margin-bottom: 55px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FileUpload = ({
  id,
  onChange,
  accept = '',
  maxFileSize = 2,
  allowed,
  recommendation,
  type = 0,
  image,
}) => {
  const [img, setImg] = useState(image || null);

  const handleChange = (e) => {
    e.preventDefault();

    if (!e?.target?.files?.length > 0 && !image) {
      return;
    }

    const fileSize = Math.round(e.target.files[0].size / 1024 / 1024);
    if (fileSize > maxFileSize) {
      return alert('File is too big');
    }

    setImg(URL.createObjectURL(e.target.files[0]) || image);

    onChange && onChange(e);
  };

  if (type === 1) {
    return (
      <FileWrapper className='file-upload type-2'>
        <input type='file' onChange={handleChange} id={id} accept={accept} />

        <div className='image-holder'>
          <img src={img} alt='uploaded file' width='40' height='40' />

          <span>Upload new</span>
        </div>

        {recommendation && <p>{recommendation}</p>}
      </FileWrapper>
    );
  }

  return (
    <FileWrapper className='file-upload'>
      <input type='file' onChange={handleChange} id={id} />

      {img ? (
        <img src={img} alt='uploaded file' width='40' height='40' />
      ) : (
        <svg
          width='40'
          height='40'
          viewBox='0 0 40 40'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M36.6665 24.5038V26.8097C36.6665 27.1924 36.6498 27.5784 36.6164 27.961C36.1488 33.2261 32.4915 36.6666 27.2143 36.6666H12.7854C10.1134 36.6666 7.85888 35.7994 6.23897 34.227C5.60437 33.6439 5.06997 32.9588 4.65247 32.1734C5.20357 31.5034 5.82147 30.7699 6.42267 30.0346C7.44138 28.8149 8.42668 27.6101 9.04458 26.8264C9.96309 25.6902 12.3846 22.6992 15.7413 24.1028C16.426 24.3869 17.0272 24.7879 17.5783 25.1388C18.931 26.0411 19.4988 26.3085 20.4507 25.7905C21.5028 25.2224 22.1875 24.1028 22.9056 22.9332C23.2897 22.3133 23.6738 21.7134 24.0913 21.162C25.9116 18.7892 28.7173 18.1543 31.0553 19.5579C32.2243 20.2597 33.2263 21.1453 34.1615 22.0459C34.3619 22.2464 34.5623 22.4319 34.746 22.6157C34.9965 22.8663 35.8315 23.7018 36.6665 24.5038Z'
            fill='#A37C51'
          />
          <path
            opacity='0.4'
            d='M27.2312 3.3335H12.7856C7.12429 3.3335 3.33337 7.29361 3.33337 13.1903V26.8101C3.33337 28.8637 3.80098 30.6867 4.65268 32.1739C5.20378 31.5038 5.82168 30.7703 6.42289 30.0334C7.44159 28.8153 8.42689 27.6105 9.0448 26.8269C9.9633 25.6906 12.3848 22.6996 15.7415 24.1032C16.4262 24.3873 17.0274 24.7883 17.5785 25.1392C18.9312 26.0415 19.499 26.3089 20.4509 25.7892C21.503 25.2228 22.1877 24.1032 22.9059 22.9319C23.29 22.3137 23.6741 21.7138 24.0916 21.1624C25.9119 18.7897 28.7175 18.1547 31.0555 19.5583C32.2245 20.2601 33.2265 21.1457 34.1617 22.0463C34.3621 22.2468 34.5625 22.4323 34.7462 22.6161C34.9967 22.8651 35.8317 23.7005 36.6667 24.5043V13.1903C36.6667 7.29361 32.8758 3.3335 27.2312 3.3335Z'
            fill='#A37C51'
          />
          <path
            d='M19.0907 14.6611C19.0907 17.0087 17.1352 18.9637 14.7888 18.9637C12.4441 18.9637 10.4885 17.0087 10.4885 14.6611C10.4885 12.3151 12.4441 10.3584 14.7888 10.3584C17.1352 10.3584 19.0907 12.3151 19.0907 14.6611Z'
            fill='#A37C51'
          />
        </svg>
      )}

      <h6>
        Click and drag a photo or <a href='#s'>Search</a>
      </h6>

      {recommendation && <p>{recommendation}</p>}
      {allowed && <p>{allowed}</p>}
    </FileWrapper>
  );
};
