import React from 'react';

import * as Styled from './Section.style';

export const Section = ({ title, children }) => {
  return (
    <Styled.Section>
      {title && <Styled.Title className='section-title'>{title}</Styled.Title>}

      <div className="body">{children}</div>
    </Styled.Section>
  );
};
