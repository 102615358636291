import { Navigate } from 'react-router-dom';
import { Account, AccountUnVerified, LandingPage } from '../layouts';
import { Blank } from '../layouts/Blank';
import {
  BuyAReviewPage,
  HomePage,
  PaymentMethod,
  Personal,
  Subscription,
  ProfilePage,
  PurchaseHistory,
  RankListPage,
  RegisterPage,
  RegisterStepTwo,
  ScoresPage,
  Settings,
  UnderReviewPage,
  UserPage,
  PrivacyPolicy,
  TermsAndConditions,
} from '../pages';

const routes = [
  {
    path: '/',
    exact: true,
    element: <LandingPage />,
    children: [
      {
        exact: true,
        index: true,
        element: <HomePage />,
      },
      {
        path: '/privacy-policy',
        exact: true,
        element: <PrivacyPolicy />,
      },
      {
        path: '/terms-and-conditions',
        exact: true,
        element: <TermsAndConditions />,
      },
    ],
  },

  {
    path: '/register',
    exact: true,
    element: <Blank />,
    children: [
      {
        path: '/register',
        exact: true,
        element: <RegisterPage />,
      },
      {
        path: '/register/buy-a-review',
        exact: true,
        element: <RegisterStepTwo />,
      },
    ],
  },

  {
    path: '/account',
    exact: true,
    element: <Account />,
    auth: true,
    children: [
      {
        exact: true,
        index: true,
        element: <Navigate to='/account/profile' />,
      },
      {
        path: '/account/profile',
        exact: true,
        element: <ProfilePage />,
      },
      {
        path: '/account/profile/scores/:group/:category',
        exact: true,
        element: <ScoresPage />,
      },
      {
        path: '/account/buy-a-review',
        exact: true,
        element: (
          <BuyAReviewPage
            order={{
              name: 'Review Score One-Time',
              name_key: 'plan.1',
              price: 49,
              priceId: process.env.REACT_APP_REVIEW,
              period: 'One time',
              period_key: 'period.1',
            }}
          />
        ),
      },
    ],
  },

  {
    path: '/account',
    exact: true,
    element: <Account onlyVerified={false} />,
    auth: true,
    children: [
      {
        path: '/account/rank-list',
        exact: true,
        element: <RankListPage />,
      },
      {
        path: '/account/user/:userId',
        exact: true,
        element: <UserPage />,
      },
    ],
  },

  {
    path: '/under/review',
    exact: true,
    auth: true,
    element: <AccountUnVerified />,
    children: [
      {
        exact: true,
        index: true,
        element: <UnderReviewPage />,
      },
    ],
  },

  {
    path: '/profile/settings',
    exact: true,
    element: <Settings />,
    children: [
      {
        exact: true,
        index: true,
        element: <Navigate to='/profile/settings/personal' />,
      },
      {
        path: '/profile/settings/personal',
        exact: true,
        element: <Personal />,
      },
      {
        path: '/profile/settings/purchase-history',
        exact: true,
        element: <PurchaseHistory />,
      },
      {
        path: '/profile/settings/subscriptions',
        exact: true,
        element: <Subscription />,
      },
      {
        path: '/profile/settings/payment-method',
        exact: true,
        element: <PaymentMethod />,
      },
    ],
  },

  {
    path: '*',
    element: <h1>Not Found</h1>,
  },
];

export default routes;
